import React from 'react';
import { Link } from 'react-router-dom';
import logoPDE from '../resources/Assets/drawable-hdpi/Grupo 16446.png';
import $ from 'jquery';
import back from '../resources/back.png';
import DatePicker from 'react-date-picker';
import splashBG from '../resources/Assets/Splash_Fondo.png';
import splashGif from '../resources/Assets/Splash_Animado.gif';
import splashGifFinal from '../resources/Assets/FondoWeb.gif';

import validateSession from "../checksession";
import { messaging } from '../init-fcm';
import { async } from 'q';
var environment = "sferea";

var registro = false,
    logeo = false,
    forgotpass = false;


/**
 *Componente que contiene el splash que se muestra al principio de la webapp
 *
 * @class Splash
 * @extends {React.Component}
 */
class Splash extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            splashshow: true
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        if(messaging){
            if (!("Notification" in window)) {
                console.log("no soporta notificaciones");
              }else{
                Notification.requestPermission();
              }
           
        }
        

        //   setTimeout(() => {
            // $("#inicio").fadeOut("slow");
            setTimeout(() => {
                // $("#inicio").remove();
                this.setState({ splashshow: false });
                logeo = true;
            }, 2000);
        // }, 2000);
        sessionStorage.setItem("acercaDe", false);
    }

    render() {
        var url = window.location.origin;
        var protocol = window.location.protocol;
        if (url.indexOf('prevencionydeteccion') !== -1) {
            localStorage.setItem('urlAPI', `${protocol}//panel.prevencionydeteccion.mx`);
        } else {
            if (url.indexOf('localhost') !== -1) {
                console.log("cambio a esta url //panel.prevencionydeteccion.mx en localhost");
                localStorage.setItem("urlAPI", `https://pde.sferea.com`);
                // localStorage.setItem('urlAPI', `https://panel.prevencionydeteccion.mx`);
            } else {
                if (url.indexOf('pdewebappqa') === -1) {
                    localStorage.setItem("urlAPI", `https://pde.sferea.com`);
                } else {
                    localStorage.setItem('urlAPI', `https://pde.sferea.com`);
                }
            }
        }

        return (
            this.state.splashshow && (
                <div className='position-absolute vh-100 vw-100'>
                    {/* <img alt='Fondo' className='w-100 h-100 position-absolute' style={{ objectFit: 'cover', left: 0, top: 0 }} src={splashBG}></img>
                    <img alt='Gif' className='w-100 h-100 position-absolute' style={{ objectFit: 'cover', left: 0, top: 0 }} src={splashGif}></img> */}
                    <img alt='Gif' className='w-100 h-100 position-absolute' style={{ objectFit: 'cover', left: 0, top: 0 }} src={splashGifFinal}></img>
                </div>
            ) 
        );

        // return (
        //     <div id="inicio">
        //         {this.state.splashshow ? <img src={logoPDE} className='App-logo' alt='PDE Logo' /> : null}
        //     </div>
        // );
    }
}

/**
 *Componente que contiene los campos para iniciar sesión
 *
 * @class LogIn
 * @extends {React.Component}
 */
class LogIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogIn: logeo,
            correo: '',
            pass: ''
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(messaging){
            navigator.serviceWorker.removeEventListener("message", (message) => {
                for (const [key, value] of Object.entries(message.data)) {
                    if (key == "firebase-messaging-msg-data") {
                        if (window.location.pathname !== '/webapp/app/comunidad/') {
                            alert(`${value.notification.body}`);
                        }
                        break;
                    }
                }
            });
        }
       
    }

    componentDidMount() {
        if(messaging){
            messaging.onTokenRefresh(() => {
                messaging.getToken().then((refreshedToken) => {
                }).catch((err) => {
                    console.log('Unable to retrieve refreshed token ', err);
                });
            });

            navigator.serviceWorker.addEventListener("message", (message) => {
                console.log("----------------------");
                console.log(message);
                for (const [key, value] of Object.entries(message.data)) {
                    if (key === "firebase-messaging-msg-data") {
                        if (window.location.pathname !== '/webapp/app/comunidad/') {
                            alert(`${value.notification.body}`);
                        }
                        break;
                    }
                }
            });
        }
     

        
        // 

        setInterval(() => {
            if (this.state.showLogIn !== logeo) {
                this.setState({ showLogIn: logeo });
            }
        }, 100);
    }

    /**
     *Función que realiza el logeo del usuario para la aplicación
     *
     * @memberof LogIn
     */
    _logeo = () => {
        let { history } = this.props;
        var correo = this.state.correo,
            password = this.state.pass;

        if (correo !== "" && password !== "") {
            var re = new RegExp('^[^@]+@[^@]+\.[a-zA-Z]{2,}$');
            var OK = re.exec(correo);

            if (OK != "undefined") {
                // var re2 = new RegExp('^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{5,12}$');
                var re2 = /^.{4,12}$/g;
                var OK2 = re2.exec(password);
                if (OK2 != "undefined") {
                    $.ajax({
                        type: "POST",
                        url: `${localStorage.getItem("urlAPI")}/api/user/login`,
                        data: JSON.stringify({
                            "correoUsuario": correo,
                            "password": (password)
                        }),
                        dataType: "json",
                        success: function (response) {
                            if (response.result.status_code == "invalid_pass_or_email") {
                                alert("El correo o la contraseña es incorrecto, favor de verificarlo.");
                            }

                            if (response.result.status_code == "ok") {
                                localStorage.setItem("token", response.result.data.tokenUser);

                                $.ajax({
                                    type: "POST",
                                    url: localStorage.getItem("urlAPI") + "/api/user/profile",
                                    data: JSON.stringify({
                                        "tokenUsuario": response.result.data.tokenUser
                                    }),
                                    dataType: "json",
                                    success: function (response) {
                                        let data_usu = response.result.data;
                                        sessionStorage.setItem("acercaDe", true);
                                        sessionStorage.setItem("data_usu", JSON.stringify({ data_usu }));
                                        if (localStorage.getItem('back') === null) {
                                            history.push('/webapp/app/vidaSana/');
                                        } else {
                                            history.push(localStorage.getItem('back'));
                                            localStorage.removeItem('back');
                                        }
                                    },
                                    error: function (response) {
                                        console.log(response.result);
                                        alert("Error de datos de usuario");
                                    }
                                });

                            }
                        },
                        error: function (response) {
                            console.log(response);
                            alert(response.responseJSON.result.message);
                        }
                    });
                } else {
                    alert("La contraseña debe tener mínimo 5 caracteres, máximo 12, al menos 1 letra mayúscula, 1 minúscula y 1 número.");
                }
            } else {
                alert("El correo electrónico no es válido, favor de verificarlo.");
            }
        } else {
            alert("Llenar todos los campos");
        }
    }

    componentDidUpdate() {
        let { history } = this.props;
        $("#registrarme").click(() => {
            logeo = false;

            registro = true;
        });
        if (this.state.showLogIn) {

            $("#forgotpass").click(function (e) {
                e.preventDefault();
                $("#forgotpassmodal").show("slow");
            });
            $("#cerrar_fechamodal").click((e) => {
                e.preventDefault();
                $("#forgotpassmodal").hide("slow");
            });

            $("#forgotaceptar").click(function () {
                var formail = document.querySelector("#forgotmail").value;

                var re = new RegExp('^[^@]+@[^@]+\.[a-zA-Z]{2,}$');
                var OK = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(formail);
                // var OK = re.exec(formail);
                if (OK) {

                    if (formail != "") {
                        $.ajax({
                            type: "POST",
                            url: localStorage.getItem("urlAPI") + "/api/user/recover",
                            data: JSON.stringify({
                                "correoUsuario": formail
                            }),
                            dataType: "json",
                            success: function (response) {
                                alert("Se ha enviado la información para restablecer la contraseña a tu correo electrónico.");
                                $("#forgotpassmodal").hide("slow");
                                $("#forgotmail").val("");
                            },
                            error: function (response) {
                                console.log(response.responseJSON.result.message);
                                alert(response.responseJSON.result.message);
                            }

                        });
                    } else {
                        alert("Los campos son requeridos.");
                    }
                } else {
                    alert("El correo electrónico no es válido, favor de verificarlo.");
                }
            });

        }
    }

    _invitado = () => {
        localStorage.setItem('token', '0');
        sessionStorage.setItem("acercaDe", true);
        this.props.history.push('/webapp/app/vidaSana/')
    }

    render() {
        return (
            <div>
                {this.state.showLogIn ? (
                    <div className="h6">
                        <div className="centrar_absoluto col-10 col-md-4" id="forgotpassmodal">

                            <div className="cont_fechamodal">
                                <span id="cerrar_fechamodal" className="font-weight-bold">x</span>
                                <div className="dataModal">
                                    <div className="row mt-4">
                                        <div className="col-12 color-pde h5">
                                            <p>¿Olvidaste tu contraseña?</p>
                                        </div>
                                        <div className="col-10 offset-1 text-dark font-size-08">
                                            <p>Te enviaremos los pasos a seguir a tu correo electrónico.</p>
                                        </div>
                                        <div className="col-10 offset-1">
                                            <input className="form-control font-size-08 pl-2" id="forgotmail" type="text" placeholder="Correo Electrónico"></input>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div id="forgotaceptar" className="btn text-light mx-auto cursor_pointer bg-pde">Aceptar</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-10 offset-1">
                            <div className="row">
                                <div className="col-md-10 offset-1 mb-4">
                                    <Link className="nav-link" to="/webapp/app/acercaDe/" target='_blank'><img alt="PDE" className="w-75 mb-5 cursor_pointer" src={logoPDE} /></Link>
                                    <p className="h2">Bienvenida</p>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="float-left mb-2 font-size-08 font-weight-bold">Correo electrónico</label>
                                        <input onChange={(e) => this.setState({ correo: e.target.value })} onKeyDown={e => e.keyCode === 13 ? (this._logeo()) : (null)} id="mailLog" type="text" className="form-control py-0 font-size-08" maxLength="255" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="float-left mb-2 font-size-08 font-weight-bold">Contraseña</label>
                                        <input onChange={(e) => this.setState({ pass: e.target.value })} onKeyDown={e => e.keyCode === 13 ? (this._logeo()) : (null)} id="passLog" type="password" className="form-control py-0 font-size-08" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <div style={{ textDecoration: 'underline' }} id="forgotpass" className="cursor_pointer font-size-08 font-weight-bold">¿Olvidaste tu contraseña?</div>
                                </div>
                            </div>
                            <div style={{ opacity: `${this.state.correo !== '' && this.state.pass !== '' ? (1) : (.5)}` }} className="px-5 py-2 mx-3 mb-4 mt-2 bg-pde cursor_pointer rounded font-weight-bold" onClick={() => this._logeo()}>Entrar</div>
                            <div className="px-5 py-2 mx-3 mb-4 mt-2 cursor_pointer rounded btn-invitado font-weight-bold" onClick={() => this._invitado()}>Entrar como Invitado</div>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <div id="registrarme" className="cursor_pointer font-size-08 font-weight-bold">Registrarme</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (null)
                }
            </div>
        );
    }
}


/**
 *Componente que contiene los campos para registro
 *
 * @class SignIn
 * @extends {React.Component}
 */
class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSignIn: registro,
            date: '',
            calendario: false,
            name: '',
            lastname: '',
            telefono: "",
            paisesData: [],
            estadosData: []
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {

        fetch(localStorage.getItem('urlAPI')+"/api/catalogo/paises",{
            method:'GET'
        })
            .then(response => response.json())
            .then(responseJSON => {
                console.log(responseJSON);
                this.setState({
                    paisesData: responseJSON.result.data
                })
            })
            .catch(err => console.log(err))

        setInterval(() => {
            if (this.state.showSignIn !== registro) {
                this.setState(state => ({
                    showSignIn: registro
                }));
            }
        }, 100);
    }

    /**
     *Funcion que registra a una usuaria
     *
     * @memberof SignIn
     */
    _registro = () => {

        var passWord = document.querySelector("#password").value;
        let prefechadata = this.state.date;

        if (document.querySelector("#name").value != "" && document.querySelector("#mail").value != "" && document.querySelector("#apellido").value != "" && document.querySelector("#country").value != 0 && document.querySelector("#state").value != 0 && document.querySelector("#password").value != "" && document.querySelector("#repassword").value != "" && document.querySelector("#terminosyc").checked != false) {
            let fechadata = `${prefechadata.getFullYear()}/${prefechadata.getMonth() + 1}/${prefechadata.getDate()}`;
            let fechaingresada = new Date(fechadata);
            let fechatoday = new Date();
            var re = new RegExp('^[^@]+@[^@]+\.[a-zA-Z]{2,}$');
            var OK = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(document.querySelector("#mail").value);
            // var OK = re.exec(document.querySelector("#mail").value);
            if (OK) {

                if (fechaingresada <= fechatoday) {
                    // var re2 = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{5,12}$/mg;
                    var re2 = /^.{4,12}$/g;

                    var OK2 = re2.exec(document.querySelector("#password").value);
                    if (OK2 != null) {
                        if (document.querySelector("#password").value === document.querySelector("#repassword").value) {
                            var nombre = document.querySelector("#name").value,
                                apellido = document.querySelector("#apellido").value,
                                mail = document.querySelector("#mail").value,
                                state = document.querySelector("#state").value;

                            if (String(this.state.telefono).length === "") {

                                fetch(`${localStorage.getItem("urlAPI")}/api/user/register`, {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        "imagenPerfil": document.querySelector("#imgperfil").getAttribute("data-imagen") != "0" ? (document.querySelector("#imgperfil").getAttribute("data-imagen").split("base64,")[1]) : (""),
                                        "nombreUsuario": nombre,
                                        "apellidos": apellido,
                                        "fechaNacimiento": fechadata,
                                        "correoUsuario": mail,
                                        "telefono": "",
                                        "estadoId": Number(state),
                                        "password": passWord
                                    })
                                })
                                    .then(response => response.json())
                                    .then(responseJSON => {
                                        if (responseJSON.result.status_code == "ok") {
                                            alert("Usuario registrado correctamente");
                                            this._logeo();
                                        } else {
                                            alert(responseJSON.result.message)
                                        }
                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })

                            } else {
                                if (String(this.state.telefono).length === 10) {
                                    fetch(`${localStorage.getItem("urlAPI")}/api/user/register`, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            "imagenPerfil": document.querySelector("#imgperfil").getAttribute("data-imagen") != "0" ? (document.querySelector("#imgperfil").getAttribute("data-imagen").split("base64,")[1]) : (""),
                                            "nombreUsuario": nombre,
                                            "apellidos": apellido,
                                            "fechaNacimiento": fechadata,
                                            "correoUsuario": mail,
                                            "telefono": String(this.state.telefono),
                                            "estadoId": Number(state),
                                            "password": passWord
                                        })
                                    })
                                        .then(response => response.json())
                                        .then(responseJSON => {
                                            if (responseJSON.result.status_code == "ok") {
                                                alert("Usuario registrado correctamente");
                                                this._logeo();
                                            } else {
                                                alert(responseJSON.result.message)
                                            }
                                        })
                                } else {
                                    alert("El teléfono tiene que contener 10 digitos.")
                                }
                            }

                        } else {
                            alert("Las contraseñas no coinciden.");
                        }
                    } else {
                        alert("La contraseña debe tener mínimo 4 caracteres y máximo 12.");
                    }
                } else {
                    alert("Fecha invalida.");
                }
            } else {
                alert("El correo electrónico no es válido, favor de verificarlo.");
            }
        } else {
            alert("Los campos son requeridos.");
        }
    }

    componentDidUpdate() {
        function legales() {
            if ($("#terminosyc").prop("checked") === false) {
                $("#legal").show("slow");

                $("#atraslegal").click(function (e) {
                    e.preventDefault();
                    $("#legal").hide("slow");
                });

                $(".selectAviso").click(function (e) {
                    e.preventDefault();
                    $(".selectAviso").addClass("active");
                    $(".selectTermyc").removeClass("active");
                    $(".aviso").show();
                    $(".terminos").hide();
                });

                $(".selectTermyc").click(function (e) {
                    e.preventDefault();
                    $(".selectTermyc").addClass("active");
                    $(".selectAviso").removeClass("active");
                    $(".aviso").hide();
                    $(".terminos").show();
                });

                $("#aceptarlegal").click(function () {
                    $("#legal").hide("slow");
                    $("#terminosyc").prop("checked", true);

                });

            } else {
                $("#terminosyc").prop("checked", false);
            }
        }
        $("#back").click(() => {
            registro = false;
            logeo = true;
        });

        // cambiamos la forma de peticion de ajax a fetch
        fetch(localStorage.getItem("urlAPI")+'/api/legal',{
            method:'GET',
            mode:'cors'
        })
        .then(response => response.json())
        .then(responseJSON =>{
            let termyc = responseJSON.result.data;
            $(".aviso").html(termyc.privacidad);
            $(".terminos").html(termyc.terminos);
            //console.log(termyc);
        })
        .catch(error=> {
            console.log("Hay un error en traer legal "+ error);
        });

        // $.ajax({
        //     type: "GET",
        //     url: `${localStorage.getItem("urlAPI")}/api/legal`,
        //     data: "",
        //     dataType: "json",
        //     success: function (response) {
        //         console.log(JSON.stringify(response));
        //         var termyc = response.result.data;

        //         $(".aviso").html(termyc.privacidad);
        //         $(".terminos").html(termyc.terminos);
        //     }
        // });

        $("#terminosyc").click(function (e) {
            e.preventDefault();
            setTimeout(() => {
                if (!$("#terminosyc").prop("checked")) {
                    legales();
                } else {
                    $("#terminosyc").prop("checked", false);
                }
            }, 100);
        });

        $("#termn").click(function (e) {
            e.preventDefault();
            legales();
        });

        $("#dateb").click(function (e) {
            e.preventDefault();
            $("#dateb").prop("disabled", true);
            $("#fechamodal").show("slow");
            $("#yearreg").click();

        });
        $("#cerrar_fechamodal").click((e) => {
            e.preventDefault();
            $("#fechamodal").hide("slow");
            $("#dateb").prop("disabled", false);
        });

        $("#aceptarFecha").click(function (e) {
            e.preventDefault();
            var year = document.querySelector("#yearreg").value,
                month = document.querySelector("#monthreg").value,
                day = document.querySelector("#dayreg").value;
            if (year.length == 4) {
                if (year !== "" && month !== "" && day !== "") {
                    if (Number(year) < 9999 && Number(month) < 13 && day < 32) {
                        let fechacomp = new Date(year, (month - 1), day);
                        let diacomp = fechacomp.getDate();
                        if (diacomp == day) {
                            document.querySelector("#dateb").value = year + "/" + month + "/" + day;
                            $("#fechamodal").hide("slow");
                            $("#dateb").prop("disabled", false);
                        } else {
                            alert("Fecha incorrecta, volver a llenar los campos.");
                        }

                    } else {
                        alert("Ingresar correctamente la fecha.");
                    }
                } else {
                    console.log("Nope");
                    alert("Se requieren todos los campos.")
                }
            } else {
                alert('El formato de fecha debe tener 4 digitos.');
            }
        });

    }
    /**
     *Función que recoje una imagen para hacerla en base 64
     *
     * @param {*} event
     * @memberof SignIn
     */
    handleChange(event) {
        var imgdata = document.querySelector("#fotoperfil").files[0];
        var reader = new FileReader();
        reader.readAsDataURL(imgdata);
        reader.onload = function (event) {
            document.querySelector("#imgperfil").style.backgroundImage = "url(" + event.target.result + ")";
            document.querySelector("#imgperfil").setAttribute("data-imagen", event.target.result);
            document.querySelector("#imgperfil").innerHTML = "";
            document.querySelector("#imgperfil").style.border = "0";
        };


    }

    /**
     *Funcion que hace el proceso de login
     *
     * @memberof SignIn
     */
    _logeo = () => {
        fetch(`${localStorage.getItem("urlAPI")}/api/user/login`, {
            method: 'POST',
            body: JSON.stringify({
                "correoUsuario": document.querySelector("#mail").value,
                "password": document.querySelector("#password").value
            })
        })
            .then(response => response.json())
            .then(responseJSON => {
                localStorage.setItem("token", responseJSON.result.data.tokenUser);
                fetch(`${localStorage.getItem("urlAPI")}/api/user/profile`, {
                    method: 'POST',
                    body: JSON.stringify({
                        "tokenUsuario": responseJSON.result.data.tokenUser
                    })
                })
                    .then(datauser => datauser.json())
                    .then(datauserJSON => {
                        let data_usu = datauserJSON.result.data;
                        sessionStorage.setItem("acercaDe", true);
                        sessionStorage.setItem("data_usu", JSON.stringify({ data_usu }));
                        this.props.history.push('/webapp/app/vidaSana/');
                    })
                    .catch(error => console.log(error))
            })
            .catch(error => console.log(error))

    }

    /**
     *Funcion que evalua el nombre y lo guarda
     *
     * @memberof SignIn
     */
    _getName = (value) => {
        var validador = new RegExp(/^[a-zA-ZñÑ\s\W]*$/);
        if (validador.test(value)) {
            this.setState({
                name: value
            })
        } else {
            this.setState({
                name: value.slice(0, value.length - 1)
            })
        }
    }

    /**
     *Funcion que evalua el apellido y lo guarda
     *
     * @memberof SignIn
     */
    _getLastName = (value) => {
        var validador = new RegExp(/^[a-zA-ZñÑ\s\W]*$/);
        if (validador.test(value)) {
            this.setState({
                lastname: value
            })
        } else {
            this.setState({
                lastname: value.slice(0, value.length - 1)
            })
        }
    }

    /**
     *Evalua el estado y lo guarda
     *
     * @memberof SignIn
     */
    _getEstados = (id) => {
        fetch(`${localStorage.getItem("urlAPI")}/api/catalogo/estados/${id}`)
            .then(response => {
                if (response.statusText === "No content") {
                    return { "result": { "data": [{ "idestados": -1, "estado": "No hay datos" }] } };
                } else {
                    return response.json();
                }
            })
            .then(respuesta => {
                this.setState({
                    estadosData: respuesta.result.data
                })
            });
    }

    /**
     *Función que cambia el mes
     *
     * @memberof SignIn
     */
    _changeTextMonth = () => {
        var valueTxt = $(".react-date-picker__inputGroup__month");
        for (let index = 0; index < $(valueTxt).children().length; index++) {
            if (valueTxt.children()[0].innerHTML === '') {
                valueTxt.children()[index].innerHTML = valueTxt.children()[index].innerHTML.split(".")[0];
                valueTxt.children()[index].innerHTML = valueTxt.children()[index].innerHTML.slice(0, 1).toUpperCase() + valueTxt.children()[index].innerHTML.slice(1);
            }
        }
    }

    render() {
        return (
            <div>
                {this.state.showSignIn ? (
                    <div className="h6">

                        <div className="centrar_absoluto col-10 col-md-5 px-4 pb-3 pt-2" id="legal">

                            <div className="h-100 w-100">
                                <div className="row mt-4 mx-0">
                                    <div className="row w-100 mx-0 mb-3">
                                        <div className="col-12">
                                            <div className="cont-titl-legal">
                                                <img id="atraslegal" src={back} className="backimg mr-2 cursor_pointer" alt="Atras PDE" />
                                                <span className="text-body h2 legaltitl mb-0">Legal</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row w-100 mx-0 mb-3">
                                        <div className="col-12">

                                            <div className="selecttermn font-size-065">
                                                <div className="cursor_pointer center_Text selectAviso active float-left w-50 h-100">
                                                    <span style={{ fontWeight: 'bold' }}>Aviso de privacidad</span>
                                                </div>
                                                <div className="cursor_pointer center_Text selectTermyc float-left w-50 h-100">
                                                    <span style={{ fontWeight: 'bold' }}>Términos y condiciones</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row w-100 mx-0">
                                        <div className="col-12">

                                            <div className="termnbox aviso font-size-08 text-left">

                                            </div>
                                            <div className="termnbox terminos font-size-08 text-left">

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div id="aceptarlegal" className="btn text-light mx-auto cursor_pointer bg-pde w-50 py-0">Aceptar</div>
                                </div>
                            </div>

                        </div>

                        <div className="centrar_absoluto" id="fechamodal">
                            <div className="cont_fechamodal">
                                <span id="cerrar_fechamodal" className="font-weight-bold">x</span>
                                <div className="select_date">
                                    <div className="row">
                                        <div className="col">
                                            <input className="form-control font-size-08 pl-2" id="yearreg" type="number" placeholder="Año" max="9999"></input>
                                        </div>
                                        <div className="col">
                                            <input className="form-control font-size-08 pl-2" id="monthreg" type="number" placeholder="Mes" max="12"></input>
                                        </div>
                                        <div className="col">
                                            <input className="form-control font-size-08 pl-2" id="dayreg" type="number" placeholder="Dia" max="31"></input>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div id="aceptarFecha" className="btn btn-danger mx-auto cursor_pointer">Aceptar</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-md-1 offset-md-1 col-2 offset-0 arrow-left">
                                <div id="back" className="mt-5 cursor_pointer float-right font-weight-bold"> <img alt='PDE' style={{ filter: 'brightness(13)', width: '1rem' }} src={back} /> </div>
                            </div>
                            <div className="col-md-8 offset-md-0 col-8">
                                <div className="row">
                                    <div className="col-md-5 offset-md-0 col-8 offset-2 mb-2">
                                        <Link to="/webapp/app/acercaDe/" target='_blank' >
                                            <img id="pdelogin" alt="PDE" className="w-75 cursor_pointer" src={logoPDE} />
                                        </Link>
                                    </div>
                                    <div className="col-md-5 offset-md-1 col-12">
                                        <input onChange={(event) => this.handleChange(event.target.files[0])} id="fotoperfil" type="file" accept="image/*" />
                                        <label htmlFor="fotoperfil" className="float"><div className="foto_perfil float-right cursor_pointer p-3 btn-agregarFP" id="imgperfil" data-imagen="0"><span>+</span></div><br /><span className="font-size-08 font-weight-bold">Añade foto de Perfil</span></label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="float-left mb-0 font-size-08 font-weight-bold">Nombre</label>
                                            <input onChange={(event) => this._getName(event.target.value)} value={this.state.name} id="name" type="text" className="form-control py-0 font-size-08" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="float-left mb-0 font-size-08 font-weight-bold">Apellido</label>
                                            <input onChange={(event) => this._getLastName(event.target.value)} value={this.state.lastname} id="apellido" type="text" className="form-control py-0 font-size-08" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="float-left mb-0 font-size-08 font-weight-bold">Correo electrónico</label>
                                            <input id="mail" type="email" className="form-control py-0 font-size-08" maxLength="255" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="float-left mb-0 font-size-08 font-weight-bold">Teléfono</label>
                                            <input value={this.state.telefono === '' ? ('') : (this.state.telefono)} onChange={(event) => {
                                                if (event.target.value.length <= 10) {
                                                    this.setState({ telefono: event.target.value })
                                                }
                                            }} type="number" className="form-control py-0 font-size-08" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="float-left mb-0 font-size-08 font-weight-bold">Fecha de nacimiento</label>

                                            <DatePicker
                                                onCalendarOpen={() => this.setState({ calendario: true })}
                                                onCalendarClose={() => this.setState({ calendario: false })}
                                                disabled={this.state.calendario}
                                                locale='es-ES'
                                                maxDate={new Date(2010,11,17)}
                                                onChange={(date) => {
                                                    this.setState({ date: date, calendario: false })
                                                    this._changeTextMonth()
                                                }}
                                                value={this.state.date}
                                                calendarIcon={null}
                                                format='dd-MMM-y'
                                                dayPlaceholder=''
                                                monthPlaceholder=''
                                                yearPlaceholder=''
                                                className='w-100 font-size-08 datepicker_signin'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="float-left mb-0 font-size-08 font-weight-bold">País</label>
                                            <select onChange={(event) => this._getEstados(event.target.value)} id="country" className="form-control py-0 font-size-08">
                                                <option value="0"></option>
                                                {this.state.paisesData.length > 0 ? (
                                                    this.state.paisesData.map(pais =>
                                                        <option key={pais.idpaises} value={pais.idpaises}>{pais.pais}</option>
                                                    )
                                                ) : (null)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="float-left mb-0 font-size-08 font-weight-bold">Estado</label>
                                            <select id="state" className="form-control py-0 font-size-08">
                                                <option value="-1"></option>
                                                {this.state.estadosData.length > 0 ? (
                                                    this.state.estadosData.map(estado =>
                                                        <option key={estado.idestados} value={estado.idestados}>{estado.estado}</option>
                                                    )
                                                ) : (null)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="float-left mb-0 font-size-08 font-weight-bold">Contraseña</label>
                                            <input id="password" type="password" className="form-control py-0 font-size-08" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="float-left mb-0 font-size-08 font-weight-bold">Confirmar contraseña</label>
                                            <input id="repassword" type="password" className="form-control py-0 font-size-08" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="float-left mb-0"><input id="terminosyc" type="checkbox" className="float-left mr-1 h-auto" /><span id="termn" className="float-left text-left termyc font-size-08 font-weight-bold"> Acepto términos y condiciones y Aviso de Privacidad</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div onClick={() => this._registro()} className="px-5 py-2 mt-2 bg-pde-a50 cursor_pointer rounded">Ingresar</div>
                            </div>

                        </div>
                    </div>
                ) : (
                        null
                    )}
            </div>
        );
    }
}


/**
 *Componente que contiene todos los componentes de inicio de sesion y de registro
 *
 * @class IniciarSesion
 * @extends {React.Component}
 */
class IniciarSesion extends React.Component {
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        if (localStorage.getItem('token') !== null) {
            fetch(`${localStorage.getItem('urlAPI')}/api/user/validate`, {
                method: 'POST',
                body: JSON.stringify({
                    'tokenUsuario': localStorage.getItem('token')
                })
            })
                .then(response => response.json())
                .then(responseJSON => {
                    if (responseJSON.result.status_code === 'ok') {
                        window.location.href = "/webapp/app/vidaSana/";
                    }
                })
                .catch(err => console.log(err))
        }
    }

    render() {
        return (
            <div className="App">
                <header className="App-header position-relative">
                    <Splash />

                    <LogIn history={this.props.history} />

                    <SignIn history={this.props.history} />
                </header>
            </div>
        );
    }
}

export default IniciarSesion;