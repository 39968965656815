import React, { Component } from 'react';
import NavBar from '../navBar/NavBar';
import validateSession from '../checksession';
import btnSend from '../resources/avion.png';
import iconLike0 from '../resources/like_norelleno.png';
import iconLike1 from '../resources/like_relleno.png';
import iconReport0 from '../resources/reportar0.png';
import iconReport1 from '../resources/reportar1.png';

import logoColores from '../resources/logoColores.png';
import $ from 'jquery';
import Modal from '../Modal';
/**
 *Componente que tiene el chat de comunidad PDE
 *
 * @class comunidadPde
 * @extends {Component}
 */
class comunidadPde extends Component {
    state = {
        toggle: false,
        idChat: 0,
        temaChat: '',
        modalNotifi: false,
        modalReporte: false,
        selectedReport: 0,
        resporteId: 0,
        reportTxt: '',
        messageTxt: '',
        mensajesData: [],
        longitud: 0,
        notifiChat: false,
        longInicio: 0,
        longFin: 30,
        inicioModal: false
    }

    intervalo = 0;

    /**
     *Funcion donde se ejecuta las funciones necesarias para iniciar el chat
     *
     * @memberof comunidadPde
     */
    componentWillMount() {
        validateSession();
        if (localStorage.getItem('token') !== '0') {
            this._getFirstData();
        }
    }

    componentDidMount() {
        if (localStorage.getItem('token') === '0') {
            this.setState({
                inicioModal: true
            })
        }
    }

    /**
     *Funcion que detiene el intervalo cuando se desmonta el componente
     *
     * @memberof comunidadPde
     */
    componentWillUnmount() {
        clearInterval(this.intervalo)
    }

    /**
     *Funcion que obtiene los primeros datos del chat
     *
     * @memberof comunidadPde
     */
    _getFirstData = () => {
        fetch(`${localStorage.getItem("urlAPI")}/api/comunidad/chatActual`, {
            method: 'POST',
            body: JSON.stringify({
                "tokenUsuario": localStorage.getItem('token')
            })
        })
            .then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.result.data !== 'none') {
                    this.setState({
                        idChat: responseJSON.result.data.idchat,
                        temaChat: responseJSON.result.data.tema,
                        notifiChat: responseJSON.result.data.notificacion
                    })
                    this.intervalo = setInterval(() => { this._getData() }, 1000);
                } else {
                    this.setState({
                        idChat: -1,
                        temaChat: responseJSON.result.message
                    })
                }
            })
            .catch(err => console.log(err))
    }

    /**
     *Componente que obtiene la información de los mensajes
     *
     * @memberof comunidadPde
     */
    _getData = () => {
        fetch(`${localStorage.getItem("urlAPI")}/api/comunidad/listado`, {
            method: 'POST',
            body: JSON.stringify({
                "tokenUsuario": localStorage.getItem('token'),
                "idChat": this.state.idChat,
                "start": this.state.longInicio,
                "length": this.state.longFin
            })
        })
            .then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.result.data.total !== this.state.longitud) {

                    this.setState({
                        mensajesData: responseJSON.result.data.mensajes.reverse(),
                        longitud: responseJSON.result.data.total,
                    })
                    document.querySelector("#chat_comunidad").scrollTo(0, 10000000);
                }
            })
            .catch(err => console.log(err))
    }
    /**
     *Funcion que se ejecuta cuando se pagina el chat
     *
     * @memberof comunidadPde
     */
    _getDataPag = () => {
        fetch(`${localStorage.getItem("urlAPI")}/api/comunidad/listado`, {
            method: 'POST',
            body: JSON.stringify({
                "tokenUsuario": localStorage.getItem('token'),
                "idChat": this.state.idChat,
                "start": this.state.longInicio,
                "length": this.state.longFin
            })
        })
            .then(response => response.json())
            .then(responseJSON => {
                this.setState({
                    mensajesData: responseJSON.result.data.mensajes.reverse(),
                    longitud: responseJSON.result.data.total,
                })
            })
            .catch(err => console.log(err))
    }
    /**
     *Funcion para enviar mensajes
     *
     * @memberof comunidadPde
     */
    _sendMessage = () => {
        if (this.state.messageTxt !== '') {
            let mensajeContenido = this.state.messageTxt;
            this.setState({
                messageTxt:''
            },()=>{
                fetch(`${localStorage.getItem('urlAPI')}/api/comunidad/enviarMensaje`, {
                    method: 'POST',
                    body: JSON.stringify({
                        "tokenUsuario": localStorage.getItem('token'),
                        "idChat": this.state.idChat,
                        "mensaje": mensajeContenido
                    })
                })
                    .then(response => response.json())
                    .then(responseJSON => {
                        console.log(responseJSON)
                        if (responseJSON.result.success === true) {
                            
                          
                                this._getData();
                          
                            
                        } else {
                            alert("Intentar más tarde.")
                        }
                    })
                    .catch(err => console.log(err))
            })
            
        }
    }

    /**
     *Funcion para el boton de notificacion
     *
     * @memberof comunidadPde
     */
    _togglecheck = () => {

        if (this.state.toggle) {
            this.setState({
                toggle: false
            })
        } else {
            this.setState({
                modalNotifi: true
            })
        }

    }

    /**
     *Funcion para obtener el id del reporte
     *
     * @memberof comunidadPde
     */
    _selectReport = (rel) => {
        this.setState({
            selectedReport: rel
        })
    }

    /**
     *Funcion que enviar el mensaje reportado
     *
     * @memberof comunidadPde
     */
    _reportSend = () => {
        fetch(`${localStorage.getItem('urlAPI')}/api/comunidad/accionMensaje`, {
            method: 'POST',
            body: JSON.stringify({
                "tokenUsuario": localStorage.getItem("token"),
                "idMensaje": this.state.resporteId,
                "accion": 2,
                "motivo": this.state.selectedReport,
                "razon": this.state.reportTxt
            })
        })
            .then(response => response.json())
            .then(responseJSON => {
                let selector = document.querySelector(`#reporte${this.state.resporteId}`);
                let validar = selector.src.split("reportar")[1].split(".")[0];
                if (validar === '1') {
                    selector.src = iconReport0
                } else {
                    selector.src = iconReport1
                }
                $(selector).attr("data-disabled", true);
                this.setState({
                    modalReporte: false,
                    reportTxt: ''
                })
            })
            .catch(err => console.log(err))
    }

    /**
     *Funcion que da la autorización para enviar el reporte si se cumplen sus condiciones
     *
     * @memberof comunidadPde
     */
    _reportMessage = () => {
        if (this.state.selectedReport !== 4) {
            this._reportSend();
        } else {
            if (this.state.reportTxt !== '') {
                this._reportSend();
            } else {
                alert("Debe indicar el motivo en un máximo de 200 caracteres.");
            }
        }

    }

    /**
     *Función que envia el like al mensaje
     *
     * @memberof comunidadPde
     */
    _likeMessage = (id) => {
        fetch(`${localStorage.getItem('urlAPI')}/api/comunidad/accionMensaje`, {
            method: 'POST',
            body: JSON.stringify({
                "tokenUsuario": localStorage.getItem("token"),
                "idMensaje": id,
                "accion": 1,
                "motivo": 0,
                "razon": ""
            })
        })
            .then(response => response.json())
            .then(responseJSON => {
                let selector = document.querySelector(`#recibido${id}`);
                let validar = selector.src.split("like_")[1].split(".")[0];
                if (validar === 'relleno') {
                    selector.src = iconLike0
                } else {
                    selector.src = iconLike1
                }
            })
            .catch(err => console.log(err))
    }

    /**
     *Funcion que activa las notificaciones del chat
     *
     * @memberof comunidadPde
     */
    _notificacionChat = () => {
        fetch(`${localStorage.getItem('urlAPI')}/api/notificacion/activar`, {
            method: 'POST',
            body: JSON.stringify({
                'tokenUsuario': localStorage.getItem("token"),
                'pushChat': !this.state.notifiChat
            })
        })
            .then(response => response.json())
            .then(responseJSON => {
                if (this.state.notifiChat) {
                    this.setState({ modalNotifi: false, notifiChat: false })
                } else {
                    this.setState({ modalNotifi: false, notifiChat: true })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    /**
     *Función del paginado
     *
     * @memberof comunidadPde
     */
    _paginado = (value) => {
        if (value === 0) {
            this.setState({
                longFin: this.state.longFin + 30
            });
            document.querySelector("#chat_comunidad").scrollTo(0, 5);
            setTimeout(() => {
                this._getDataPag();
            }, 500);
        }
    }

    render() {
        return (
            <div className='position-relative'>
                {this.state.inicioModal ? (
                    <Modal history={this.props.history} val={false} close={() => this.setState({ inicioModal: false })}></Modal>
                ) : null}
                {this.state.modalNotifi ? (
                    <div className='cerrar_sesion_modal row mx-0 w-100 position-fixed' style={{ zIndex: '99' }}>
                        <div className='col-md-3 col-10 cont-cerrar-sesion p-3'>
                            <div className='cont-cs w-100 h-100 center_Text position-relative flex_column px-md-4 x-3'>
                                <div onClick={() => this.setState({ modalNotifi: false })} className='color-pde cerrar_modal'>x</div>
                                <img className='w-50 mb-3' alt='PDE' src={logoColores} />
                                <div className='color-pde'>
                                    <h5 className='text-center'>
                                        ¿Deseas recibir notificaciones sobre este tema?
                                    </h5>
                                </div>
                                <div className='w-100 d-flex btn_cerrar_s_cont'>
                                    <div className='px-md-5 px-4 py-2' onClick={() => this._notificacionChat()}>Sí</div>
                                    <div className='px-md-5 px-4 py-2' onClick={() => this.setState({ modalNotifi: false })}>No</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (null)}
                {this.state.modalReporte ? (
                    <div onClick={(event) => console.log(event.target.id)} className='position-fixed vh-100 vw-100 d-flex' style={{ backgroundColor: 'rgba(0,0,0,.5)', zIndex: '13', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='container-fluid'>
                            <div className='row d-flex' id='modal_report' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <div className='col-md-4 col-12 px-5'>
                                    <div className='w-100 bg-light p-2' style={{ height: '27rem', borderRadius: '13px' }}>
                                        <div className='w-100 h-100 p-2 d-flex' style={{ border: '2px dashed #ed63ac', borderRadius: '13px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                                            <img alt='Logo PDE' src={logoColores} style={{ width: '7rem' }} className='mb-2'></img>
                                            <div className='mb-2' style={{ fontWeight: 'bold', color: '#e73491' }}>Reportar</div>
                                            <div onClick={() => this._selectReport(1)} className={`w-100 d-flex justify-content-center reportar cursor_pointer reportarBtn ${this.state.selectedReport === 1 ? ('active') : (null)}`} style={{ borderWidth: '1px 0px 0px 0px', }}>Contenido antisonante</div>
                                            <div onClick={() => this._selectReport(2)} className={`w-100 d-flex justify-content-center reportar cursor_pointer reportarBtn ${this.state.selectedReport === 2 ? ('active') : (null)}`} style={{ borderWidth: '1px 0px 0px 0px', }}>Respuesta discriminatoria</div>
                                            <div onClick={() => this._selectReport(3)} className={`w-100 d-flex justify-content-center reportar cursor_pointer reportarBtn ${this.state.selectedReport === 3 ? ('active') : (null)}`} style={{ borderWidth: '1px 0px 0px 0px', }}>Respuesta ofensiva</div>
                                            <div onClick={() => this._selectReport(4)} className={`w-100 d-flex justify-content-center reportar cursor_pointer reportarBtn mb-2 ${this.state.selectedReport === 4 ? ('active') : (null)}`} style={{ borderWidth: '1px 0px 1px 0px', }}>Otra razón</div>
                                            {this.state.selectedReport === 4 ? (
                                                <textarea value={this.state.reportTxt} onChange={(event) => this.setState({ reportTxt: event.target.value })} placeholder='Escribe la causa...' className='w-100 px-2 mb-3 mt-2' style={{ borderRadius: '13px', border: '1px solid #ed63ac' }}>

                                                </textarea>
                                            ) : (null)}
                                            <div className='w-100 d-flex' style={{ justifyContent: 'center' }}>
                                                <div onClick={() => this.setState({ modalReporte: false, selectedReport: 0 })} className='btn cursor_pointer mr-4' style={{ backgroundColor: '#e5097f', color: 'white', fontWeight: 'bold' }}>Cancelar</div>
                                                <div onClick={() => this._reportMessage()} className='btn cursor_pointer' style={{ backgroundColor: '#e5097f', color: 'white', fontWeight: 'bold' }}>Aceptar</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (null)}

                <NavBar />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 col-md-6 offset-md-3 bg-light p-2 p-md-5 shadow-x' style={{ minHeight: '45rem', backgroundColor: '#fff', }}>
                            <div className="h2 titulo">Comunidad</div>
                            <div className='container-fluid'>
                                <div className='row mt-5' style={{ minHeight: '4.5rem' }}>
                                    <div className='col-7 pr-0'>
                                        <div className='w-100 h-100 border_radius shadow-x p-2'>
                                            <div className='w-100 h-100 border_dashed px-1' style={{ display: 'flex', flexDirection: 'column', fontSize: '0.85rem', justifyContent: 'center', color: '#e5097f' }}>
                                                {this.state.idChat !== -1 ? (
                                                    <div>Hoy estamos hablando de...</div>
                                                ) : (null)}

                                                <div style={{ fontWeight: 'bolder' }}>{this.state.temaChat}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-5'>
                                        <div className='w-100 h-100 border_radius shadow-x p-2 d-flex comunidad_toggle' style={{ color: '#818181', alignItems: 'center', fontSize: '0.9rem', justifyContent: 'space-around' }}>
                                            <div>Habilitar notificaciones</div>
                                            <div className='check_toggle_back' style={this.state.notifiChat ? ({ backgroundColor: '#e5097f' }) : ({ backgroundColor: '#9c9c9c' })}>
                                                <div onClick={() => this.state.notifiChat ? (this._notificacionChat()) : (this._togglecheck())} id='toggle' style={this.state.notifiChat ? ({ right: '0px' }) : ({ right: '55%' })} className={`check_toggle_circle ${this.state.notifiChat ? ('active') : ('')}`}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-12'>
                                        <div className='w-100 border_radius shadow-x p-3 position-relative' style={{ height: '30rem' }}>
                                            {this.state.idChat !== -1 ? (
                                                <div onScroll={(event) => this._paginado((event.target).scrollTop)} className='w-100 termnbox' id='chat_comunidad' style={{ maxHeight: '30rem', overflow: 'auto', height: '90%' }}>
                                                    {this.state.mensajesData.length > 0 ? (

                                                        this.state.mensajesData.map(mensaje =>
                                                            !mensaje.borrado ? (
                                                                !mensaje.emisor ? (
                                                                    <div key={mensaje.idmensajes} className='container-fluid mb-3 newMessage' style={{ minHeight: '7rem', }}>
                                                                        <div className='row' style={{ minHeight: '7rem' }}>
                                                                            <div className='col-2 col-md-1 px-0 d-flex' style={{ justifyContent: 'center', }}>
                                                                                <div style={{ width: '2rem', height: '2rem', borderRadius: '100%', backgroundColor: 'white', boxShadow: '0px 3px 7px -2px gray' }}>
                                                                                    <img className='w-100 h-100' style={{ objectFit: 'cover', borderRadius: '100%' }} alt='PDE' src={mensaje.fotoPerfil}></img>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-10 col-md-11 px-0 pt-1'>
                                                                                <div className='color-pde' style={{ fontSize: 'x-small', fontWeight: 'bold' }}>
                                                                                    {mensaje.nombre}
                                                                                </div>
                                                                                <div className='position-relative p-1 pb-3' style={{ minHeight: '3rem', backgroundColor: '#ef8bb8', borderRadius: '0px 13px 13px 13px', color: 'white', fontSize: 'small', fontWeight: 'bold', width: '85%' }}>
                                                                                    <span className='text-break'>
                                                                                        {mensaje.mensaje}
                                                                                    </span>
                                                                                    <div className='position-absolute' style={{ right: '3%', bottom: '3%', fontSize: 'xx-small' }}>
                                                                                        {mensaje.fechaHora.split(' ')[1]} {mensaje.fechaHora.split(' ')[2]}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='mt-1' style={{ width: '85%', fontSize: 'x-small', display: 'flex', justifyContent: 'flex-end' }}>
                                                                                    <div className='d-flex' style={{ alignItems: 'center' }}>
                                                                                        <img id={`recibido${mensaje.idmensajes}`} onClick={() => this._likeMessage(mensaje.idmensajes)} className='cursor_pointer mr-1' alt='PDE' src={mensaje.like ? (iconLike1) : (iconLike0)} style={{ width: '1.3rem', }} />
                                                                                        <div className='color-pde' style={{ fontWeight: 'bold', }}>Me gusta</div>
                                                                                    </div>
                                                                                    <div className='d-flex ml-3' style={{ alignItems: 'center' }}>
                                                                                        {mensaje.reportado ? (
                                                                                            <img alt='PDE' src={mensaje.reportado ? (iconReport1) : (iconReport0)} style={{ width: '1.3rem' }} />
                                                                                        ) : (
                                                                                                <img id={`reporte${mensaje.idmensajes}`} onClick={(event) => $(event.target).attr("data-disabled") == 'true' ? (null) : (this.setState({ modalReporte: true, resporteId: mensaje.idmensajes }))} data-disabled={false} className='cursor_pointer mr-1' alt='PDE' src={mensaje.reportado ? (iconReport1) : (iconReport0)} style={{ width: '1.3rem' }} />
                                                                                            )}
                                                                                        <div className='color-pde' style={{ fontWeight: 'bold', }}>Reportar</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                        <div key={mensaje.idmensajes} className='container-fluid mb-2' style={{ minHeight: '7rem', }}>
                                                                            <div className='row' style={{ minHeight: '7rem' }}>
                                                                                <div className='col-10 col-md-11 px-0 pt-1 d-flex' style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                                                                                    <div className='color-pde' style={{ fontSize: 'x-small', fontWeight: 'bold' }}>
                                                                                        {mensaje.nombre}
                                                                                    </div>
                                                                                    <div className='position-relative p-1 pb-3' style={{ minHeight: '3rem', backgroundColor: '#e5097f', borderRadius: '13px 0px 13px 13px', color: 'white', fontSize: 'small', fontWeight: 'bold', width: '85%' }}>
                                                                                        <span className='text-break'>
                                                                                            {mensaje.mensaje}
                                                                                        </span>
                                                                                        <div className='position-absolute' style={{ right: '3%', bottom: '3%', fontSize: 'xx-small' }}>
                                                                                            {mensaje.fechaHora.split(' ')[1]} {mensaje.fechaHora.split(' ')[2]}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-2 col-md-1 px-0 d-flex' style={{ justifyContent: 'center', }}>
                                                                                    <div style={{ width: '2rem', height: '2rem', borderRadius: '100%', backgroundColor: 'white', boxShadow: '0px 3px 7px -2px gray' }}>
                                                                                        <img className='w-100 h-100' style={{ objectFit: 'cover', borderRadius: '100%' }} alt='PDE' src={mensaje.fotoPerfil}></img>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                            ) : (null)
                                                        )

                                                    ) : (null)}

                                                </div>
                                            ) : (null)}
                                            <div className='w-100 position-absolute d-flex p-2' style={{ height: '10%', bottom: 0, left: 0, backgroundColor: '#e5097f', borderRadius: '0px 0px 5px 5px', alignItems: 'center' }}>
                                                <input id="enviaMensajeChat" onKeyDown={(event) => event.keyCode === 13 ? (this._sendMessage()) : (null)} value={this.state.messageTxt} onChange={(event) => this.setState({ messageTxt: event.target.value })} disabled={this.state.idChat !== -1 ? (false) : (true)} placeholder='Escribe un mensaje' className='w-100 h-100 pl-2' type='text' style={{ border: 0, borderRadius: '15px', fontSize: 'small', paddingRight: '2.2rem'}} />
                                                {this.state.idChat !== -1 ? (
                                                    <div style={{ position: 'absolute', cursor: 'pointer', right: '1.3rem', width: '1.3rem', }}> <img className='w-100' onClick={() => this._sendMessage()} src={btnSend}></img> </div>
                                                ) : (
                                                        <div style={{ position: 'absolute', right: '1.3rem', width: '1.3rem', }}> <img className='w-100' src={btnSend}></img> </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default comunidadPde;