import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import NavBar from "../navBar/NavBar";
import validateSession from "../checksession";
import $ from "jquery";
import like0 from "../resources/like0.png";
import like1 from "../resources/like1.png";
import share from "../resources/share.png";
import iconoplay from '../resources/play.png'
import Modal from "../Modal";
// import { Link } from 'react-router-dom';
// importamos firebase
import { messaging } from '../init-fcm';
var firebasecount = 0;

/**
 *Funciones de click
 *
 * @param {*} history
 */
function eventsClick(history, esto) {
    var esto = esto;
    $(".notas").click(function () {
        let id = $(this)
            .parent()
            .attr("data-id");
        history.push(`/webapp/app/notaPS?${id}`);
        var html = document.documentElement;
        html.scrollTop = 0;
    });
    $(".btns-actions").click(function () {
        let selector = $(this);
        let tipo = $(selector).attr("data-tipo");
        let id = $(selector).attr("data-id");
        if (tipo === "like") {
            if (localStorage.getItem('token') !== '0') {
                $.ajax({
                    type: "POST",
                    url: localStorage.getItem("urlAPI") + "/api/notas/megusta",
                    data: JSON.stringify({
                        tokenUsuario: localStorage.getItem("token"),
                        idNota: id
                    }),
                    dataType: "json",
                    success: function (response) {
                        if ($(selector).attr("data-active") === "0") {
                            $(selector).attr("src", like1);
                            $(selector).attr("data-active", "1");
                        } else {
                            $(selector).attr("src", like0);
                            $(selector).attr("data-active", "0");
                        }
                    },
                    error: function (response) {
                        console.log(response);
                        alert("Hubo un error");
                    }
                });
            } else {
                esto.setState({
                    inicioModal: true
                })
            }
        }

        if (tipo === "share") {
            let newHref = window.location.href.split("/pechoSano/")[0] + "/notaPS?" + id;
            var aux = document.createElement("input");
            aux.setAttribute("value", newHref);
            document.body.appendChild(aux);
            aux.select();
            if (document.execCommand("copy")) {
                alert("Copiado al portapapeles.");
            } else {
                alert("Error al copiar.");
            }
            document.body.removeChild(aux);
        }
    });
}

/**
 *Funcion que genera las tarjetas de notas tamaño grande
 *
 * @param {*} params
 * @returns tarjeta
 */
function NotaGdd(params) {
    var titulo = params.titulo
    var contenido = params.descripcion;
    var descripcion = contenido.replace(/<[^>]*>?/g, '');
    if (titulo.length > 35) {
        titulo = `${titulo.slice(0, 34)}<span class="color-pde">...</span>`;
    }
    const element = `
    <div data-id=${params.idnotas} class="cursor_pointer h-100 w-100 position-relative">
        <div class="botones-gd d-flex">
        <div class="w-50 h-100 p-2">
            <img data-tipo="like" data-active=${params.like ? "1" : "0"} data-id=${params.idnotas} class="btns-actions p-0 w-100 h-100 object_fit_contain" src=${params.like ? like1 : like0} />
        </div>
        <div class="w-50 h-100 p-2">
            <img data-tipo="share" data-id=${params.idnotas} class="btns-actions p-0 w-100 h-100 object_fit_contain" src=${share} />
        </div>
    </div>
    <div class="col-12 notas notaps px-0 w-100 h-100">
        <div class="imagenota w-100 h-100">
            <img class="portada" src=${params.imagenPrincipal} />
            ${
        params.tipoMultimedia == 2
            ? `<span class='position-absolute reproductor'><img class='h-100 w-100' style='object-fit:contain' alt='Nota' src=${iconoplay} /></span>`
            : ""
        }
        </div>
    <div class="datosnota pt-2 pb-2 pl-3 pr-3">
        <div class="row">
            <div class="col-12">
                <span class="color-pde">${titulo}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12 truncate-text">
                <span class="font-size-08">${descripcion}</span>
            </div>
        </div>
    </div>
    </div>
    </div>`;
    return element;
}

/**
 *Funcion que genera las tarjetas de notas tamaño mediano
 *
 * @param {*} params
 * @returns tarjeta
 */
function NotaMdd(params) {
    var titulo = params.titulo;
    var contenido = params.descripcion;
    var descripcion = contenido.replace(/<[^>]*>?/g, '');
    if (titulo.length > 35) {
        titulo = `${titulo.slice(0, 34)}<span class="color-pde">...</span>`;
    }
    const element = `
    <div data-id=${
        params.idnotas
        } class="cursor_pointer h-auto w-100 position-relative">
    <div class="botones-md">
        <img data-tipo="like" data-active=${params.like ? "1" : "0"} data-id=${params.idnotas} class="btns-actions p-2 impajuste" src=${
        params.like ? like1 : like0
        } />
        <img data-tipo="share" data-id=${params.idnotas} class="btns-actions p-2 impajuste" src=${share} />
    </div>
    <div class="w-100 notas notapsmd mb-3">
        <div class="imagenota">
            <img class="portada" src=${params.mosaico_a} />
            ${
        params.tipoMultimedia == 2
            ? `<span class='position-absolute reproductormd'><img class='h-100 w-100' style='object-fit:contain' alt='Nota' src=${iconoplay} /></span>`
            : ""
        }
        </div>
        
        <div class="datosnota pt-2 pb-2 pl-3 pr-3">
            <div class="row mb-0 mb-md-0">
                <div class="col-12 truncate-text2" style="height:2.7rem">
                    <span class="color-pde">${titulo}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 truncate-text">
                    <span class="font-size-08">${descripcion}</span>
                </div>
            </div>
        </div>

    </div>
    </div>
    `;
    return element;
}

/**
 *Funcion que genera las tarjetas de notas tamaño chico
 *
 * @param {*} params
 * @returns tarjeta
 */
function NotaChd(params) {
    var titulo = params.titulo;
    var contenido = params.descripcion;
    var descripcion = contenido.replace(/<[^>]*>?/g, '');
    if (titulo.length > 35) {
        titulo = `${titulo.slice(0, 34)}<span class="color-pde">...</span>`;
    }
    const element = `
    <div data-id=${
        params.idnotas
        } class="cursor_pointer h-auto w-100 position-relative">
    <div class="botones-ch">
        <img data-tipo="like" data-active=${params.like ? "1" : "0"} data-id=${params.idnotas} class="btns-actions p-2 impajuste" src=${
        params.like ? like1 : like0
        } />
        <img data-tipo="share" data-id=${params.idnotas} class="btns-actions p-2 impajuste" src=${share} />
    </div>
    <div class="w-100 notas notapssm mb-3">
        <div class="imagenota">
            <img class="portada" src=${params.mosaico_b} />
            ${
        params.tipoMultimedia == 2
            ? `<span class='position-absolute reproductorch'><img class='h-100 w-100' style='object-fit:contain' alt='Nota' src=${iconoplay} /></span>`
            : ""
        }
        </div>
        
        <div class="datosnota pt-2 pb-2 pl-3 pr-3">
            <div class="row mb-0 mb-md-0">
                <div class="col-12 truncate-text2" style="height:2.7rem">
                    <span class="color-pde">${titulo}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 truncate-text">
                    <span class="font-size-08">${descripcion}</span>
                </div>
            </div>
        </div>

    </div>
    </div>
    `;
    return element;
}

/**
 *Componente donde se muestran todos los datos de pecho sano
 *
 * @class pechoSano
 * @extends {Component}
 */
class pechoSano extends Component {
    state = {
        inicioModal: false
    }

    // firebaseConfig
    _getTokenFirebase() {

        if (firebasecount <= 1) {
    
          messaging.getToken().then((currentToken) => {
            if (currentToken) {
              fetch(`${localStorage.getItem('urlAPI')}/api/user/updateToken`, {
                method: 'POST',
                body: JSON.stringify({
                  "tokenUsuario": localStorage.getItem('token'),
                  "tokenFirebase": currentToken
                })
              })
                .then(response => response.json())
                .then(responseJSON => {
                  // console.log(responseJSON)
                })
                .catch(err => console.log(err))
            }
          }).catch((err) => {
            this._getTokenFirebase()
          });
    
          firebasecount += 1;
    
        }
      }
      
    componentDidMount() {
        if(messaging) {
            console.log("es soportado el navegador");
            this._getTokenFirebase()
        }
        var esto = this;
        let { history } = this.props;
        var data;
        if (localStorage.getItem('token') !== '0') {
            data = JSON.stringify({
                tokenUsuario: localStorage.getItem("token"),
                seccionNota: 1,
                categoriaId: 0
            })
        } else {
            data = JSON.stringify({
                seccionNota: 1,
                categoriaId: 0
            })
        }
        $.ajax({
            type: "POST",
            url: localStorage.getItem("urlAPI") + "/api/notas/listado",
            data: data,
            dataType: "json",
            success: function (response) {
                if (response.result.data !== "none") {
                    var cambio = 0;
                    $.each(response.result.data, function (i, nota) {
                        if (i === 0) {
                            $("#notaGrande").append(NotaGdd(nota)).hide().fadeIn("slow");
                        } else {
                            if (i % 2 === 1) {
                                if (cambio === 0) {
                                    $("#contps-izq").append(NotaMdd(nota)).hide().fadeIn("slow");
                                    cambio = 1;
                                } else {
                                    $("#contps-izq").append(NotaChd(nota)).hide().fadeIn("slow");
                                    cambio = 0;
                                }
                            } else {
                                if (cambio === 0) {
                                    $("#contps-der").append(NotaMdd(nota)).hide().fadeIn("slow");
                                    cambio = 0;
                                } else {
                                    $("#contps-der").append(NotaChd(nota)).hide().fadeIn("slow");
                                    cambio = 1;
                                }
                            }
                        }
                    });
                    eventsClick(history, esto);
                } else {
                    $("#notaGrande").append("No hay datos para mostrar.").hide().fadeIn("slow");
                }
            }
        });
    }

    componentDidUptdate() {
        $(".notas").click(function (e) {
            e.preventDefault();
            console.log(e.target);
        });
    }

    render() {
        validateSession();

        return (
            <div>
                {this.state.inicioModal ? (
                    <Modal history={this.props.history} val={true} close={() => this.setState({ inicioModal: false })}></Modal>
                ) : null}
                <NavBar />
                <div className="row mx-0">
                    <div className="col-12 col-md-6 offset-md-3 bg-light p-2 p-md-5 shadow-x">
                        <div className="row mb-5 mx-0">
                            <div className="col-12">
                                <div className="h2 titulo">Pecho Sano</div>
                            </div>
                        </div>

                        <div className="row px-3 mb-3 mx-0" id="notaGrande" />

                        <div className="row px-3 mx-0">
                            <div className="col-6 pr-3 pl-0" id="contps-izq" />
                            <div className="col-6 pl-3 pr-0" id="contps-der" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default pechoSano;
