import React, { Component } from 'react';
import NavBar from '../navBar/NavBar';
import validateSession from '../checksession';
// import Calendario from '../Calendario/Calendario';
import CalendarioPDE from '../Calendario/CalendarioPDE';
import Bienvenida from './Bienvenida';
import '../styles/css/style.css'
import Modal from '../Modal'
/**
 *Componente que tiene los datos generales del calendario
 *
 * @class miCalendario
 * @extends {Component}
 */
class miCalendario extends Component {
    state = {
        inicio: true,
        inicioModal: false
    }

    componentWillMount() {
        if (localStorage.getItem('token') !== '0') {
            this._getData()
        }
    }

    /**
     *Obtiene si ya se ah configurado o no el calendario para llevarlo a la bienvenida o al calendario
     *
     * @memberof miCalendario
     */
    _getData = () => {
        fetch(`${localStorage.getItem('urlAPI')}/api/calendario/getCalendario`, {
            method: 'POST',
            body: JSON.stringify({
                'tokenUsuario': localStorage.getItem('token')
            })
        })
            .then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.result.status_code === 'no_calendar') {
                    this.setState({
                        inicio: true
                    })
                } else {
                    this.setState({
                        inicio: false
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    _changeInicio = () => {
        this.setState({
            inicio: false
        })
    }

    render() {
        validateSession();
        return (
            <div>
                {this.state.inicioModal ? (
                    <Modal history={this.props.history} val={true} close={() => this.setState({ inicioModal: false })}></Modal>
                ) : null}
                <NavBar />
                <div className='container-fluid mt-4'>
                    <div className='row' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='col-11 col-md-5 mb-5 px-0 position-relative' style={{ borderRadius: '0.5rem', minHeight: '40rem', boxShadow: '0px 0px 8px -4px black', backgroundColor: '#fff' }}>
                            {this.state.inicio ? (
                                <Bienvenida val={() => this.setState({ inicioModal: true })} complete={this._changeInicio}></Bienvenida>
                            ) : (
                                    <CalendarioPDE ></CalendarioPDE>
                                )}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default miCalendario;