import * as firebase from "firebase/app";
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
    // Servidor Sferea
    // apiKey: "AIzaSyCQ0wdNJBgnAOiqw_pF8rwJiVkNhYbpYY4",
    // authDomain: "iospde-c8565.firebaseapp.com",
    // databaseURL: "https://iospde-c8565.firebaseio.com",
    // projectId: "iospde-c8565",
    // storageBucket: "iospde-c8565.appspot.com",
    // messagingSenderId: "350266722604",
    // appId: "1:350266722604:web:f6691a28c9d97076120489",
    // measurementId: "G-Y1K06ZSZ8J",
    // Servidor cliente
    apiKey: "AIzaSyAvL1E9AadA2Mfr__gFsWntdr-eugtGmXk",
    authDomain: "apppde-cd967.firebaseapp.com",
    databaseURL: "https://apppde-cd967.firebaseio.com",
    projectId: "apppde-cd967",
    storageBucket: "apppde-cd967.appspot.com",
    messagingSenderId: "825241451278",
    appId: "1:825241451278:web:f6ebb0ce6d1e4c333ab55f",
    measurementId: "G-FPYHF6EPKG"
});

// inicializamos en null messaging
let messaging = null

// const messaging = initializedFirebaseApp.messaging();

// apuntando al servidor de sferea, verificamos si el navegador es soportado
if(firebase.messaging.isSupported()) {
    console.log("es soportado el navegador");
    messaging = initializedFirebaseApp.messaging();
    messaging.usePublicVapidKey(
      "BH3kHRIZUKNrjvz1xyPFBbGW-5JoDoCH8z1zgmNb5OhbarURd4oW8blJAiDCtugNMYyPFR-4neXoBu4i7tGS21c"
    );
}

// Servidor Sferea
// messaging.usePublicVapidKey("BHqTFd59u-8KLmKhvRqbzDFm67RBMhLe-hpLCC_Ryu4PMpkD2pjHiHg2qn1Y16I6Gpms9IdKFBk37l6m_xrehgk");
// Servidor cliente
// messaging.usePublicVapidKey("BH3kHRIZUKNrjvz1xyPFBbGW-5JoDoCH8z1zgmNb5OhbarURd4oW8blJAiDCtugNMYyPFR-4neXoBu4i7tGS21c");
export { messaging };