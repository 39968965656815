import React, { Component } from 'react';
import NavBar from '../navBar/NavBar';
import validateSession from '../checksession';
// import imagen from '../resources/logoPDE2.jpg';

import phoneicon from '../resources/newPhone.png';
import placenicon from '../resources/newLocation.png';
import clockicon from '../resources/newclock.png';

import back from '../resources/back.png';
import $ from 'jquery';
/**
 *Componente que tiene la informacion de Checate
 *
 * @class checate
 * @extends {Component}
 */
class checate extends Component {
    componentDidMount() {
        if (localStorage.getItem('token') !== '0') {
            this._postData()
        } else {
            this._getData()
        }
    }

    _postData = () => {
        $.ajax({
            type: "POST",
            url: `${localStorage.getItem("urlAPI")}/api/checate/listado`,
            data: JSON.stringify({
                "tokenUsuario": localStorage.getItem("token")
            }),
            dataType: "json",
            success: function (response) {
            console.log(response)
                $.each(response.result.data, function (i, institucion) {
                    $("#Checate").append(`
                     <div class="col-12 col-md-6 mb-3 px-0 font-size-065">
                        <div class="wrap_checate box_shadow border_radius cursor_pointer select_checate" href="#institucion${institucion.idinstituciones}" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="institucion${institucion.idinstituciones}">
                            <div class="horizontal_center p-1 border_radius">
                                <div class="img_checate box_shadow p-1 mr-3">
                                    <img class="w-100 h-100 object_fit_contain" alt="PDE Checate" src=${institucion.logo} />
                                </div>
                                <div class="titulo_checate w-50">
                                    ${institucion.nombre}
                                </div>
                                <div data-active="0" class="circle_deploy m-auto box_shadow border_radius_100">
                                    <img alt="Deploy PDE" class="w-100 h-100 p-1 object_fit_contain" src=${back} />
                                </div>
                            </div>
                            <div class="collapse multi-collapse mb-3" id="institucion${institucion.idinstituciones}">
                                <div class="card card-body px-2" id="sucursales${institucion.idinstituciones}">


                                </div>
                            </div>
                        </div>
                    </div>
                     `).hide().fadeIn("slow");

                    if (institucion.sucursales.length > 0) {

                        $.each(institucion.sucursales, function (e, sucursal) {

                            var sucursalDetalle = `
                        <div class="col-12">
                            ${sucursal.sucursal}
                        </div>
                        `;
                            sucursalDetalle += `
                        <div class="col-12 d-flex">
                            <div class="imgchecate">
                                <img class="w-100 h-100" alt="PDE" src=${placenicon} />
                            </div>
                            <div class="datachecate pl-2">
                                ${sucursal.direccion}
                            </div>
                        </div>
                        `;
                            if (sucursal.horarioSemanalInicio.length > 0) {
                                sucursalDetalle += `
                            <div class="col-12 d-flex">
                                <div class="imgchecate">
                                    <img class="w-100 h-100 opacity_img" alt="PDE" src=${clockicon} />
                                </div>
                                <div class="datachecate pl-2">
                                    L-V ${sucursal.horarioSemanalInicio} - ${sucursal.horarioSemanalFin}
                                </div>
                            </div>
                            `;
                            }

                            if (sucursal.horarioSabatinoInicio.length > 0) {
                                sucursalDetalle += `
                            <div class="col-12 d-flex">
                                <div class="imgchecate">
                                    <img class="w-100 h-100 opacity_img" alt="PDE" src=${clockicon} />
                                </div>
                                <div class="datachecate pl-2">
                                    Sabado ${sucursal.horarioSabatinoInicio} - ${sucursal.horarioSabatinoFin}
                                </div>
                            </div>
                            `;
                            }
                            if (sucursal.horarioDominicalInicio.length > 0) {
                                sucursalDetalle += `
                            <div class="col-12 d-flex">
                                <div class="imgchecate">
                                    <img class="w-100 h-100 opacity_img" alt="PDE" src=${clockicon} />
                                </div>
                                <div class="datachecate pl-2">
                                    Domingo ${sucursal.horarioDominicalInicio} - ${sucursal.horarioDominicalFin}
                                </div>
                            </div>
                            `;
                            }
                            if (sucursal.telefono.length > 0) {
                                sucursalDetalle += `
                            <div class="col-12 d-flex">
                                <div class="imgchecate">
                                    <img class="w-100 h-100" alt="PDE" src=${phoneicon} />
                                </div>
                                <div class="datachecate pl-2">
                                    ${sucursal.telefono}
                                </div>
                            </div>
                            `;
                            }

                            $(`#sucursales${institucion.idinstituciones}`).append(`
                        <div style='text-align:"center"' class="shadow-x-2 py-2 mb-3">
                            ${sucursalDetalle}
                        </div>
                        `);

                        });

                    } else {
                        $(`#sucursales${institucion.idinstituciones}`).append(`
                        <div style='text-align: center;'>
                            No hay datos para mostrar.
                        </div>
                        `);
                    }

                });

                $(".select_checate").click(function () {
                    let flecha = $(this).children().find(".circle_deploy");
                    if ($(flecha).attr("data-active") === "0") {
                        $(flecha).children().eq(0).addClass("circle_deploy_active");
                        $(flecha).attr("data-active", "1");
                    } else {
                        $(flecha).children().eq(0).removeClass("circle_deploy_active");
                        $(flecha).attr("data-active", "0");
                    }
                });
            },
            error: function (response) {
                console.log(response);

            }
        });
    }

    _getData = () => {
        $.ajax({
            type: "GET",
            url: `${localStorage.getItem("urlAPI")}/api/checate/listado`,
            dataType: "json",
            success: function (response) {

                $.each(response.result.data, function (i, institucion) {
                    $("#Checate").append(`
                     <div class="col-12 col-md-6 mb-3 px-0 font-size-065">
                        <div class="wrap_checate box_shadow border_radius cursor_pointer select_checate" href="#institucion${institucion.idinstituciones}" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="institucion${institucion.idinstituciones}">
                            <div class="horizontal_center p-1 border_radius">
                                <div class="img_checate box_shadow p-1 mr-3">
                                    <img class="w-100 h-100 object_fit_contain" alt="PDE Checate" src=${institucion.logo} />
                                </div>
                                <div class="titulo_checate w-50">
                                    ${institucion.nombre}
                                </div>
                                <div data-active="0" class="circle_deploy m-auto box_shadow border_radius_100">
                                    <img alt="Deploy PDE" class="w-100 h-100 p-1 object_fit_contain" src=${back} />
                                </div>
                            </div>
                            <div class="collapse multi-collapse mb-3" id="institucion${institucion.idinstituciones}">
                                <div class="card card-body px-2" id="sucursales${institucion.idinstituciones}">


                                </div>
                            </div>
                        </div>
                    </div>
                     `).hide().fadeIn("slow");

                    if (institucion.sucursales.length > 0) {

                        $.each(institucion.sucursales, function (e, sucursal) {

                            var sucursalDetalle = `
                        <div class="col-12">
                            ${sucursal.sucursal}
                        </div>
                        `;
                            sucursalDetalle += `
                        <div class="col-12 d-flex">
                            <div class="imgchecate">
                                <img class="w-100 h-100" alt="PDE" src=${placenicon} />
                            </div>
                            <div class="datachecate pl-2">
                                ${sucursal.direccion}
                            </div>
                        </div>
                        `;
                            if (sucursal.horarioSemanalInicio.length > 0) {
                                sucursalDetalle += `
                            <div class="col-12 d-flex">
                                <div class="imgchecate">
                                    <img class="w-100 h-100 opacity_img" alt="PDE" src=${clockicon} />
                                </div>
                                <div class="datachecate pl-2">
                                    L-V ${sucursal.horarioSemanalInicio} - ${sucursal.horarioSemanalFin}
                                </div>
                            </div>
                            `;
                            }

                            if (sucursal.horarioSabatinoInicio.length > 0) {
                                sucursalDetalle += `
                            <div class="col-12 d-flex">
                                <div class="imgchecate">
                                    <img class="w-100 h-100 opacity_img" alt="PDE" src=${clockicon} />
                                </div>
                                <div class="datachecate pl-2">
                                    Sabado ${sucursal.horarioSabatinoInicio} - ${sucursal.horarioSabatinoFin}
                                </div>
                            </div>
                            `;
                            }
                            if (sucursal.horarioDominicalInicio.length > 0) {
                                sucursalDetalle += `
                            <div class="col-12 d-flex">
                                <div class="imgchecate">
                                    <img class="w-100 h-100 opacity_img" alt="PDE" src=${clockicon} />
                                </div>
                                <div class="datachecate pl-2">
                                    Domingo ${sucursal.horarioDominicalInicio} - ${sucursal.horarioDominicalFin}
                                </div>
                            </div>
                            `;
                            }
                            if (sucursal.telefono.length > 0) {
                                sucursalDetalle += `
                            <div class="col-12 d-flex">
                                <div class="imgchecate">
                                    <img class="w-100 h-100" alt="PDE" src=${phoneicon} />
                                </div>
                                <div class="datachecate pl-2">
                                    ${sucursal.telefono}
                                </div>
                            </div>
                            `;
                            }

                            $(`#sucursales${institucion.idinstituciones}`).append(`
                        <div style='text-align:"center"' class="shadow-x-2 py-2 mb-3">
                            ${sucursalDetalle}
                        </div>
                        `);

                        });

                    } else {
                        $(`#sucursales${institucion.idinstituciones}`).append(`
                        <div style='text-align: center;'>
                            No hay datos para mostrar.
                        </div>
                        `);
                    }

                });

                $(".select_checate").click(function () {
                    let flecha = $(this).children().find(".circle_deploy");
                    if ($(flecha).attr("data-active") === "0") {
                        $(flecha).children().eq(0).addClass("circle_deploy_active");
                        $(flecha).attr("data-active", "1");
                    } else {
                        $(flecha).children().eq(0).removeClass("circle_deploy_active");
                        $(flecha).attr("data-active", "0");
                    }
                });
            },
            error: function (response) {
                console.log(response);

            }
        });
    }

    render() {
        validateSession();
        return (
            <div>
                <NavBar />
                <div className="row mx-0">
                    <div className="alto col-12 col-md-6 offset-md-3 bg-light p-2 p-md-5 shadow-x">
                        <div className="row mb-5 mx-0">
                            <div className="col-12">
                                <div className="h2 titulo">Chécate</div>
                            </div>
                        </div>
                        <div className="row px-3 mb-3 mx-0">
                            <div className="cont_checat px-3 py-2">
                                <div className="row mx-0" id="Checate">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default checate;