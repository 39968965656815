import React, { Component } from 'react';
import NavBar from '../navBar/NavBar';
import validateSession from '../checksession';
import $ from 'jquery';
import back from '../resources/back.png';
/**
 *Componente que tiene el detalle de la imagen de la nota de vida sana
 *
 * @class ImagenVS
 * @extends {Component}
 */
class ImagenVS extends Component {
    componentDidMount() {
        var id = window.location.href.split("?")[1];
        let { history } = this.props;
        if (id != undefined) {
            var data = '';
            if (localStorage.getItem('token') !== '0') {
                data = JSON.stringify({
                    'tokenUsuario': localStorage.getItem("token"),
                    'idnotas': Number(id)
                })
            } else {
                data = JSON.stringify({
                    'idnotas': Number(id)
                })
            }
            $.ajax({
                type: "POST",
                url: `${localStorage.getItem('urlAPI')}/api/notas/detalle`,
                data: data,
                dataType: "json",
                success: function (response) {
                    $(".imagenDetalleImg").append(`<img id="imagenDetalle" className="cursor_pointer" src=${response.result.data.multimedia} alt="Imagen PDE" />`);
                },
                error: function (response) {
                    alert("error");
                    console.log(response);
                }
            });
        } else {
            alert("Llevando a listado de notas de pecho sano.");
            history.push(`/webapp/app/vidaSana/`);
        }
        $("#href").click(function (e) {
            e.preventDefault();
            history.push(`/webapp/app/notavs?${id}`);
        });
    }

    render() {

        validateSession();
        return (
            <div>
                <NavBar />
                <div className="row mx-0">
                    <div className="col-12 col-md-6 offset-md-3 bg-light px-2 pt-2 pb-0 px-md-5 pt-md-5 pb-md-0 shadow-x">
                        <div className="row mb-5 mx-0">
                            <div className="col-12 position-relative">
                                <div className="position-absolute cursor_pointer" id="backPS">
                                    <img id="href" src={back} alt="Image PDE" />
                                </div>
                                <div className="h2 titulo">Vida Sana</div>
                            </div>
                        </div>

                        <div className="row px-3 mb-3 mx-0 position-relative" id="">
                            <div className="imagenDetalleImg position-relative">
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
export default ImagenVS;