import React from 'react';
import './App.css';
import IniciarSesion from './iniciarSesion/IniciarSesion';
import perfil from './perfil/perfil';
import vidaSana from './vidaSana/vidaSana';
import miCalendario from './miCalendario/miCalendario';
import conoceTuRiesgo from './conoceTuRiesgo/conoceTuRiesgo';
import pechoSano from './pechoSano/pechoSano';
import unete from './unete/unete';
import checate from './checate/checate'
import AcercaDe from './acercaDe/acercaDe';
import comunidadPde from './comunidadPde/comunidadPde';
import DetallePS from './detalleNota/detallePS';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ImagenPS from './detalleNota/imagenPS';
import DetalleVS from './detalleNotaVS/detalleVS';
import ImagenVS from './detalleNotaVS/imagenVS';
import DetalleCS from './detalleNotaCS/detalleCS';
import ImagenCS from './detalleNotaCS/imagenCS';
// 27 firebase
// import { messaging } from './init-fcm';
import Legal from './Legal/Legal';
import corazonSano from './corazonSano/corazonSano';
/**
 *Componente que redirige a la ruta principal de la webapp
 *
 * @class Redirect
 * @extends {React.Component}
 */
class Redirect extends React.Component {
  componentWillMount() {
    this.props.history.push("/webapp/");
  }
  render() {
    return (
      <div>

      </div>
    );
  }
}
/**
 *Componente que contiene las rutas de toda la webapp
 *
 * @class App
 * @extends {React.Component}
 */
class App extends React.Component {
  render() {

    return (
      <Router>
        <Route path="/webapp/app/corazon" component={corazonSano} />
        <Route path="/webapp/app/imagenCS" component={ImagenCS} />
        <Route path="/webapp/app/notaCS" component={DetalleCS} />
        <Route path="/webapp/legal" component={Legal} />
        <Route path="/" exact component={Redirect} />
        <Route path="/webapp/" exact component={IniciarSesion} />
        <Route path="/webapp/app/miPerfil/" component={perfil} />
        <Route path="/webapp/app/vidaSana/" component={vidaSana} />
        <Route path="/webapp/app/notaVS" component={DetalleVS} />
        <Route path="/webapp/app/imagenVS" component={ImagenVS} />

        <Route path="/webapp/app/miCalendario/" component={miCalendario} />
        <Route path="/webapp/app/conoceTuRiesgo/" component={conoceTuRiesgo} />

        <Route path="/webapp/app/pechoSano/" component={pechoSano} />
        <Route path="/webapp/app/notaPS" component={DetallePS} />

        <Route path="/webapp/app/imagenPS" component={ImagenPS} />
        {/* <Route path="/webapp/app/unete/" component={unete} /> */}
        <Route path="/webapp/app/checate/" component={checate} />
        <Route path="/webapp/app/acercaDe/" component={AcercaDe} />
        <Route path="/webapp/app/comunidad/" component={comunidadPde} />
      </Router>
    );
  }
}

export default App;