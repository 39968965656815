import React, { Component } from 'react';
import flecha from '../resources/back.png';
import iconogota from '../resources/Assets/iconos/periodo.png';
import iconofertil from '../resources/Assets/iconos/fertilidad.png';
import iconomedicamentos from '../resources/Assets/iconos/medicamento.png';
import iconoAutoExploracionParaFamiliar from '../resources/Assets/iconos/familiar.png';
import iconoAutoExploracion from '../resources/Assets/iconos/autoexploracion.png';
import iconoiBreast from '../resources/Assets/iconos/Calendario_IconoPDE.png';
import iconoEdit from '../resources/lapiz.png';
import logoColores from '../resources/logoColores.png';
import moment from 'moment';
import Menu from '../miCalendario/Menu';
import iconoPildora from '../resources/Assets/iconos/pildora.png'
import iconoBebe from '../resources/bebe.png'
import iconoEsteto from '../resources/Assets/iconos/Calendario_IconoPDE.png'
import iconoFem from '../resources/Fem.png'
import iconoGota from '../resources/gota.png'
import iconoMujer from '../resources/mujer.png'
import iconoPastilla from '../resources/pastilla.png'
import TimeKeeper from 'react-timekeeper';
import iconoClock from '../resources/newclock.png'

import iconoPersona from '../resources/user.png'
import iconoCorreo from '../resources/Sobre.png'

import iconoCheck0 from '../resources/check_0.png'
import iconoCheck1 from '../resources/check_1.png'

import iconoBasura from '../resources/trash.png'

const meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
const dias = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
const calendarColumn = ["1", "2", "3", "4", "5", "6"];

const imgStyle = {
    width: '2rem',
    height: '2rem',
    boxShadow: '0px 2px 6px -3px',
    borderRadius: '100%'
}

/**
 *Componente que genera los iconos que van en el calendario
 *
 * @class IconosCalendario
 * @extends {Component}
 */
class IconosCalendario extends Component {

    state = {
        data: ''
    }

    componentDidMount() {
        var conteo = 0,
            data = this.props.data,
            content = [],
            long = this.props.longitud;
        delete data.prediccion;
        if (data.gota != undefined && conteo != long) {
            conteo += 1;
            content.push(iconogota);
        }
        if (data.fertil != undefined && conteo != long) {
            conteo += 1;
            content.push(iconofertil);
        }
        if (data.medicamentos != undefined && conteo != long) {
            conteo += 1;
            content.push(iconoPildora);
        }
        if (data.AutoExploracionParaFamiliar != undefined && conteo != long) {
            conteo += 1;
            content.push(iconoAutoExploracionParaFamiliar);
        }
        if (data.AutoExploracion != undefined && conteo != long) {
            conteo += 1;
            content.push(iconoAutoExploracion);
        }
        if (data.iBreast != undefined && conteo != long) {
            conteo += 1;
            content.push(iconoiBreast);
        }
        this.setState({
            data: content
        })
    }

    render() {
        return (
            <>
                {this.state.data !== '' ? (
                    this.state.data.map((icono, i) =>
                        <div key={i} style={{ width: '.65rem', height: '.65rem' }} className='d-flex justify-content-center'>
                            <img alt='PDE' src={icono} className='h-100 w-100' style={{ objectFit: 'contain' }}></img>
                        </div>
                    )
                ) : (null)
                }
            </>
        );
    }
}

/**
 *Componente que genera las tarjetas con la información del dia seleccionado
 *
 * @class Tarjeta
 * @extends {Component}
 */
class Tarjeta extends Component {

    state = {
        data: '',
        delete: false,
        type: true,
        id: 0
    }

    componentDidMount() {
        var data = this.props.data,
            content = [];
        delete data.prediccion;

        if (data.gota != undefined) {
            content.push([iconogota, data.gota]);
        }
        if (data.fertil != undefined) {
            content.push([iconofertil, data.fertil]);
        }
        if (data.medicamentos != undefined) {
            content.push([iconomedicamentos, data.medicamentos]);
        }
        if (data.AutoExploracionParaFamiliar != undefined) {
            content.push([iconoAutoExploracionParaFamiliar, data.AutoExploracionParaFamiliar]);
        }
        if (data.AutoExploracion != undefined) {
            content.push([iconoAutoExploracion, data.AutoExploracion]);
        }
        if (data.iBreast != undefined) {
            content.push([iconoiBreast, data.iBreast]);
        }
        if (data.hoy != undefined) {
            if (data.hoy !== '') {
                content.push([iconofertil, data.hoy.fertil]);
            }
        }
        this.setState({
            data: content
        })

    }

    /**
     *Funcion _deleteMedicamento que elimina el medicamento de la tarjeta pasando como parametro su ID
     *
     * @memberof Tarjeta
     */
    _deleteMedicamento = (id) => {
        fetch(`${localStorage.getItem('urlAPI')}/api/calendario/eliminarRecordatorioMedicamento`, {
            method: 'POST',
            body: JSON.stringify({
                'tokenUsuario': localStorage.getItem('token'),
                'idMedicamentos': id
            })
        })
            .then(response => response.json())
            .then(responseJSON => {
                // console.log(responseJSON)
                if (responseJSON.result.status_code === 'ok') {
                    window.location.reload()
                } else {
                    alert('Intentar más tarde.')
                }
            })
            .catch(err => {
                console.log(err)
                alert('Intentar más tarde.')
            })
    }

    /**
     *Funcion _delete que elimina un recordatorio pasando como parametro su ID
     *
     * @memberof Tarjeta
     */
    _delete = (id) => {
        fetch(`${localStorage.getItem('urlAPI')}/api/recordatorios/eliminarRecordatorio`, {
            method: 'POST',
            body: JSON.stringify({
                'tokenUsuario': localStorage.getItem('token'),
                'idrecordatorio': id
            })
        })
            .then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.result.status_code === 'ok') {
                    window.location.reload()
                } else {
                    alert('Intentar más tarde.')
                }
            })
            .catch(err => {
                console.log(err)
                alert('Intentar más tarde.')
            })
    }

    /**
     *Función que hara el filtro para saber que elemento borrar
     *
     * @memberof Tarjeta
     */
    _setDelete = () => {
        if (this.state.type) {
            this._delete(this.state.id)
        } else {
            this._deleteMedicamento(this.state.id)
        }
    }

    render() {
        return (
            <>
                {this.state.delete ? (
                    <div className='cerrar_sesion_modal row mx-0 position-fixed'>
                        <div className='col-md-6 col-10 cont-cerrar-sesion'>
                            <div className='cont-cs w-100 h-100 center_Text position-relative flex_column px-md-4 x-3'>
                                <div onClick={() => this.setState({ delete: false })} className='color-pde cerrar_modal'>x</div>
                                <img className='w-50 mb-3' alt='PDE' src={logoColores} />
                                <div className='color-pde'>
                                    <h5 className='text-center'>
                                        ¿Estás segura que desesas eliminar el recordatorio?
                                    </h5>
                                </div>
                                <div className='w-100 d-flex btn_cerrar_s_cont'>
                                    <div className='px-md-5 px-4 py-2' onClick={() => this._setDelete()}>Sí</div>
                                    <div className='px-md-5 px-4 py-2' onClick={() => this.setState({ delete: false })}>No</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ('')}
                {this.state.data !== '' ? (
                    this.state.data.map((icono, i) =>
                        typeof icono[1] == 'object' ? (
                            icono[1][0].tipo === 'iBreast' ? (
                                <div key={i} className='w-100 mt-3 d-flex justify-content-center border-calendar' style={{ minHeight: '4rem' }}>
                                    <div className='w-25 bg-pde-claro d-flex justify-content-center align-items-center'>
                                        <img style={{ height: '2rem', }} alt='PDE' src={icono[0]}></img>
                                    </div>
                                    <div className='fz-085 w-50 d-flex align-items-center justify-content-start pl-2 text-break'>
                                        Recordatorio de iBreastExam
                                    </div>
                                    <div className='fz-085 w-25 d-flex align-items-center justify-content-center pl-2 text-break cursor_pointer'>
                                        <img onClick={() => this.setState({ type: true, id: icono[1][0].id, delete: true })} style={{ height: '2rem' }} alt='PDE' src={iconoBasura}></img>
                                    </div>
                                </div>
                            ) : (
                                    icono[1][0].tipo === 'AutoExploracion para Familiar' ? (
                                        <div key={i} className='w-100 mt-3 d-flex justify-content-center border-calendar' style={{ minHeight: '4rem' }}>
                                            <div className='w-25 bg-pde-claro d-flex justify-content-center align-items-center'>
                                                <img style={{ height: '2rem', }} alt='PDE' src={icono[0]}></img>
                                            </div>
                                            <div className='fz-085 w-50 d-flex justify-content-start pl-2 flex-column text-break mt-2'>
                                                <div className='font-weight-bold'>
                                                    {icono[1][0].nombre_familiar}
                                                </div>
                                                <div style={{ fontSize: '.6rem', color: '#797979' }}>
                                                    {icono[1][0].correo_familiar}
                                                </div>
                                                <div className='mb-2' style={{ color: '#797979' }}>
                                                    {icono[1][0].mensaje}
                                                </div>
                                                <div className='font-weight-bold d-flex align-items-center mb-2' style={{ color: '#e5097f' }}>
                                                    <img className='mr-2' alt='Hora' src={iconoClock} style={{ height: '1rem' }}></img>
                                                    <div>
                                                        {moment(icono[1][0].hora, 'hh:mm').format('LT')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='fz-085 w-25 d-flex align-items-center justify-content-center pl-2 text-break cursor_pointer'>
                                                <img onClick={() => this.setState({ type: true, id: icono[1][0].id, delete: true })} style={{ height: '2rem' }} alt='PDE' src={iconoBasura}></img>
                                            </div>
                                        </div>
                                    ) : (
                                            icono[1][0].tipo === 'AutoExploracion' ? (
                                                <div key={i} className='w-100 mt-3 d-flex justify-content-center border-calendar' style={{ minHeight: '4rem' }}>
                                                    <div className='w-25 bg-pde-claro d-flex justify-content-center align-items-center'>
                                                        <img style={{ height: '2rem', }} alt='PDE' src={icono[0]}></img>
                                                    </div>
                                                    <div className='fz-085 w-50 d-flex justify-content-start pl-2 flex-column text-break mt-2'>
                                                        <div className='mb-2'>
                                                            {icono[1][0].mensaje}
                                                        </div>
                                                        <div className='font-weight-bold d-flex align-items-center mb-2' style={{ color: '#e5097f' }}>
                                                            <img className='mr-2' alt='Hora' src={iconoClock} style={{ height: '1rem' }}></img>
                                                            <div>
                                                                {moment(icono[1][0].hora, 'hh:mm').format('LT')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='fz-085 w-25 d-flex align-items-center justify-content-center pl-2 text-break cursor_pointer'>
                                                        <img onClick={() => this.setState({ type: true, id: icono[1][0].id, delete: true })} style={{ height: '2rem' }} alt='PDE' src={iconoBasura}></img>
                                                    </div>
                                                </div>
                                            ) : (
                                                    icono[1].map(data =>
                                                        <div key={data.id} className='w-100 mt-3 d-flex justify-content-center border-calendar' style={{ minHeight: '4rem' }}>
                                                            <div className='w-25 bg-pde-claro d-flex justify-content-center align-items-center'>
                                                                <img style={{ height: '3rem', }} alt='PDE' src={icono[0]}></img>
                                                            </div>
                                                            <div className='fz-085 w-50 d-flex justify-content-start pl-2 flex-column text-break mt-2'>
                                                                <div className='mb-2'>
                                                                    {data.nombre}
                                                                </div>
                                                                <div className='font-weight-bold d-flex align-items-center mb-2' style={{ color: '#e5097f' }}>
                                                                    <img className='mr-2' alt='Hora' src={iconoClock} style={{ height: '1rem' }}></img>
                                                                    <div>
                                                                        {moment(data.hora, 'hh:mm').format('LT')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='fz-085 w-25 d-flex align-items-center justify-content-center pl-2 text-break cursor_pointer'>
                                                                <img onClick={() => this.setState({ type: false, id: data.id, delete: true })} style={{ height: '2rem' }} alt='PDE' src={iconoBasura}></img>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                        )
                                )
                        ) : (
                                <div key={i} className='w-100 mt-3 d-flex justify-content-center border-calendar' style={{ minHeight: '4rem' }}>
                                    <div className='w-25 bg-pde-claro d-flex justify-content-center align-items-center'>
                                        <img style={{ height: '2rem', }} alt='PDE' src={icono[0]}></img>
                                    </div>
                                    <div className='fz-085 w-75 d-flex align-items-center justify-content-start pl-2 text-break'>
                                        {icono[1]}
                                    </div>
                                </div>
                            )
                    )
                ) : (null)
                }
            </>
        );
    }
}

/**
 *Componente que muestra el menú inicial por el cual se puede navegar en las opciones del calendario
 *
 * @class MenuInicial
 * @extends {Component}
 */
class MenuInicial extends Component {
    render() {
        return (
            <div className='pde-h-60 cont_cal d-flex justify-content-center align-items-center flex-column text-center' style={{ color: '#757575', fontSize: '.7rem' }}>
                <div className='d-flex my-3 align-items-center cont-menu-pde'>
                    <img onClick={() => this.props.changeMenu(2)} className='opacity-1 cursor_pointer mr-2' style={{ ...imgStyle }} alt='AutoExploracion de un familiar' src={iconoMujer}></img>
                    <div className='font-size-08'>
                        AutoExploracion de un familiar.
                    </div>
                </div>
                <div className='d-flex my-3 align-items-center cont-menu-pde'>
                    <img onClick={() => this.props.changeMenu(4)} className='opacity-1 cursor_pointer mr-2' style={{ ...imgStyle }} alt='Recordatorio de medicamento' src={iconoPastilla}></img>
                    <div className='font-size-08'>
                        Recordatorio de medicamento.
                    </div>
                </div>
                <div className='d-flex my-3 align-items-center cont-menu-pde'>
                    <img onClick={() => this.props.changeMenu(3)} className='opacity-1 cursor_pointer mr-2' style={{ ...imgStyle }} alt='Recordatorio de autoexploracion' src={iconoFem}></img>
                    <div className='font-size-08'>
                        Recordatorio de autoexploracion.
                    </div>
                </div>
                <div className='d-flex my-3 align-items-center cont-menu-pde'>
                    <div onClick={() => this.props.changeMenu(1)} alt='PDE' className='opacity-1 cursor_pointer mr-2 p-1' style={{ ...imgStyle }}>
                        <img src={iconoEsteto} className='w-100 h-100' alt='Recordatorio de iBreastExam' style={{ objectFit: 'contain' }}></img>
                    </div>
                    <div className='font-size-08'>
                        Recordatorio de iBreastExam.
                    </div>
                </div>
                <div className='text-body font-weight-bold' style={{ fontSize: '1rem' }}>
                    Selecciona el recordatorio <br></br> que deseas agregar.
                </div>
            </div>
        );
    }
}

/**
 *Componente que contiene los campos para agregar recordatorio de IBreastExam
 *
 * @class IBreastExam
 * @extends {Component}
 */
class IBreastExam extends Component {

    state = {
        time: '',
        timekeeper: false,
        data12: '',
        data24: '',
        check: false
    }

    /**
     *Funcion _sendRecordatorio que agrega el recordatorio
     *
     * @memberof IBreastExam
     */
    _sendRecordatorio = () => {
        if (this.state.data24 !== '') {
            fetch(`${localStorage.getItem('urlAPI')}/api/recordatorios/agregarRecordatorio`, {
                method: 'POST',
                body: JSON.stringify({
                    "tokenUsuario": localStorage.getItem('token'),
                    "fechaHora": `${moment(this.props.data).format('YYYY-MM-DD')} ${this.state.data24}`,
                    "tipo": 3,
                    "periodicidad": this.state.check ? (1) : (0),
                })
            })
                .then(response => response.json())
                .then(responseJSON => {
                    if (responseJSON.result.status_code === 'ok') {
                        alert(responseJSON.result.message)
                        window.location.reload()
                    } else {
                        alert(responseJSON.result.message)
                    }
                })
                .catch(err => {
                    console.log(err)
                    alert('Intentar más tarde.')
                })
        } else {
            alert('Llenar todos los datos.');
        }
    }

    render() {
        return (
            <div className='text-center pde-h-60 d-flex flex-column justify-content-center align-items-center'>
                <div className='h4'>
                    Agrega un recordatorio de iBreastExam
                </div>
                <div style={{ color: '#777777', fontSize: '.9rem' }}>
                    Es un estudio que utiliza tecnología de sensores electromagnéticos que realizan <br></br>
                    una palpación mecánica en la mama.
                </div>
                <div className='d-flex justify-content-center align-items-center flex-column my-5'>
                    <div className='text-left' style={{ width: '10rem', fontSize: '.9rem' }}>Hora</div>
                    {this.state.timekeeper ? (
                        <TimeKeeper onChange={(event) => this.setState({ data12: event.formatted12, data24: event.formatted24 })} onDoneClick={() => this.setState({ timekeeper: false })}></TimeKeeper>
                    ) : (null)}
                    <div onClick={() => this.setState({ timekeeper: true })} className='cursor_pointer d-flex justify-content-center align-items-center px-4 position-relative' style={{ width: '10rem', height: '2rem', border: '1px solid pink', borderRadius: '5px' }}>
                        <img alt='Horario' className='position-absolute' style={{ left: '7%', height: '1rem' }} src={iconoClock}></img>
                        {this.state.data12}
                    </div>

                </div>
                <div className='d-flex align-items-center my-2'>
                    <div style={{ fontSize: '.9rem', marginRight: '10px' }}>
                        Deseo configurar este día como recordatorio mensual
                    </div>
                    <img onClick={() => this.setState({ check: !this.state.check })} alt='PDE' src={this.state.check ? (iconoCheck1) : (iconoCheck0)} className='cursor_pointer' style={{ height: '1rem' }}></img>
                </div>
                <div onClick={() => this._sendRecordatorio()} className='px-5 py-2 cursor_pointer mt-3' style={{ backgroundColor: '#e5097f', width: '33%', borderRadius: '5px', color: 'white' }}>
                    Guardar
                </div>
            </div>
        );
    }
}

/**
 *Componente que contiene los campos para agregar recordatorio de Autoexploracion para un familiar
 *
 * @class AutoExpFam
 * @extends {Component}
 */
class AutoExpFam extends Component {
    state = {
        time: '',
        timekeeper: false,
        data12: '',
        data24: '',
        mensaje: '',
        nombre: '',
        correo: ''
    }

    /**
     *Funcion _sendRecordatorio que guarda el recordatorio 
     *
     * @memberof AutoExpFam
     */
    _sendRecordatorio = () => {
        if (this.state.data24 !== '' && this.state.mensaje !== '' && this.state.nombre !== '' && this.state.correo !== '') {
            fetch(`${localStorage.getItem('urlAPI')}/api/recordatorios/agregarRecordatorio`, {
                method: 'POST',
                body: JSON.stringify({
                    "tokenUsuario": localStorage.getItem('token'),
                    "fechaHora": `${moment(this.props.data).format('YYYY-MM-DD')} ${this.state.data24}`,
                    "tipo": 2,
                    "periodicidad": 0,
                    "mensaje": this.state.mensaje,
                    "nombre_familiar": this.state.nombre,
                    "correo_familiar": this.state.correo
                })
            })
                .then(response => response.json())
                .then(responseJSON => {
                    if (responseJSON.result.status_code === 'ok') {
                        alert(responseJSON.result.message)
                        window.location.reload()
                    } else {
                        alert(responseJSON.result.message)
                    }
                })
                .catch(err => {
                    console.log(err)
                    alert('Intentar más tarde.')
                })
        } else {
            alert('Llenar todos los datos.');
        }
    }

    render() {
        return (
            <div className='text-center pde-h-60 d-flex flex-column justify-content-center align-items-center'>
                <div className='h5 font-weight-bold'>
                    Agrega el recordatorio de autoexploración para un familiar
                </div>
                <div className='d-flex justify-content-center align-items-center flex-column my-2'>
                    <div style={{ fontSize: '.9rem' }}>Hora</div>
                    {this.state.timekeeper ? (
                        <TimeKeeper onChange={(event) => this.setState({ data12: event.formatted12, data24: event.formatted24 })} onDoneClick={() => this.setState({ timekeeper: false })}></TimeKeeper>
                    ) : (null)}
                    <div onClick={() => this.setState({ timekeeper: true })} className='cursor_pointer d-flex justify-content-center align-items-center px-4 position-relative inputPDE' style={{ width: '10rem', height: '2rem', borderRadius: '5px' }}>
                        <img alt='Horario' className='position-absolute' style={{ left: '7%', height: '1rem' }} src={iconoClock}></img>
                        {this.state.data12}
                    </div>
                </div>
                <div className='mt-2'>
                    <div style={{ fontSize: '.9rem' }}>Mensaje</div>
                    <textarea onChange={(event) => this.setState({ mensaje: event.target.value })} className='inputPDE form-control' style={{ width: '20rem' }}></textarea>
                </div>
                <div className='mt-2 position-relative'>
                    <div style={{ fontSize: '.9rem' }}>Familiar</div>
                    <img className='position-absolute' style={{ left: '2%', height: '1rem', bottom: '11%' }} src={iconoPersona}></img>
                    <input onChange={(event) => this.setState({ nombre: event.target.value })} type='text' className='inputPDE pl-4' style={{ borderRadius: '5px', width: '20rem' }}></input>
                </div>
                <div className='mt-2 position-relative'>
                    <img className='position-absolute' style={{ left: '2%', height: '.9rem', bottom: '15%' }} src={iconoCorreo}></img>
                    <input onChange={(event) => this.setState({ correo: event.target.value })} type='mail' className='inputPDE' style={{ borderRadius: '5px', width: '20rem', paddingLeft: '9%' }}></input>
                </div>
                <div onClick={() => this._sendRecordatorio()} className='px-5 py-2 cursor_pointer my-3' style={{ backgroundColor: '#e5097f', width: '33%', borderRadius: '5px', color: 'white' }}>
                    Guardar
                </div>
            </div>
        );
    }
}

/**
 *Componente que contiene los campos para guardar una Autoexploracion
 *
 * @class AutoExp
 * @extends {Component}
 */
class AutoExp extends Component {
    state = {
        time: '',
        timekeeper: false,
        data12: '',
        data24: '',
        check: false,
        mensaje: ''
    }
    /**
     *Funcino _sendRecordatorio que guarda el recordatorio
     *
     * @memberof AutoExp
     */
    _sendRecordatorio = () => {
        if (this.state.data24 !== '' && this.state.mensaje !== '') {
            fetch(`${localStorage.getItem('urlAPI')}/api/recordatorios/agregarRecordatorio`, {
                method: 'POST',
                body: JSON.stringify({
                    "tokenUsuario": localStorage.getItem('token'),
                    "fechaHora": `${moment(this.props.data).format('YYYY-MM-DD')} ${this.state.data24}`,
                    "tipo": 1,
                    "periodicidad": this.state.check ? (1) : (0),
                    "mensaje": this.state.mensaje,
                })
            })
                .then(response => response.json())
                .then(responseJSON => {
                    if (responseJSON.result.status_code === 'ok') {
                        alert(responseJSON.result.message)
                        window.location.reload()
                    } else {
                        alert(responseJSON.result.message)
                    }
                })
                .catch(err => {
                    console.log(err)
                    alert('Intentar más tarde.')
                })
        } else {
            alert('Llenar todos los datos.');
        }
    }
    render() {
        return (
            <div className='text-center pde-h-60 d-flex flex-column justify-content-center align-items-center'>
                <div className='h5 font-weight-bold'>
                    Agrega un recordatorio de autoexploración
                </div>
                <div style={{ color: '#777777', fontSize: '.9rem' }}>
                    Súmate a la lucha y agrega un recordatorio para tu iBreastExam.
                </div>
                <div className='d-flex justify-content-center align-items-center flex-column my-2'>
                    <div className='text-center' style={{ width: '10rem', fontSize: '.9rem' }}>Hora</div>
                    {this.state.timekeeper ? (
                        <TimeKeeper onChange={(event) => this.setState({ data12: event.formatted12, data24: event.formatted24 })} onDoneClick={() => this.setState({ timekeeper: false })}></TimeKeeper>
                    ) : (null)}
                    <div onClick={() => this.setState({ timekeeper: true })} className='cursor_pointer d-flex justify-content-center align-items-center px-4 position-relative inputPDE' style={{ width: '10rem', height: '2rem', borderRadius: '5px' }}>
                        <img alt='Horario' className='position-absolute' style={{ left: '7%', height: '1rem' }} src={iconoClock}></img>
                        {this.state.data12}
                    </div>

                </div>
                <div className='my-2'>
                    <div style={{ fontSize: '.9rem' }}>Mensaje</div>
                    <textarea onChange={(event) => this.setState({ mensaje: event.target.value })} className='inputPDE form-control' style={{ width: '20rem' }}></textarea>
                </div>
                <div className='d-flex align-items-center my-2'>
                    <div style={{ fontSize: '.9rem', marginRight: '10px' }}>
                        Deseo configurar este día como recordatorio mensual
                    </div>
                    <img onClick={() => this.setState({ check: !this.state.check })} alt='PDE' src={this.state.check ? (iconoCheck1) : (iconoCheck0)} className='cursor_pointer' style={{ height: '1rem' }}></img>
                </div>
                <div onClick={() => this._sendRecordatorio()} className='px-5 py-2 cursor_pointer my-3' style={{ backgroundColor: '#e5097f', width: '33%', borderRadius: '5px', color: 'white' }}>
                    Guardar
                </div>

            </div>
        );
    }
}

/**
 *Compoente que contiene los campos para guardar el recordatorio de un medicamento
 *
 * @class Medicamento
 * @extends {Component}
 */
class Medicamento extends Component {
    state = {
        time: '',
        timekeeper: false,
        data12: '',
        data24: '',
        nombre: '',
        dias: '',
        ultimaDosis: '',
        horas: ''
    }

    /**
     *Funcion _sendRecordatorio que guarda recordatorio
     *
     * @memberof Medicamento
     */
    _sendRecordatorio = () => {
        if (this.state.data24 !== '' && this.state.nombre !== '') {
            fetch(`${localStorage.getItem('urlAPI')}/api/calendario/agregarMedicamento`, {
                method: 'POST',
                body: JSON.stringify({
                    "tokenUsuario": localStorage.getItem('token'),
                    "nombre": this.state.nombre,
                    "fechaHoraInicio": `${moment(this.props.data).format('YYYY-MM-DD')} ${this.state.data24}`,
                    "periodicidad": Number(this.state.horas),
                    "duracion": Number(this.state.dias)
                })
            })
                .then(response => response.json())
                .then(responseJSON => {
                    if (responseJSON.result.status_code === 'ok') {
                        alert(responseJSON.result.message)
                        window.location.reload()
                    } else {
                        alert(responseJSON.result.message)
                    }
                })
                .catch(err => {
                    console.log(err)
                    alert('Intentar más tarde.')
                })
        } else {
            alert('Llenar todos los datos.');
        }
    }

    render() {
        return (
            <div className='text-center pde-h-60 d-flex flex-column justify-content-center align-items-center'>
                <div className='h5 font-weight-bold'>
                    Agrega el recordatorio de un medicamento
                </div>
                <div className='mt-3'>
                    <div className='font-weight-bold' style={{ fontSize: '.9rem' }}>
                        Indica el nombre del medicamento
                    </div>
                    <input onChange={(event) => this.setState({ nombre: event.target.value })} type='text' className='inputPDE p-1 text-center' style={{ borderRadius: '5px', fontSize: '.9rem', width: '18rem' }}></input>
                </div>
                <div className='mt-3'>
                    <div className='font-weight-bold' style={{ fontSize: '.9rem' }}>
                        Indica el número de días que tomarás el medicamento
                    </div>
                    <input onChange={(event) => this.setState({ dias: event.target.value })} type='number' className='inputPDE p-1 text-center' style={{ borderRadius: '5px', fontSize: '.9rem', width: '18rem' }}></input>
                </div>
                <div className='mt-3 d-flex justify-content-center align-items-center flex-column'>
                    <div className='font-weight-bold' style={{ fontSize: '.9rem' }}>
                        ¿A qué hora tomaste la última dosis?
                    </div>
                    {this.state.timekeeper ? (
                        <TimeKeeper onChange={(event) => this.setState({ data12: event.formatted12, data24: event.formatted24 })} onDoneClick={() => this.setState({ timekeeper: false })}></TimeKeeper>
                    ) : (null)}
                    <div onClick={() => this.setState({ timekeeper: true })} className='cursor_pointer d-flex justify-content-center align-items-center px-4 position-relative inputPDE' style={{ width: '18rem', height: '2rem', borderRadius: '5px', fontSize: '.9rem' }}>
                        {this.state.data12}
                    </div>
                </div>
                <div className='mt-3'>
                    <div className='font-weight-bold' style={{ fontSize: '.9rem' }}>
                        ¿Cada cuántas horas tomarás el medicamento?
                    </div>
                    <input onChange={(event) => this.setState({ horas: event.target.value })} type='number' className='inputPDE p-1 text-center' style={{ borderRadius: '5px', fontSize: '.9rem', width: '18rem' }}></input>
                </div>
                <div onClick={() => this._sendRecordatorio()} className='px-5 py-2 cursor_pointer mt-4 mb-1' style={{ backgroundColor: '#e5097f', width: '33%', borderRadius: '5px', color: 'white' }}>
                    Guardar
                </div>
            </div>
        );
    }
}

/**
 *Componente que contiene los campos para la supervision del periodo
 *
 * @class SupervisionPeriodo
 * @extends {Component}
 */
class SupervisionPeriodo extends Component {
    state = {
        check: 0
    }

    /**
     *Funcion _sendRecordatorio que guarda la configuración de la supersivión del periodo
     *
     * @memberof SupervisionPeriodo
     */
    _sendRecordatorio = () => {
        if (this.state.check !== 0) {
            fetch(`${localStorage.getItem('urlAPI')}/api/calendario/periodo`, {
                method: 'POST',
                body: JSON.stringify({
                    "tokenUsuario": localStorage.getItem('token'),
                    "dia": this.props.data,
                    "ajuste": this.state.check
                })
            })
                .then(response => response.json())
                .then(responseJSON => {
                    if (responseJSON.result.status_code === 'ok') {
                        window.location.reload()
                    }
                })
                .catch(err => {
                    console.log(err)
                    alert('Intentar más tarde')
                })
        }
    }
    render() {
        return (
            <div className='text-center pde-h-60 d-flex flex-column justify-content-center align-items-center'>
                <div className='h5 font-weight-bold'>
                    Indica uno de los siguientes datos para supervisar <br></br>
                    tu periodo menstrual
                </div>
                <div className='mt-3 d-flex align-items-center justify-content-between' style={{ width: '35%' }}>
                    <div style={{ fontSize: '.9rem' }}>Inicio de periodo</div>
                    <img onClick={() => this.setState({ check: 1 })} alt='PDE' src={this.state.check === 1 ? (iconoCheck1) : (iconoCheck0)} className='cursor_pointer' style={{ height: '1rem' }}></img>
                </div>
                <div className='mt-3 d-flex align-items-center justify-content-between' style={{ width: '35%' }}>
                    <div style={{ fontSize: '.9rem' }}>Finalización de periodo</div>
                    <img onClick={() => this.setState({ check: 2 })} alt='PDE' src={this.state.check === 2 ? (iconoCheck1) : (iconoCheck0)} className='cursor_pointer' style={{ height: '1rem' }}></img>
                </div>
                <div className='mt-3 d-flex align-items-center justify-content-between' style={{ width: '35%' }}>
                    <div>
                        <div className='text-left' style={{ fontSize: '.9rem' }}>
                            Pausar periodo
                        </div>
                        <div style={{ fontSize: '.7rem', color: '#a7a7a7' }}>
                            (Embarazo, menopausia, otros.)
                        </div>
                    </div>
                    <img onClick={() => this.setState({ check: 3 })} alt='PDE' src={this.state.check === 3 ? (iconoCheck1) : (iconoCheck0)} className='cursor_pointer' style={{ height: '1rem' }}></img>
                </div>
                <div onClick={() => this._sendRecordatorio()} className='px-5 py-2 cursor_pointer mt-4 mb-1' style={{ backgroundColor: '#e5097f', width: '33%', borderRadius: '5px', color: 'white' }}>
                    Guardar
                </div>
            </div>
        );
    }
}


/**
 *Componente que crea el calendario
 *
 * @export
 * @class CalendarioPDE
 * @extends {Component}
 */
export class CalendarioPDE extends Component {
    state = {
        dia: new Date().getDate(),
        diaInicio: new Date().getDay(),
        diaName: '',
        mes: new Date().getMonth(),
        mesName: '',
        anio: new Date().getFullYear(),
        diasOf: 0,
        diashtml: '',
        date: '',
        calendarioDias: [],
        daySelected: '',
        menu: false,
        menuActive: 0
    }
    /**
     *Funcion donde se obtienen los datos iniciales
     *
     * @memberof CalendarioPDE
     */
    async componentDidMount() {
        this.setState({
            date: new Date(this.state.anio, this.state.mes, this.state.dia),
            daySelected: moment().format('YYYY/MM/DD'),
            calendarioDias: await this._getDiasTotal()
        })
    }

    /**
     *Funcion donde se obtiene los datos del mes 
     *
     * @param {*} val
     * @returns resultData
     * @memberof CalendarioPDE
     */
    async _getDataCalendar(val) {
        var data,
            resultData = []
        await fetch(`${localStorage.getItem('urlAPI')}/api/calendario/verCalendario`, {
            method: 'POST',
            body: JSON.stringify({
                'tokenUsuario': localStorage.getItem('token'),
                'dia': val
            })
        })
            .then(response => response.json())
            .then(responseJSON => {
                data = responseJSON.result.data;
            })
            .catch(err => {
                console.log(err)
            });
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const dataKey = data[key];
                dataKey.fecha = key;

                resultData.push(dataKey)
            }
        }
        return resultData
    }

    /**
     *Funcion que extrae los dias totales del mes mostrado
     *
     * @returns total
     * @memberof CalendarioPDE
     */
    async _getDiasTotal() {
        var start,
            diasTotal,
            anteriores,
            fechaInit,
            calendarData;

        start = Number(new Date(this.state.anio, this.state.mes, 1).getDay());
        diasTotal = Number(new Date(this.state.anio, (this.state.mes + 1), 0).getDate());
        anteriores = Number(new Date(this.state.anio, (this.state.mes + 1) - 1, 0).getDate());

        var result = [],
            dias = 0,
            total = [],
            restantes = 1;

        if (start === 0) {
            start = 7
        }

        // Algoritmo de obtencion de primer dia
        if (start <= 1) {
            let mes = this.state.mes + 1;
            if (mes === 1) {
                fechaInit = `${this.state.anio - 1}/${12}/${start}`
            } else {
                fechaInit = `${this.state.anio}/${mes}/${start}`
            }
        } else {
            let mes = this.state.mes
            if (mes === 0) {
                fechaInit = `${this.state.anio - 1}/${12}/${(anteriores - start) + 2}`
            } else {
                fechaInit = `${this.state.anio}/${mes}/${(anteriores - start) + 2}`
            }
        }


        const resultData = await this._getDataCalendar(fechaInit);
        // Fin algoritmo

        for (var index = 0; index < 6; index++) {
            total.push(resultData.splice(0, 7))
        }
        return total;
    }

    /**
     *Funcion para ir un mes atrás
     *
     * @memberof CalendarioPDE
     */
    _backfecha() {
        var anio = this.state.anio;
        var mes = this.state.mes - 1;
        if (this.state.mes == 0) {
            anio -= 1;
            mes = 11
        }
        this.setState({
            mes: mes,
            anio: anio,
            date: new Date(anio, mes, this.state.dia),
        }, async () => {
            this.setState({
                calendarioDias: await this._getDiasTotal()
            })
        })
    }

    /**
     *Funcion para ir un mes adelante
     *
     * @memberof CalendarioPDE
     */
    _forwFecha = () => {
        var anio = this.state.anio;
        var mes = this.state.mes + 1;
        if (this.state.mes == 11) {
            anio += 1;
            mes = 0;
        }
        this.setState({
            mes: mes,
            anio: anio,
            date: new Date(anio, mes, this.state.dia),
        }, async () => {
            this.setState({
                calendarioDias: await this._getDiasTotal()
            })
        })

    }

    /**
     *Función para obtener los datos del dia seleccionado
     *
     * @memberof CalendarioPDE
     */
    _getDataDate = (e, selector) => {
        this.setState({
            daySelected: e
        })
        switch (selector) {
            case 0:
                this.setState({
                    dia: new Date(`${e}`).getDate()
                }, async () => {
                    this.setState({
                        calendarioDias: await this._getDiasTotal()
                    })
                })
                break;
            case 1:
                this._backfecha()
                break;
            case 2:
                this._forwFecha()
                break;

            default:
                break;
        }
    }

    /**
     *Funcion que setea informacion para el menu
     *
     * @memberof CalendarioPDE
     */
    _changeMenu = (menu) => {
        if (Number(menu) !== 6) {
            if (Number(menu === 5)) {
                if (moment().format('YYYY/MM/DD') >= moment().format(this.state.daySelected)) {
                    this.setState({
                        menuActive: Number(menu)
                    })
                }
            } else {
                this.setState({
                    menuActive: Number(menu)
                })
            }
        }
    }

    render() {
        return (
            !this.state.menu ? (


                <div className='h-100 w-100'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center flex-column px-0'>

                                <div className='row d-flex align-items-center mt-2' style={{ justifyContent: 'space-evenly' }}>
                                    <div style={{ height: '1.3rem', width: '1.3rem' }} className='cursor_pointer d-flex' onClick={() => this._backfecha()}>
                                        <img className='h-100 w-100' style={{ objectFit: 'contain', filter: 'grayscale(2)' }} src={flecha}></img>
                                    </div>
                                    <div className='d-flex align-items-center flex-column'>
                                        <div className='font-weight-bold text-capitalize' style={{ fontSize: 'smaller' }}>
                                            {meses[this.state.mes]}
                                        </div>
                                        <div style={{ color: 'gray', fontSize: 'small' }}>
                                            {this.state.anio}
                                        </div>
                                    </div>
                                    <div style={{ height: '1.3rem', width: '1.3rem' }} className='cursor_pointer d-flex' onClick={() => this._forwFecha()}>
                                        <img className='h-100 w-100 arrow_right' style={{ objectFit: 'contain', filter: 'grayscale(2)' }} src={flecha}></img>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center pt-2 pb-4' style={{ backgroundColor: '#fadbee' }}>
                                    <table className='col-lg-7'>
                                        <tbody>
                                            <tr>
                                                {dias.map((dia, i) =>
                                                    <th style={{ color: '#98828f', fontSize: 'small', width: '2rem' }} className='text-center' key={`${dia}${i}`}>
                                                        {dia.slice(0, 3).toUpperCase()}
                                                    </th>
                                                )}
                                            </tr>
                                            {calendarColumn.map((iteracion, i) =>
                                                <tr key={`${iteracion}${i}`}>
                                                    {this.state.calendarioDias.length > 0 ? (
                                                        this.state.calendarioDias[i].map((dia, otro) =>
                                                            <>
                                                                {
                                                                    moment().format(`${dia.fecha.split('/')[0]}/${Number(dia.fecha.split('/')[1])}`) === moment().format(`${this.state.anio}/${this.state.mes + 1}`) ? (
                                                                        <td key={`${dia}${otro}`} onClick={() => this._getDataDate(dia.fecha, 0)} className={`text-center cursor_pointer transition500 ${dia.fecha == this.state.daySelected ? ('bg-pde text-light') : ('bg-light')}`} style={{ border: '1px solid #f8d3ec', height: '3rem', fontSize: 'small', fontWeight: 'bold' }}>
                                                                            <div className='h-100 w-100'>
                                                                                <div className='h-50 d-flex justify-content-center align-items-end' style={dia.fecha == this.state.daySelected ? (null) : (dia.hoy != undefined ? ({ color: '#e5097f' }) : (null))}>
                                                                                    {dia.fecha.split('/')[2]}
                                                                                </div>
                                                                                <div className='d-flex h-25 justify-content-center align-items-center'>
                                                                                    {dia.length !== 0 ? (
                                                                                        Object.keys(dia).length - 1 === 1 ? (
                                                                                            <IconosCalendario key={dia.fecha} data={dia} longitud={1}></IconosCalendario>
                                                                                        ) : (
                                                                                                Object.keys(dia).length - 1 >= 2 ? (
                                                                                                    <IconosCalendario key={dia.fecha} data={dia} longitud={2}></IconosCalendario>
                                                                                                ) : (null)
                                                                                            )
                                                                                    ) : (null)}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    ) : (null)
                                                                }
                                                                {
                                                                    moment().format(`${dia.fecha.split('/')[0]}/${Number(dia.fecha.split('/')[1])}`) < moment().format(`${this.state.anio}/${this.state.mes + 1}`) ? (
                                                                        <td key={`${dia}${otro}`} onClick={() => this._getDataDate(dia.fecha, 1)} className={`text-center cursor_pointer`} style={{ backgroundColor: '#f3bfe7', border: '1px solid #f8d3ec', height: '3rem', fontSize: 'small', fontWeight: 'bold' }}>
                                                                            <div className='w-100 h-100'>
                                                                                <div className='h-50 d-flex justify-content-center align-items-end'>
                                                                                    {dia.fecha.split('/')[2]}
                                                                                </div>
                                                                                <div className='d-flex h-25 justify-content-center align-items-center'>
                                                                                    {dia.length !== 0 ? (
                                                                                        Object.keys(dia).length - 1 === 1 ? (
                                                                                            <IconosCalendario key={dia.fecha} data={dia} longitud={1}></IconosCalendario>
                                                                                        ) : (
                                                                                                Object.keys(dia).length - 1 >= 2 ? (
                                                                                                    <IconosCalendario key={dia.fecha} data={dia} longitud={2}></IconosCalendario>
                                                                                                ) : (null)
                                                                                            )
                                                                                    ) : (null)}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    ) : (null)
                                                                }
                                                                {
                                                                    moment().format(`${dia.fecha.split('/')[0]}/${Number(dia.fecha.split('/')[1])}`) > moment().format(`${this.state.anio}/${this.state.mes + 1}`) ? (
                                                                        <td onClick={() => this._getDataDate(dia.fecha, 2)} className='text-center cursor_pointer' style={{ backgroundColor: '#f3bfe7', border: '1px solid #f8d3ec', height: '3rem', fontSize: 'small', fontWeight: 'bold' }} key={`${dia}${otro}`}>
                                                                            <div className='w-100 h-100'>
                                                                                <div className='h-50 d-flex justify-content-center align-items-end'>
                                                                                    {dia.fecha.split('/')[2]}
                                                                                </div>
                                                                                <div className='d-flex h-25 justify-content-center align-items-center'>
                                                                                    {dia.length !== 0 ? (
                                                                                        Object.keys(dia).length - 1 === 1 ? (
                                                                                            <IconosCalendario key={dia.fecha} data={dia} longitud={1}></IconosCalendario>
                                                                                        ) : (
                                                                                                Object.keys(dia).length - 1 >= 2 ? (
                                                                                                    <IconosCalendario key={dia.fecha} data={dia} longitud={2}></IconosCalendario>
                                                                                                ) : (null)
                                                                                            )
                                                                                    ) : (null)}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    ) : (null)
                                                                }
                                                            </>
                                                        )
                                                    ) : (null)
                                                    }
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='d-flex w-100 justify-content-center mt-3 pb-3 mh-100'>
                                <div className='col-lg-8 px-4' style={{ minHeight: '3rem' }}>
                                    <div className='br-r br-l w-100 h-100 shadow-x p-3'>
                                        {this.state.daySelected !== '' ? (
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='fz-09 font-weight-bold'>
                                                    {moment(new Date(this.state.daySelected)).weekday() - 1 >= 0 ? (
                                                        `${dias[moment(new Date(this.state.daySelected)).weekday() - 1]}
                                                    , ${moment(new Date(this.state.daySelected)).date()} de ${meses[moment(new Date(this.state.daySelected)).month()]} ${moment(new Date(this.state.daySelected)).year()}`
                                                    ) : (
                                                            `${dias[6]}
                                                        , ${moment(new Date(this.state.daySelected)).date()} de ${meses[moment(new Date(this.state.daySelected)).month()]} ${moment(new Date(this.state.daySelected)).year()}`
                                                        )}
                                                </div>
                                                <div>
                                                    <img onClick={() => this.setState({ menu: true })} className='cursor_pointer' alt='PDE' src={iconoEdit} style={{ width: '1rem' }}></img>
                                                </div>
                                            </div>
                                        ) : (null)}
                                        {this.state.calendarioDias.map(data =>
                                            data.map((dataFinal, i) =>
                                                dataFinal.fecha === this.state.daySelected ? (
                                                    <Tarjeta key={`${i}${i + 1}`} data={dataFinal}></Tarjeta>
                                                ) : (null)
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            ) : (
                    <div className='h-100 w-100 position-absolute'>
                        <Menu menuActivo={this.state.menuActive} periodo={this.state.daySelected} changeMenu={this._changeMenu} day={moment(new Date(this.state.daySelected)).weekday() - 1 >= 0 ? (
                            `${dias[moment(new Date(this.state.daySelected)).weekday() - 1].toLocaleLowerCase()} ${moment(new Date(this.state.daySelected)).date()} ${meses[moment(new Date(this.state.daySelected)).month()]}`
                        ) : (
                                `${dias[6].toLocaleLowerCase()} ${moment(new Date(this.state.daySelected)).date()} ${meses[moment(new Date(this.state.daySelected)).month()]}`
                            )}></Menu>

                        {this.state.menuActive === 0 ? (
                            <MenuInicial changeMenu={this._changeMenu} data={this.state.daySelected}></MenuInicial>
                        ) : (null)}
                        {this.state.menuActive === 1 ? (
                            <IBreastExam data={this.state.daySelected}></IBreastExam>
                        ) : (null)}
                        {this.state.menuActive === 2 ? (
                            <AutoExpFam data={this.state.daySelected}></AutoExpFam>
                        ) : (null)}
                        {this.state.menuActive === 3 ? (
                            <AutoExp data={this.state.daySelected}></AutoExp>
                        ) : (null)}
                        {this.state.menuActive === 4 ? (
                            <Medicamento data={this.state.daySelected}></Medicamento>
                        ) : (null)}
                        {this.state.menuActive === 5 ? (
                            <SupervisionPeriodo data={this.state.daySelected}></SupervisionPeriodo>
                        ) : (null)}
                    </div>
                )
        )
    }
}

export default CalendarioPDE