import React, { Component } from 'react'
import fondoPDE from '../resources/cabecera.png'
import iconoBebe from '../resources/bebe.png'
import iconoEsteto from '../resources/Calendario_IconoPDE.png'
import iconoFem from '../resources/Fem.png'
import iconoGota from '../resources/gota.png'
import iconoMujer from '../resources/mujer.png'
import iconoPastilla from '../resources/pastilla.png'
import iconoBack from '../resources/back.png'
// import moment from 'moment'

const imgStyle = {
    width: '2rem',
    height: '2rem',
    boxShadow: '0px 2px 6px -3px',
    borderRadius: '100%'
}
// pde-menu-selected
/**
 *Componente que contiene el menú principal para guardar recordatorios
 *
 * @export
 * @class Menu
 * @extends {Component}
 */
export class Menu extends Component {

    state = {
        active: true,
        day: ''
    }

    componentDidMount() {
        if (this.props.active !== undefined) {
            this.setState({
                active: false
            })
        }
        if (this.props.day !== undefined) {
            this.setState({
                day: this.props.day
            })
        }
        // console.log(this.props.periodo)
        // console.log(moment().format(this.props.periodo) <= moment().format('YYYY/MM/DD'))
        // console.log(moment().format(this.props.periodo))
        // console.log(moment().format('YYYY/MM/DD'))
    }

    render() {
        return (
            <div style={{ height: '40%', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', backgroundImage: `url(${fondoPDE})`, backgroundPosition: 'bottom', backgroundSize: 'cover' }} className='w-100 d-flex justify-content-center align-items-center position-relative'>
                {this.state.active ? (
                    <img onClick={() => this.props.menuActivo === 0 ? (window.location.reload()) : (this.props.changeMenu(0))} alt='Back' src={iconoBack} className='position-absolute cursor_pointer' style={{ top: '4%', left: '2%', height: '2rem', filter: 'brightness(0) invert(1)' }}></img>
                ) : (null)}
                <div className={`position-absolute ${this.state.active ? ('') : ('opacity-1')}`} style={{ borderRadius: '100%', height: '11rem', width: '11rem', border: '4px solid #e7308f' }}>
                    <div className='absolut_center position-absolute h-100 w-100 cont_cal flex-column font-weight-bold' style={{ borderRadius: '100%', color: 'white' }}>
                        {this.state.day !== '' ? (
                            <>
                                <div>
                                    {this.state.day.split(' ')[0]}
                                </div>
                                <div style={{ fontSize: '2rem' }}>
                                    {this.state.day.split(' ')[1]}
                                </div>
                                <div>
                                    {this.state.day.split(' ')[2]}
                                </div>
                            </>
                        ) : (null)}
                    </div>
                    {this.state.active ? (
                        <>
                            <img onClick={() => this.props.changeMenu(5)} alt='PDE' src={iconoGota} className={`position-absolute absolut_center menuCalendario ${this.state.active ? ('cursor_pointer') : (null)}`} style={{ ...imgStyle }}></img>
                            <img onClick={() => this.props.changeMenu(6)} alt='PDE' src={iconoBebe} className={`position-absolute absolut_center menuCalendario1 ${this.state.active ? ('cursor_pointer') : (null)}`} style={{ ...imgStyle }}></img>
                            <img onClick={() => this.props.changeMenu(4)} alt='PDE' src={iconoPastilla} className={`position-absolute absolut_center menuCalendario2 ${this.state.active ? ('cursor_pointer') : (null)}`} style={{ ...imgStyle }}></img>
                            <img onClick={() => this.props.changeMenu(3)} alt='PDE' src={iconoFem} className={`position-absolute absolut_center menuCalendario3 ${this.state.active ? ('cursor_pointer') : (null)}`} style={{ ...imgStyle }}></img>
                            <img onClick={() => this.props.changeMenu(2)} alt='PDE' src={iconoMujer} className={`position-absolute absolut_center menuCalendario4 ${this.state.active ? ('cursor_pointer') : (null)}`} style={{ ...imgStyle }}></img>
                            <div onClick={() => this.props.changeMenu(1)} alt='PDE' className={`bg-light border_radius_100 p-1 d-flex justify-contain-center align-items-center position-absolute absolut_center menuCalendario5 ${this.state.active ? ('cursor_pointer') : (null)}`} style={{ ...imgStyle }}>
                                <img src={iconoEsteto} className='w-100 h-100' style={{ objectFit: 'contain' }}></img>
                            </div>
                        </>
                    ) : (
                            <>
                                <img alt='PDE' src={iconoGota} className={`position-absolute absolut_center menuCalendario ${this.state.active ? ('cursor_pointer') : (null)}`} style={{ ...imgStyle }}></img>
                                <img alt='PDE' src={iconoBebe} className={`position-absolute absolut_center menuCalendario1 ${this.state.active ? ('cursor_pointer') : (null)}`} style={{ ...imgStyle }}></img>
                                <img alt='PDE' src={iconoPastilla} className={`position-absolute absolut_center menuCalendario2 ${this.state.active ? ('cursor_pointer') : (null)}`} style={{ ...imgStyle }}></img>
                                <img alt='PDE' src={iconoFem} className={`position-absolute absolut_center menuCalendario3 ${this.state.active ? ('cursor_pointer') : (null)}`} style={{ ...imgStyle }}></img>
                                <img alt='PDE' src={iconoMujer} className={`position-absolute absolut_center menuCalendario4 ${this.state.active ? ('cursor_pointer') : (null)}`} style={{ ...imgStyle }}></img>
                                <div alt='PDE' className={`bg-light border_radius_100 p-1 d-flex justify-contain-center align-items-center position-absolute absolut_center menuCalendario5 ${this.state.active ? ('cursor_pointer') : (null)}`} style={{ ...imgStyle }}>
                                    <img src={iconoEsteto} className='w-100 h-100' style={{ objectFit: 'contain' }}></img>
                                </div>
                            </>
                        )}

                </div>
            </div >
        )
    }
}

export default Menu
