import React, { Component } from 'react';
import { Link } from "react-router-dom";
import NavBar from '../navBar/NavBar';
import validateSession from '../checksession';
import logoPDE from '../resources/Assets/drawable-hdpi/Grupo 16446.png';
import avatar from '../resources/Grupo_17108.png';
import arrow from '../resources/chevron-right-solid.svg';

import darrow from '../resources/down-arrow.svg';
import Modal from '../Modal';

/**
 *Componente de conoce tu riesgo
 *
 * @class conoceTuRiesgo
 * @extends {Component}
 */
class conoceTuRiesgo extends Component {
    state = {
        bienvenida: true,
        dataPregunta: [],
        preguntaAnterior: 0,
        resultado: false,
        inicioModal: false
    }

    /**
     *Funcion que cambia la bienvenida
     *
     * @memberof conoceTuRiesgo
     */
    _changeStateWelcome = () => {
        if (this.state.bienvenida === true) {

            this.setState({
                bienvenida: false
            })
        } else {
            this.setState({
                bienvenida: true
            })
        }
    }

    /**
     *Funcion para finalizar el cuestionario
     *
     * @memberof conoceTuRiesgo
     */
    _finish = () => {
        this.setState({
            resultado: true
        })
    }

    /**
     *Funcion que envía la respuesta del cuestionario
     *
     * @memberof conoceTuRiesgo
     */
    _sendResponse = (answ) => {
        fetch(`${localStorage.getItem('urlAPI')}/api/test/respuestaUsuario`, {
            method: 'POST',
            body: JSON.stringify({
                "tokenUsuario": localStorage.getItem('token'),
                "idPregunta": localStorage.getItem('questActual'),
                "idOpcion": answ,
                "respuesta": answ
            })
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.result.data.siguientePregunta === 0) {
                    this._finish();
                } else {
                    this._getQuestions(responseJson.result.data.siguientePregunta);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    /**
     *Función que obtiene la pregunta
     *
     * @memberof conoceTuRiesgo
     */
    _getQuestions = (idPreg = -1) => {
        console.log(idPreg);
        fetch(`${localStorage.getItem('urlAPI')}/api/test/listado`, {
            method: 'POST',
            body: JSON.stringify({
                'tokenUsuario': localStorage.getItem('token'),
                "idPregunta": idPreg
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                localStorage.setItem('questActual', responseJson.result.data.idPregunta)
                this.setState({
                    dataPregunta: responseJson.result.data,
                })
            });
    }

    componentDidMount() {
        if (localStorage.getItem('token') !== '0') {
            this._getQuestions();
        }
    }

    render() {
        validateSession();
        return (
            <div>
                {this.state.inicioModal ? (
                    <Modal history={this.props.history} val={true} close={() => this.setState({ inicioModal: false })}></Modal>
                ) : null}
                <NavBar />
                <div className='col-md-6 offset-md-3 col-12 px-md-5 px-2 mt-4'>
                    <div className='bg-bienvenidaCR'>
                        {!this.state.resultado ? (
                            this.state.bienvenida ? (<Bienvenida val={() => this.setState({ inicioModal: true })} estado={this._changeStateWelcome}></Bienvenida>) : (<Preguntas getQuest={this._getQuestions} answare={this._sendResponse} data={this.state.dataPregunta} resultFunct={this._finish} estado={this._changeStateWelcome}></Preguntas>)
                        ) : (<Resultado />)}
                    </div>
                </div>
            </div>
        );
    }
}

/**
 *Componente que muestra la bienvenida a conoce tu riesgo
 *
 * @class Bienvenida
 * @extends {Component}
 */
class Bienvenida extends Component {
    render() {
        return (
            <div className='center_Text flex_column'>
                <div className='cont_img mb-4 mt-2 mt-md-0'>
                    <Link className="cursor_pointer" to="/webapp/app/acercaDe/" >
                        <img src={logoPDE} />
                    </Link>
                </div>
                <h1 className='text-light mb-5 text-center'>¡Únete a la lucha!</h1>
                <h6 className='text-light text-center mt-3 w-75'>Responder el siguiente test de prevención te permitirá detectar a tiempo cualquier anomalía y te ayudará a mantenerte atenta sobre cualquier riesgo.</h6>
                <div onClick={() => localStorage.getItem('token') !== '0' ? (this.props.estado()) : (this.props.val())} className="btn text-light mt-5 cursor_pointer bg-pde">Comenzar</div>
            </div>
        );
    }
}

let semaforo = true;
/**
 *Componente que muestra la pregunta
 *
 * @class Preguntas
 * @extends {Component}
 */
class Preguntas extends Component {
    state = {
        bandera: false,
        validacion: false,
        value1: 0,
        value2: '',
        prev: 0,
    }

    componentDidUpdate(prevProps, prevState) {
        if (semaforo) {
            this.setState({
                prev: prevProps.data.idPregunta,
            });
            semaforo = false
        } else {
            semaforo = true
        }
    }
    componentDidMount() {
    }
    myChangeHandler = (event) => {
        if (this.props.data.verificacion === 2) {

            this.setState({
                value2: event.target.value
            });
        } else {
            this.setState({
                value1: Number(event.target.value)
            });
        }
    }

    /**
     *Envío de tipo de pregunta 1
     *
     * @memberof Preguntas
     */
    _send1 = () => {
        if (this.props.data.respuestas.contestado) {
            if (this.state.value1 === 0) {

                this.props.answare(this.props.data.respuestas.respuesta);
                this.setState({
                    value1: 0
                });

            } else {
                this.props.answare(this.state.value1);
                this.setState({
                    value1: 0
                });
            }

        } else {
            if (this.state.value1 === 0) {
                alert("Por favor contestar la pregunta.");
            } else {
                this.props.answare(this.state.value1);
                this.setState({
                    value1: 0
                });
            }
        }
    }
    /**
     *Envío de tipo de pregunta 2
     *
     * @memberof Preguntas
     */
    _send2 = () => {

        if (this.props.data.respuestas.contestado) {
            if (this.state.value2 === '') {

                this.props.answare(this.props.data.respuestas.respuesta);
                this.setState({
                    value2: ''
                });

            } else {
                this.props.answare(this.state.value2);
                this.setState({
                    value2: ''
                });
            }

        } else {
            if (this.state.value2 === '') {
                alert("Por favor contestar la pregunta.");
            } else {
                this.props.answare(this.state.value2);
                this.setState({
                    value2: ''
                });
            }
        }

    }

    render() {
        return (
            <div className='center_Text flex_column w-100 h-100'>
                <div className='cont_arrow mb-4'>
                    <img onClick={this.state.prev === 0 ? this.props.estado : () => this.props.getQuest(this.state.prev)} className='cursor_pointer' alt='PDE' src={arrow} />
                </div>
                <div className='w-50 box_question font-size-08 mb-5 center_Text'>
                    {this.props.data.pregunta}
                </div>
                <div className='cont_box_ask bg-light mt-3 mb-5'>
                    <div className='box_ask h-100 w-100 px-3 pb-3 font-size-065'>
                        <img alt='PDE' src={avatar} className='position-absolute mx-auto' />
                        {this.props.data.tipoPregunta === 2 ? (
                            <div>
                                {this.props.data.respuestas.contestado ? (

                                    this.props.data.opciones.map(pregunta =>
                                        this.props.data.respuestas.idOpciones == pregunta.idOpciones ? (

                                            <div key={pregunta.idOpciones} onClick={() => this.props.answare(pregunta.idOpciones)} className="ask ask_active w-100 mb-2">
                                                {pregunta.opcion}
                                            </div>
                                        ) : (
                                                <div key={pregunta.idOpciones} onClick={() => this.props.answare(pregunta.idOpciones)} className="ask w-100 mb-2">
                                                    {pregunta.opcion}
                                                </div>
                                            )
                                    )
                                ) : (
                                        this.props.data.opciones.map(pregunta =>
                                            <div key={pregunta.idOpciones} onClick={() => this.props.answare(pregunta.idOpciones)} className="ask w-100 mb-2">
                                                {pregunta.opcion}
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        ) : (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className='color-pde text-center mb-2'>Escribe tu respuesta</div>
                                    <div className="ask2 w-100 mb-2">
                                        {

                                            this.props.data.verificacion === 1 ? (
                                                <input onChange={this.myChangeHandler} value={this.state.value1 === 0 ? ('') : (this.state.value1)} placeholder={this.props.data.respuestas.contestado ? (this.props.data.respuestas.respuesta) : ("")} type='number'></input>
                                            ) : (
                                                    <input onChange={this.myChangeHandler} value={this.state.value2} placeholder={this.props.data.respuestas.contestado ? (this.props.data.respuestas.respuesta) : ("")} type='text'></input>
                                                )
                                        }
                                    </div>
                                    <div onClick={this.props.data.verificacion === 1 ? (this._send1) : (this._send2)} className="btn text-light mt-3 cursor_pointer bg-pde mx-auto">Aceptar</div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}

/**
 *Componente que muestra el resultado
 *
 * @class Resultado
 * @extends {Component}
 */
class Resultado extends Component {
    state = {
        resultado: 0,
    }

    componentDidMount() {
        fetch(`${localStorage.getItem('urlAPI')}/api/test/resultado`, {
            method: 'POST',
            body: JSON.stringify({
                'tokenUsuario': localStorage.getItem('token')
            })
        })
            .then(response => response.json())
            .then(responseJson => {
                let res = Number(responseJson.result.data.resultado);

                this.setState({
                    resultado: (res * 100) / 3,
                })
            })
            .catch(err => {
                console.log(err);
            })
    }
    render() {
        return (
            <div className='center_Text flex_column w-100 h-100 text-light'>
                <label>
                    <h5>
                        ¡Gracias por realizar este test!
                    </h5>
                </label>
                <label>
                    <h5>
                        ¡Únete a la lucha!
                    </h5>
                </label>
                <label style={{ marginBottom: '5%' }}>Tu resultado:</label>
                <div style={{ width: '50%', height: '4rem', border: '2px solid white', borderRadius: '5px', marginBottom: '6%', backgroundColor: 'white', padding: '.4%' }}>
                    <div className='h-100 w-100' style={{ backgroundImage: 'linear-gradient(to left, red , yellow, green)', borderRadius: '5px', position: 'relative' }}>
                        <img alt='PDE' src={darrow} style={{ position: 'absolute', width: '10%', filter: 'invert(1)', bottom: '108%', left: `${this.state.resultado - 4.5}%`, transition: '500ms' }} />
                        <div style={{ width: '1%', height: '100%', backgroundColor: 'white', marginLeft: `${this.state.resultado}%`, transition: '500ms' }}>

                        </div>
                    </div>

                </div>
                <label>
                    <h3>
                        CHÉCATE
                    </h3>
                </label>
                <label className='h5' style={{ width: '50%', textAlign: 'center' }}>¿Podemos ayudarte a ti y a otras mujeres?</label>
                <Link style={{ width: '45%' }} to='/webapp/app/checate/'>
                    <div className="w-100 btn text-light mt-3 cursor_pointer bg-pde mx-auto">Ubícanos</div>
                </Link>
            </div>
        )
    }
}

export default conoceTuRiesgo;