import $ from 'jquery';
function validateSession() {
    var valtoken = localStorage.getItem("token");
    if (valtoken !== '0') {
        if (valtoken == null) {
            window.location.href = "/webapp/";
        } else {
            $.ajax({
                type: "POST",
                url: localStorage.getItem("urlAPI") + "/api/user/validate",
                data: JSON.stringify({
                    "tokenUsuario": valtoken
                }),
                dataType: "json",
                success: function (response) {
                    // console.log(response);
                },
                error: function (response) {
                    console.log(response);
                    console.log("error");
                    if (response.responseJSON.result.status_code == "invalid_token") {
                        window.location.href = "/webapp/";
                    }
                }
            });
        }
    }
}
export default validateSession;