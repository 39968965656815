import React, { Component } from 'react'

export class Legal extends Component {
    state = {
        privacidad: '',
        terminos: '',
        active: true
    }
    componentDidMount() {
        var url = window.location.origin;
        var protocol = window.location.protocol;
        if (url.indexOf('prevencionydeteccion') !== -1) {
            localStorage.setItem('urlAPI', `${protocol}//panel.prevencionydeteccion.mx`);
        } else {
            if (url.indexOf('localhost') !== -1) {
                localStorage.setItem('urlAPI', `${protocol}//pdeqa.sferea.com`);
            } else {
                if (url.indexOf('pdewebappqa') === -1) {
                    localStorage.setItem("urlAPI", `${protocol}//pde.sferea.com`);
                } else {
                    localStorage.setItem('urlAPI', `${protocol}//pdeqa.sferea.com`);
                }
            }
        }
        this._getData()
    }
    _getData = () => {
        fetch(`${localStorage.getItem('urlAPI')}/api/legal`)
            .then(response => response.json())
            .then(responseJSON => {
                var { data } = responseJSON.result
                this.setState({
                    privacidad: data.privacidad,
                    terminos: data.terminos
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
    render() {
        return (
            <div>
                <div className="row mx-0 vh-100">
                    <div className="col-12 col-md-6 offset-md-3 bg-light p-2 p-md-5 shadow-x">
                        <div className="h2 titulo">Legal</div>

                        <div className="w-100">
                            <div className="h-100 w-100">
                                <div className="row mt-4 mx-0">
                                    <div className="row w-100 mx-0 mb-3">
                                        <div className="col-12">
                                            <div className="selecttermn font-size-065">
                                                <div onClick={() => this.setState({ active: true })} className={`cursor_pointer center_Text selectAviso float-left w-50 h-100 ${this.state.active ? ('active') : ('')}`}>
                                                    <span style={{ fontWeight: 'bold' }}>Aviso de privacidad</span>
                                                </div>
                                                <div onClick={() => this.setState({ active: false })} className={`cursor_pointer center_Text selectTermyc float-left w-50 h-100 ${!this.state.active ? ('active') : ('')}`}>
                                                    <span style={{ fontWeight: 'bold' }}>Términos y condiciones</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row w-100 mx-0">
                                        <div className="col-12">
                                            <div className="termnbox font-size-08 text-left" style={{ height: '25rem' }}>
                                                {this.state.active ? (
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.privacidad }} >
                                                    </div>
                                                ) : (
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.terminos }} >
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Legal
