import React, { Component } from 'react'
import fondoPDE from '../resources/cabecera.png'
import Menu from './Menu'
import DatePicker from 'react-date-picker'
import $ from 'jquery'
import iconCalendar from '../resources/calendar.png'
import iconCheck_0 from '../resources/check_0.png'
import iconCheck_1 from '../resources/check_1.png'
import Modal from '../Modal'

var setNamesMonth = false;
/**
 *Componente que tiene la primera vista de la bienvenida
 *
 * @class ViewOne
 * @extends {Component}
 */
class ViewOne extends Component {
    render() {
        return (
            <div style={{ height: '60%' }} className='w-100 cont_cal flex_column'>
                <div className='txt_colorPDE h3 text-center'>¡Bienvenida al calendario de la mujer!</div>
                <ul className='vinetas'>
                    <li>
                        <span>
                            Supervisar tu periodo menstrual.
                        </span>
                    </li>
                    <li>
                        <span>
                            Recibir recordatorios de autoexploración.
                        </span>
                    </li>
                    <li>
                        <span>
                            Recordar la toma de medicamentos.
                        </span>
                    </li>
                </ul>
                <div onClick={() => localStorage.getItem('token') !== '0' ? (this.props.btn1()) : (this.props.val())} className='btn-calendario cursor_pointer'>Siguiente</div>
            </div >
        );
    }
}

/**
 *Componente que tiene el formulario que se tiene que llenar
 *
 * @class Form
 * @extends {Component}
 */
class Form extends Component {

    state = {
        completed: false,
        calendario: false,
        editFecha: '',
        editFechaFormat: '',
        menopausia: false,
        duracion: 0,
        duracionCiclo: 0,
    }

    componentDidUpdate(prevProps, prevState) {
        if (!setNamesMonth) {
            this._changeTextMonth()
            setNamesMonth = true
        }
    }

    /**
     *Cambia el formato del mes que se muestra en el formulario
     *
     * @memberof Form
     */
    _changeTextMonth = () => {
        var valueTxt = $(".react-date-picker__inputGroup__month");
        for (let index = 0; index < $(valueTxt).children().length; index++) {
            valueTxt.children()[index].innerHTML = valueTxt.children()[index].innerHTML.split(".")[0];
            valueTxt.children()[index].innerHTML = valueTxt.children()[index].innerHTML.slice(0, 1).toUpperCase() + valueTxt.children()[index].innerHTML.slice(1);
        }
    }

    /**
     *Funcion de toggle para el check
     *
     * @memberof Form
     */
    _check = () => {
        if (this.state.menopausia) {
            this.setState({
                completed: true,
                duracion: '',
                duracionCiclo: '',
                editFechaFormat: ''
            })
        } else {
            if (this.state.editFechaFormat !== '' && this.state.duracion !== '' && this.state.duracionCiclo !== '') {
                this.setState({
                    completed: true
                })
            } else {
                if (this.state.completed) {
                    this.setState({
                        completed: false
                    })
                }
            }
        }
    }

    /**
     *Abre el datepicker
     *
     * @memberof Form
     */
    openDatepicker = () => {
        this._calendar.toggleCalendar();
    };

    render() {
        return (
            <div style={{ height: '60%' }} className='w-100 cont_cal flex_column'>
                <div className={`h5 font-weight-bold text-center w-75 mb-3 ${this.state.menopausia ? ('opacity-1') : ('')}`}>Comencemos con tu periodo menstrual.</div>
                <div className={`w-50 fz-09 px-1 ${this.state.menopausia ? ('opacity-1') : ('')}`}>
                    <div className='text-center'>
                        ¿Cuándo inició tu último periodo?
                    </div>
                    <div className='d-flex'>
                        <DatePicker
                            ref={(r) => this._calendar = r}
                            onCalendarOpen={() => this.setState({ calendario: true })}
                            onCalendarClose={() => this.setState({ calendario: false })}
                            disabled={this.state.calendario}
                            locale='es-ES'
                            maxDate={new Date()}
                            onChange={(date) => {
                                this.setState({
                                    editFecha: `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`,
                                    editFechaFormat: date,
                                    calendario: false

                                }, () => {
                                    this._check()
                                });
                            }}
                            value={this.state.editFechaFormat}
                            calendarIcon='Calendar'
                            format='dd-MMM-y'
                            className='edit_input w-85 pl-1 py-1 picker br-l'
                        />
                        <div onClick={this.openDatepicker} className='w-15 inputPDE border-l-0 d-flex br-r justify-content-center align-items-center p-1'>
                            <img alt='Calendario' src={iconCalendar} className='w-50'></img>
                        </div>
                    </div>
                </div>
                <div className={`w-50 fz-09 px-1 d-flex mt-3 ${this.state.menopausia ? ('opacity-1') : ('')}`}>
                    <div>
                        <div className='text-center pr-4'>
                            <div>
                                ¿Cuántos días duró?
                            </div>
                            <input value={this.state.duracion !== 0 ? (this.state.duracion) : ('')} onChange={(event) => this.setState({ duracion: Number(event.target.value) }, () => { this._check() })} type='number' className='form-control py-1 h-auto fz-09 border-PDE'></input>
                        </div>
                    </div>
                    <div>
                        <div className='text-center pl-4'>
                            <div>
                                ¿De cuántos días es tu ciclo?
                            </div>
                            <input value={`${this.state.duracionCiclo !== 0 ? (this.state.duracionCiclo) : ('')}`} onChange={(event) => this.setState({ duracionCiclo: Number(event.target.value) }, () => { this._check() })} type='number' className='form-control py-1 h-auto fz-09 border-PDE'></input>
                        </div>
                    </div>
                </div>
                <div className='w-50 fz-09 px-1 my-3 d-flex align-items-center justify-content-between'>
                    <div className='mr-3'>
                        He comenzado la menopausia
                    </div>
                    <img onClick={() => this.setState({ menopausia: !this.state.menopausia }, () => { this._check() })} className='cursor_pointer' src={this.state.menopausia ? (iconCheck_1) : (iconCheck_0)} alt='PDE' style={{ width: '1rem', height: '1rem' }} />
                </div>
                {
                    this.state.completed ? (
                        <div onClick={() => this.props.setData(this.state)} className='btn-calendario cursor_pointer'>Guardar</div>
                    ) : (
                            <div style={{ opacity: '.5' }} className='btn-calendario cursor_pointer'>Siguiente</div>
                        )
                }
            </div >
        );
    }
}


/**
 *Pantalla de bienvenida que contiene otros componentes
 *
 * @export
 * @class Bienvenida
 * @extends {Component}
 */
export class Bienvenida extends Component {

    state = {
        pageOne: true
    }

    _changeView = () => {
        this.setState({
            pageOne: false
        })
    }

    /**
     *Funcion que guarda todos los datos de la configuración inicial
     *
     * @memberof Bienvenida
     */
    _setData = (data) => {
        fetch(`${localStorage.getItem('urlAPI')}/api/calendario/configuracioninicial`, {
            method: 'POST',
            body: JSON.stringify({
                "tokenUsuario": localStorage.getItem('token'),
                "fechaInicio": data.editFecha,
                "duracion": data.duracion,
                "ciclo": data.duracionCiclo,
                "menopausia": data.menopausia
            })
        })
            .then(response => response.json())
            .then(responseJSON => {
                this.props.complete();
            })
            .catch(err => {
                console.log(err)
                alert('Intentar más tarde.')
            })
    }

    render() {
        return (
            <div className='h-100 w-100 position-absolute' style={{ borderRadius: '0.5rem' }}>
                <Menu active={false}></Menu>
                {this.state.pageOne ? (
                    <ViewOne val={this.props.val} btn1={this._changeView}></ViewOne>
                ) : (
                        <Form setData={this._setData}></Form>
                    )}

            </div>
        )
    }
}

export default Bienvenida
