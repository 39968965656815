import React from "react";
import { Link } from "react-router-dom";
import logoPDENav from "../resources/Assets/drawable-hdpi/Grupo 16446.png";
// import perfilNav from "../resources/Assets/drawable-ldpi/Grupo 16474.png";
import vidaSanaNav from "../resources/Assets/drawable-ldpi/Grupo 16466.png";
import miCalendarioNav from "../resources/Assets/drawable-ldpi/Grupo 16467.png";
import conoceTuRiesgoNav from "../resources/Assets/drawable-ldpi/Grupo 16468.png";
import pechoSanoNav from "../resources/Assets/drawable-ldpi/Grupo 16469.png";
import uneteNav from "../resources/Assets/drawable-ldpi/Grupo 16470.png";
import checateNav from "../resources/Assets/drawable-ldpi/Grupo 16471.png";
import acercaDeNav from "../resources/Assets/drawable-ldpi/Grupo 16472.png";
import comunidadNav from "../resources/Assets/drawable-ldpi/Grupo 16473.png";
import MenuIconoCorazonSano from "../resources/Assets/iconos/MenuIconoCorazonSano.png";
import imgdefault from '../resources/imgdefault.png';
// import $ from 'jquery';
/**
 *Componente que contiene el navbar con el que se navega a traves de las diferentes secciones de la webapp
 *
 * @class NavBar
 * @extends {React.Component}
 */
class NavBar extends React.Component {

  state = {
    img: sessionStorage.getItem('data_usu') !== null ? (JSON.parse(sessionStorage.getItem('data_usu')).data_usu.fotoPerfil) : (imgdefault)
  }

  componentDidMount() {

    fetch(`${localStorage.getItem('urlAPI')}/api/user/profile`, {
      method: 'POST',
      body: JSON.stringify({
        'tokenUsuario': localStorage.getItem('token')
      })
    })
      .then(response => {
        if (response.status !== 403) {
          return response.json();
        }
      })
      .then(responseJson => {
        var data_usu = responseJson.result.data;
        if (responseJson.result.data.status_code !== 'invalid_token') {
          if (sessionStorage.getItem('data_usu') === null) {
            sessionStorage.setItem("data_usu", JSON.stringify({ data_usu }));
          }
          if (JSON.parse(sessionStorage.getItem('data_usu')).data_usu.fotoPerfil !== responseJson.result.data.fotoPerfil) {
            sessionStorage.setItem("data_usu", JSON.stringify({ data_usu }));
            this.setState({
              img: responseJson.result.data.fotoPerfil
            })
          }

        }
      })
      .catch((error) => { console.log(error) })

  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-pde-gradient shadow-pde py-0 z-index-10">
        <div className="navbar-brand pl-4">
          <Link to="/webapp/app/acercaDe/">
            <img src={logoPDENav} className="pde-logo" alt="PDE Logo" />
          </Link>
        </div>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse font-size-065" id="navbarText">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item active">
              <Link className="nav-link centrado-vh lineabottom" to="/webapp/app/miPerfil/">
                <div className="img_perfil">
                  <img onError={() => this.setState({ img: imgdefault })} alt="Imagen PDE" src={this.state.img} />
                </div>
                <span>Mi Perfil</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link centrado-vh lineabottom" to="/webapp/app/vidaSana/">
                <img alt="Imagen PDE" src={vidaSanaNav} />
                <span>Vida Sana</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link centrado-vh lineabottom"
                to="/webapp/app/miCalendario/"
              >
                <img alt="Imagen PDE" src={miCalendarioNav} />
                <span>Mi Calendario</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link centrado-vh lineabottom"
                to="/webapp/app/conoceTuRiesgo/"
              >
                <img alt="Imagen PDE" src={conoceTuRiesgoNav} />
                <span>Conoce tu Riesgo</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link centrado-vh lineabottom"
                to="/webapp/app/pechoSano/"
              >
                <img alt="Imagen PDE" src={pechoSanoNav} />
                <span>Pecho Sano</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link centrado-vh lineabottom" to="/webapp/app/corazon/">
                <img className='mr-1' style={{ height: '16px', }} alt="Imagen PDE" src={MenuIconoCorazonSano} />
                <span> Corazón Sano</span>
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link centrado-vh lineabottom" to="/webapp/app/unete/">
                <img alt="Imagen PDE" src={uneteNav} />
                <span>Únete a la Lucha</span>
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link centrado-vh lineabottom" to="/webapp/app/checate/">
                <img alt="Imagen PDE" src={checateNav} />
                <span>Chécate</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link centrado-vh lineabottom" to="/webapp/app/acercaDe/">
                <img className='mr-1' alt="Imagen PDE" src={acercaDeNav} />
                <span>Acerca de</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link centrado-vh lineabottom" to="/webapp/app/comunidad/" >
                <img alt="Imagen PDE" src={comunidadNav} />
                <span>Comunidad PDE</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavBar;
