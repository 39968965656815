import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../navBar/NavBar';
import validateSession from '../checksession';
import $ from 'jquery';
import like0 from '../resources/like0.png';
import like1 from '../resources/like1.png';
import share from '../resources/share.png';
import back from '../resources/back.png';

import clock from '../resources/newclock.png';
import calendar from '../resources/newcalendario.png';
import Modal from '../Modal';

/**
 *Componente que contiene el detalle de la nota de pecho sano
 *
 * @class DetallePS
 * @extends {Component}
 */
class DetallePS extends Component {
    state = {
        inicioModal: false
    }
    componentDidMount() {
        var id = window.location.href.split("?")[1];
        let componente = this;
        let { history } = this.props;
        if (id != undefined) {
            var data = '';
            if (localStorage.getItem('token') !== '0') {
                data = JSON.stringify({
                    'tokenUsuario': localStorage.getItem("token"),
                    'idnotas': Number(id)
                })
            } else {
                data = JSON.stringify({
                    'idnotas': Number(id)
                })
            }
            $.ajax({
                type: "POST",
                url: `${localStorage.getItem('urlAPI')}/api/notas/detalle`,
                data: data,
                dataType: "json",
                success: function (response) {
                    let data = response.result.data;
                    $(document.querySelector("#descDetalle")).html(data.descripcion);
                    $(document.querySelector("#tituloDetalle")).text(data.titulo);
                    $(document.querySelector("#autorDetalle")).text(`Por: ${data.autor}`);

                    $(document.querySelector("#fechaDetalle")).text(data.fecha);
                    $(document.querySelector("#horaDetalle")).text(data.hora);
                    $(document.querySelector("#like")).attr("src", data.likes ? like1 : like0);
                    $(document.querySelector("#like")).attr("data-active", data.likes ? "1" : "0");

                    $(".imagenDetalle").html("");
                    $(".imagenDetalle").append(`
                    <div id="autor" class="position-absolute">
                        <img alt="Autor Imagen PDE" src=${data.fotoPerfil}>
                    </div>
                    `).hide().fadeIn("slow");
                    switch (data.tipoMultimedia) {
                        case 1:
                            $(".imagenDetalle").append(`<img id="imagenDetalle" class="cursor_pointer" src=${data.multimedia} alt="Imagen PDE" />`).hide().fadeIn("slow");
                            $("#imagenDetalle").click(function () {
                                history.push(`/webapp/app/imagenPS?${id}`);
                            });
                            break;
                        case 2:
                            $(".imagenDetalle").append(`<video class="video cursor_pointer" src=${data.multimedia} class='position-absolute'></video>`).hide().fadeIn("slow");
                            $(".imagenDetalle").append(`<span class='position-absolute reproductor cursor_pointer'><span class='arrowRepr'> > </span></span>`).hide().fadeIn("slow");
                            var video = document.querySelector(".video");
                            function reproductorPDE() {
                                if (video.paused) {
                                    document.querySelector(".reproductor").style.display = "none";
                                    video.play();
                                } else {
                                    video.pause();
                                    document.querySelector(".reproductor").style.display = "block";
                                }
                            }
                            $(".reproductor").click(function () {
                                reproductorPDE();
                            });
                            $(".video").click(function () {
                                reproductorPDE();
                            });
                            document.querySelector("video").onended = function () {
                                document.querySelector(".reproductor").style.display = "block";
                            };
                            break;
                        case 3:
                            $(".imagenDetalle").append(`
                                <iframe id="ytdetalle" class="w-100 h-100" src="https://www.youtube.com/embed/${data.multimedia}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                `).hide().fadeIn("slow");
                            break;
                        default:
                            alert("Error");
                            break;
                    }


                    $("#notasuger").html("");
                    if (data.notasSimilares.length > 0) {
                        $.each(data.notasSimilares, function (i, notasimilar) {
                            $("#notasuger").append(`
                            <div class="notasug_2 redirect mx-4 mb-3" data_id="/webapp/app/notaVS?${notasimilar.idnotas}">
                                <div class="w-100 nota-sugerido cursor_pointer">
                                    <div class="img_sugerido">
                                        <img alt="Imagen PDE" src="${localStorage.getItem('urlAPI')}/${notasimilar.imagenPrincipal}" />
                                    </div>
                                    <div class="datos_sugerido font-size-065 text-white">
                                        <div class="row px-4">
                                            ${notasimilar.titulo}
                                        </div>
                                        <div class="row px-4 horizontal_center">
                                            <img alt="PDE" src=${calendar} class="icons_pde_ch mr-1" />
                                            <span class='text_notaSimilar'>
                                                ${notasimilar.fecha} 
                                            </span>
                                            <img alt="PDE" src=${clock} class="icons_pde_ch ml-auto mr-1" />
                                            <span class='text_notaSimilar'>
                                                ${notasimilar.hora}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            `);
                        });

                        $(".redirect").click(function () {
                            history.push($(this).attr("data_id"));
                            componente.componentDidMount();
                        });
                    } else {
                        $("#similaresDetalle").hide();
                    }

                },
                error: function (response) {
                    alert("error");
                    console.log(response);
                }
            });
        } else {
            alert("Llevando a listado de notas de pecho sano.");
        }

        $(".redirect").click(function () {
            history.push($(this).attr("data_id"));
        });

        $("#like").click(function () {
            if (localStorage.getItem('token') !== '0') {

                var condicion = $(this).attr("data-active");
                var selector = $(this);
                $.ajax({
                    type: "POST",
                    url: `${localStorage.getItem("urlAPI")}/api/notas/megusta`,
                    data: JSON.stringify({
                        "tokenUsuario": localStorage.getItem("token"),
                        "idNota": id
                    }),
                    dataType: "json",
                    success: function (response) {
                        if (condicion == 1) {
                            $(selector).attr("src", like0);
                            $(selector).attr("data-active", "0");
                        } else {
                            $(selector).attr("src", like1);
                            $(selector).attr("data-active", "1");
                        }
                    },
                    error: function (response) {
                        console.log(response);
                        alert("Ocurrio un error, intentarlo más tarde.");
                    }
                });
            } else {
                componente.setState({
                    inicioModal: true
                })
            }
        });

        $("#share").click(function () {

            let newHref = window.location.href;
            var aux = document.createElement("input");
            aux.setAttribute("value", newHref);
            document.body.appendChild(aux);
            aux.select();
            if (document.execCommand("copy")) {
                alert("Copiado al portapapeles.");
            } else {
                alert("Error al copiar.");
            }
            document.body.removeChild(aux);

        });

        $("#imagenDetalle").click(function () {
        });

        $(".nota-sugerido").click(function () {
            componente.componentDidMount();

        });

        window.scrollTo(0, 0);

    }

    render() {
        validateSession();
        return (
            <div>
                {this.state.inicioModal ? (
                    <Modal history={this.props.history} val={true} close={() => this.setState({ inicioModal: false })}></Modal>
                ) : null}
                <NavBar />
                <div className="row mx-0">
                    <div className="col-12 col-md-6 offset-md-3 bg-light px-2 pt-2 pb-0 px-md-5 pt-md-5 pb-md-0 shadow-x">
                        <div className="row mb-5 mx-0">
                            <div className="col-12 position-relative">
                                <div className="position-absolute cursor_pointer" id="backPS">
                                    <Link to="/webapp/app/pechoSano/">
                                        <img src={back} alt="Image PDE" />
                                    </Link>
                                </div>
                                <div className="h2 titulo">Pecho Sano</div>
                            </div>
                        </div>

                        <div className="row px-3 mb-3 mx-0 position-relative" id="">
                            <div className="botonesDetalle d-flex">
                                <div className="w-50 h-100 padding_detalle">
                                    <img id="like" data-active="" data-tipo="like" className="cursor_pointer btns-actions p-0 w-100 h-100 object_fit_contain" src={like0} />
                                </div>
                                <div className="w-50 h-100 padding_detalle">
                                    <img id="share" data-tipo="share" className="cursor_pointer btns-actions p-0 w-100 h-100 object_fit_contain" src={share} />
                                </div>
                            </div>
                            <div className="imagenDetalle position-relative">
                                <div id="autor" className="position-absolute">
                                    <img alt="Autor Imagen PDE" src="#" />
                                </div>
                            </div>
                        </div>

                        <div className="row px-3 mx-0 my-1 py-1">
                            <div className="float-left color-pde h4" id="tituloDetalle">

                            </div>
                        </div>
                        <div className="row px-3 mb-3 mx-0 space-between">
                            <div className="float-left color-pde font-weight-bold font-size-065 pl-3" id="autorDetalle">

                            </div>
                            <div className="float-right font-size-065 d-flex horizontal_center">
                                <img className="icons_pde mr-1" alt="PDE" src={calendar} />
                                <div id="fechaDetalle">

                                </div>
                                <img className="icons_pde mr-1 ml-4" alt="PDE" src={clock} />
                                <div id="horaDetalle">

                                </div>
                            </div>
                        </div>
                        <div className="row px-3 mx-0 mb-5">
                            <div className="font-size-08 w-100" id="descDetalle">

                            </div>
                        </div>
                        <div className="row py-3 mx-0 bg-sugeridos" id="similaresDetalle">
                            <div className="col-12">
                                <div className="row color-pde font-weight-bold mb-2 pl-5">
                                    Notas similares
                                </div>
                                <div className="row">

                                    <div style={{ justifyContent: 'center', flexFlow: 'wrap' }} id="notasuger" className="w-100 horizontal_center">

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default DetallePS;