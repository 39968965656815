import React from 'react';
import NavBar from '../navBar/NavBar';
import logoPDE from '../resources/logoPDE.png';
/**
 *Componente que contiene los datos de Acerca De.
 *
 * @class AcercaDe
 * @extends {React.Component}
 */
class AcercaDe extends React.Component {
    state = {
        queEs: '',
        mision: '',
        vision: ''
    }

    /**
     *Funcion _getDataAcercaDe que obtiene los datos de un Web Service
     *
     * @memberof AcercaDe
     */
    _getDataAcercaDe = () => {
        fetch(`${localStorage.getItem('urlAPI')}/api/acercaDe`)
            .then(response => response.json())
            .then(responseJson => {
                if (this.state.queEs === '') {
                    var data = responseJson.result.data;
                    var queEs = data.descripcion,
                        mision = data.mision,
                        vision = data.vision;
                    this.setState({
                        queEs: queEs,
                        mision: mision,
                        vision: vision
                    })
                }
            });
    }

    componentDidMount() {
        this._getDataAcercaDe()
    }
    render() {

        return (
            <div>
                {JSON.parse(sessionStorage.getItem("acercaDe")) ? (
                    <NavBar />
                ) : (null)}
                <div className="row mx-0">
                    <div className="col-12 col-md-6 offset-md-3 bg-light p-2 p-md-5 shadow-x">
                        <div className="h2 titulo">Acerca De</div>
                        <div className="row mx-0 my-4">
                            <div className="acercaimg mx-auto" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img alt='PDE' src={logoPDE} style={{ width: '15rem' }}></img>
                            </div>
                        </div>
                        <div className="row mx-0 mt-4 mb-0">
                            <span className="color-pde font-weight-bold">
                                ¿Qué es PDE?
                            </span>
                        </div>
                        <div className="row mx-0 mb-4 mt-1">
                            <span className="font-size-08">
                                {this.state.queEs}
                            </span>
                        </div>
                        <div className="row mx-0 mt-4 mb-0">
                            <span className="color-pde font-weight-bold">
                                Misión
                            </span>
                        </div>
                        <div className="row mx-0 mb-4 mt-1">
                            <span className="font-size-08">
                                {this.state.mision}
                            </span>
                        </div>
                        <div className="row mx-0 mt-4 mb-0">
                            <span className="color-pde font-weight-bold">
                                Visión
                            </span>
                        </div>
                        <div className="row mx-0 mb-4 mt-1">
                            <span className="font-size-08">
                                {this.state.vision}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default AcercaDe;