import React, { Component } from 'react'

export class Modal extends Component {
    render() {
        return (
            <div className='position-fixed vh-100 vw-100 d-flex justify-content-center align-items-center' style={{ backgroundColor: 'rgba(0,0,0,.5)', zIndex: 150 }}>
                <div className='col-11 col-lg-3 bg-light border_radius cont-cerrar-sesion p-3' style={{ height: '24rem' }}>
                    <div className='cont-cs w-100 h-100 d-flex justify-content-center align-items-center p-3 flex-column position-relative'>
                        <div onClick={() => this.props.val ? (this.props.close()) : (this.props.history.push('/webapp/app/vidaSana/'))} className='position-absolute cursor_pointer font-weight-bold firstVS' style={{ top: '1%', right: '3%' }}>x</div>
                        <div className='font-weight-bold h4'>Iniciar sesión</div>
                        <div className='text-center w-100 px-4 h5 my-5 firstVS'>Inicia sesión para poder utilizar todas las funcionalidades que te ofrece la App de la Mujer</div>
                        <div onClick={() => { localStorage.setItem('back', window.location.pathname); this.props.history.push('/webapp/'); }} className='bg-pde cursor_pointer rounded text-white font-weight-bold py-2 px-5'>Aceptar</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal
