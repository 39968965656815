import React, { Component } from "react";
import NavBar from "../navBar/NavBar";
import $ from "jquery";
import validateSession from "../checksession";
import arrow from '../resources/back.png';
/**
 *Componente que tiene toda la información del listado de unete a la lucha
 *
 * @class unete
 * @extends {Component}
 */
class unete extends Component {
  componentDidMount() {
    if (localStorage.getItem('token') !== '0') {
      this._postData()
    } else {
      this._getData()
    }

  }
  _postData = () => {
    $.ajax({
      type: "POST",
      url: `${localStorage.getItem("urlAPI")}/api/unete/listado`,
      data: JSON.stringify({
        tokenUsuario: localStorage.getItem("token")
      }),
      dataType: "json",
      success: function (response) {
        var instituciones = response.result.data;
        $.each(instituciones, function (i, institucion) {
          var enlace = "";
          if (institucion.enlace.split("/").length > 1) {
            enlace = institucion.enlace;
          } else {
            enlace = "#";
          }

          $("#UneteLucha").append(`
                  <div class="col-6 col-md-3 mb-3 px-0 font-size-065">
                      <div class="wrap_unete box_shadow border_radius">
                          <a href=${enlace} ${enlace != "#" ? "target='_blank'" : ""}>
                              <div class="unete_box px-2 pb-1">
                                  <div class="img_unete box_shadow border_radius d-flex justify-content-center align-items-center">
                                      <img class="w-100 h-100 object_fit_contain" alt="Unete a la Lucha" src=${institucion.logo} />
                                  </div>
                                  <div class="institucion_unete w-100 h-25 center_Text px-4 position-relative">
                                      <span style="word-break: break-word; text-align: center;" class="text-body inst-unete h-100 overflow-y-auto ${institucion.nombre.length < 25 ? "center_Text" : "py-2"}">${institucion.nombre}</span>
                                      ${enlace != "#" ? (`
                                        <div class="position-absolute h-50 w-25 right-0 p-1">
                                          <img class="w-100 h-100 object_fit_contain arrow_right" alt="PDE" src=${arrow} />
                                        </div>`
            ) : ("")}
                                  </div>
                              </div>
                          </a>
                      </div>
                  </div>
                  `).hide().fadeIn("slow");
        });
      },
      error: function (response) {
        console.log(response);
      }
    });
  }
  _getData = () => {
    $.ajax({
      type: "GET",
      url: `${localStorage.getItem("urlAPI")}/api/unete/listado`,
      dataType: "json",
      success: function (response) {
        var instituciones = response.result.data;
        $.each(instituciones, function (i, institucion) {
          var enlace = "";
          if (institucion.enlace.split("/").length > 1) {
            enlace = institucion.enlace;
          } else {
            enlace = "#";
          }

          $("#UneteLucha").append(`
                    <div class="col-6 col-md-3 mb-3 px-0 font-size-065">
                        <div class="wrap_unete box_shadow border_radius">
                            <a href=${enlace} ${enlace != "#" ? "target='_blank'" : ""}>
                                <div class="unete_box px-2 pb-1">
                                    <div class="img_unete box_shadow border_radius d-flex justify-content-center align-items-center">
                                        <img class="w-100 h-100 object_fit_contain" alt="Unete a la Lucha" src=${institucion.logo} />
                                    </div>
                                    <div class="institucion_unete w-100 h-25 center_Text px-4 position-relative">
                                        <span style="word-break: break-word; text-align: center;" class="text-body inst-unete h-100 overflow-y-auto ${institucion.nombre.length < 25 ? "center_Text" : "py-2"}">${institucion.nombre}</span>
                                        ${enlace != "#" ? (`
                                          <div class="position-absolute h-50 w-25 right-0 p-1">
                                            <img class="w-100 h-100 object_fit_contain arrow_right" alt="PDE" src=${arrow} />
                                          </div>`
            ) : ("")}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    `).hide().fadeIn("slow");
        });
      },
      error: function (response) {
        console.log(response);
      }
    });
  }
  render() {
    validateSession();
    return (
      <div>
        <NavBar />
        <div className="row mx-0">
          <div className="col-12 col-md-6 offset-md-3 bg-light p-2 p-md-5 shadow-x">
            <div className="row mb-5 mx-0">
              <div className="col-12">
                <div className="h2 titulo">Únete a la Lucha</div>
              </div>
            </div>
            <div className="row px-3 mb-3 mx-0">
              <div className="cont_unet px-3 py-2">
                <div className="row mx-0" id="UneteLucha">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default unete;
