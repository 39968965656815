import React, { Component } from 'react';
import NavBar from '../navBar/NavBar';
import $ from 'jquery';
import logoPDE from "../resources/Assets/drawable-hdpi/cabecera.png";
import iconEdit from '../resources/edit-regular.svg';
import perfilDum from '../resources/perfilDummy.png';
import IconCamera from '../resources/photo-camera.svg';
import iconCheck from '../resources/check-solid.svg';
// import DatePicker from 'react-date-picker/dist/entry.nostyle';
import DatePicker from 'react-date-picker';
import imgdefault from '../resources/imgdefault.png';
import back from '../resources/back.png';
import logo from '../resources/logoPDE3.jpg';
import logoColores from '../resources/logoColores.png';
import cabecera from '../resources/Bg1.png';
// import logoPDE from "../resources/Assets/drawable-hdpi/Grupo 16446.png";
// let expp = new RegExp(/^.{4,12}$/g);
import validateSession from '../checksession';
import Modal from '../Modal';
const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
/**
 *Componente que contiene todos los datos de perfil
 *
 * @class perfil
 * @extends {Component}
 */
class perfil extends Component {

    state = {
        paisesData: {},
        estadosData: {},
        // edit: true,
        editpass: false,
        edit: false,
        toggle1: false,
        toggle2: false,
        toggle3: false,
        loaded: false,
        fotoPerfil: JSON.parse(sessionStorage.getItem('data_usu')) !== null ? (JSON.parse(sessionStorage.getItem('data_usu')).data_usu.fotoPerfil) : (''),
        nombre: '',
        apellido: '',
        correo: '',
        fechaNacimiento: '',
        pais: '',
        estado: '',
        idpais: 0,
        idestado: 0,
        telefono: "",
        // 
        editImg: JSON.parse(sessionStorage.getItem('data_usu')) !== null ? (JSON.parse(sessionStorage.getItem('data_usu')).data_usu.fotoPerfil) : (''),
        editName: '',
        editApellido: '',
        editFecha: '',
        editFechaFormat: {},
        editPais: '',
        editEstado: '',
        editContrasenia: '',
        editContraseniaNva: '',
        editContraseniaNvaConfirm: '',
        editTelefono: "",
        options: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },

        cerrarSesion: false,
        calendario: false,
        inicioModal: false
    }
    /**
     *Funcion que obtiene todos los datos del usuario
     *
     * @memberof perfil
     */
    _getData() {
        fetch(`${localStorage.getItem('urlAPI')}/api/user/profile`, {
            method: 'POST',
            body: JSON.stringify({
                'tokenUsuario': localStorage.getItem('token')
            })
        })
            .then(response => response.json())
            .then(responseJson => {
                let data = responseJson.result.data;
                let dataFechaSplit = data.fechaNacimiento.split("/");

                let fechForm = new Date(`${dataFechaSplit[1]}/${dataFechaSplit[0]}/${dataFechaSplit[2]}`);
                this.setState({
                    toggle1: data.pushAutoexploracion,
                    toggle2: data.pushBreastExam,
                    toggle3: data.pushMedicamento,
                    toggle4: data.pushChat,
                    toggle5: data.pushGenerales,
                    fotoPerfil: data.fotoPerfil,
                    nombre: data.nombre,
                    apellido: data.apellidos,
                    nombreCompleto: `${data.nombre} ${data.apellidos}`,
                    correo: data.correoElectronico,
                    fechaNacimiento: data.fechaNacimiento,
                    idpais: data.pais,
                    idestado: data.estado,
                    telefono: data.telefono,
                    loaded: true,
                    // 
                    editName: data.nombre,
                    editApellido: data.apellidos,
                    editFecha: `${dataFechaSplit[1]}/${dataFechaSplit[0]}/${dataFechaSplit[2]}`,
                    // editFecha: data.fechaNacimiento,
                    editFechaFormat: fechForm,
                    editPais: Number(data.pais),
                    editEstado: Number(data.estado),
                    editImg: data.fotoPerfil,
                    IMGoriginal:data.fotoPerfil,
                    editTelefono: data.telefono
                },()=>{
                    this.setState({
                        editImg:data.fotoPerfil
                    })
                    console.log("deberia hacer el cambio")
                });
                fetch(`${localStorage.getItem('urlAPI')}/api/catalogo/paises`)
                    .then(paisesresponse => paisesresponse.json())
                    .then(paisesresponseJson => {
                        let paises = paisesresponseJson.result.data;
                        paises.forEach(pais => {
                            if (pais.idpaises === this.state.idpais) {
                                this.setState({
                                    pais: pais.pais,
                                })
                            }
                        });
                    },
                        fetch(`${localStorage.getItem('urlAPI')}/api/catalogo/estados/${this.state.idpais}`)
                            .then(estadosresponse => estadosresponse.json())
                            .then(estadosjson => {
                                estadosjson.result.data.forEach(estado => {
                                    if (estado.idestados === this.state.idestado) {
                                        this.setState({
                                            estado: estado.estado
                                        });
                                    }
                                });
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    )
            })
            .catch(error => {
                console.log(error);
            })
        fetch(`${localStorage.getItem('urlAPI')}/api/catalogo/paises`)
            .then(responsePaises => responsePaises.json())
            .then(paisesJson => {
                this.setState({
                    paisesData: paisesJson.result.data
                })
                setTimeout(() => {
                    this._getEstado();
                }, 200);
            })
    }
    componentDidMount() {
        if (localStorage.getItem('token') !== '0') {
            this._getData();
        } else {
            this.setState({
                inicioModal: true
            })
        }

    }

    componentDidUpdate(prevProps, prevState) {
        this._changeTextMonth()
    }

    /**
     *Funcion que obtiene el estado
     *
     * @param {*} pais
     */
    _getEstado = (pais) => {
        fetch(`${localStorage.getItem('urlAPI')}/api/catalogo/estados/${pais !== undefined ? (pais) : (this.state.editPais)}`)
            .then(response => response.json())
            .then(responseJson => {
                this.setState({
                    estadosData: responseJson.result.data
                })
            })
    }

    /**
     *Funcion que cierra la sesión
     *
     * @memberof perfil
     */
    _closeSession = () => {
        fetch(`${localStorage.getItem('urlAPI')}/api/user/logout`, {
            method: 'POST',
            body: JSON.stringify({
                'tokenUsuario': localStorage.getItem('token')
            })
        })
            .then(response => response.json())
            .then(responseJson => {
                window.location.href = '/webapp/';
            })
            .catch(error => {
                console.log(error);
            })
    }

    /**
     *Funcion para cambiar a editar perfil
     *
     * @memberof perfil
     */
    _togglePass = () => {
        if (this.state.editpass) {
            this.setState({
                editpass: false
            });
        } else {

            this.setState({
                editpass: true
            });
        }
    }

    /**
     *Funcion que guarda los cambios de perfil
     *
     * @memberof perfil
     */
    _fetchEditSave = () => {
        fetch(`${localStorage.getItem('urlAPI')}/api/user/editProfile`, {
            method: 'POST',
            body: JSON.stringify({
                "tokenUsuario": localStorage.getItem('token'),
                "imagenPerfil": this.state.editImg.split("base64,").length > 1 ? (this.state.editImg.split("base64,")[1]) : (""),
                "nombreUsuario": this.state.editName,
                "apellidos": this.state.editApellido,
                "fechaNacimiento": this.state.editFecha,
                "pais": Number(this.state.editPais),
                "estado": Number(this.state.editEstado),
                "telefono": this.state.editTelefono
            })
        })
            .then(response => response.json())
            .then(responseJson => {
                if (this.state.editpass) {

                    if (this.state.editContraseniaNva !== '' && this.state.editContraseniaNvaConfirm !== '' && this.state.editContrasenia !== '') {

                        // var validaPass = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{5,12}$/mg;
                        var validaPass = /^.{4,12}$/g;
                        if (validaPass.exec(this.state.editContraseniaNva) !== '' && validaPass.exec(this.state.editContraseniaNvaConfirm) !== '') {
                            if (this.state.editContraseniaNva === this.state.editContraseniaNvaConfirm) {
                                if (this.state.editContraseniaNva !== this.state.editContrasenia) {

                                    fetch(`${localStorage.getItem('urlAPI')}/api/user/changePassword`, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            "tokenUsuario": localStorage.getItem('token'),
                                            "contraseniaActual": this.state.editContrasenia,
                                            "nuevaContrasenia": this.state.editContraseniaNva
                                        })
                                    })
                                        .then(pass => pass.json())
                                        .then(passJson => {
                                            if (passJson.result.status_code != 'invalid_pass' && passJson.result.status_code != 'error_pass' && passJson.result.status_code != 'duplicate_pass') {
                                                alert("Perfil Actualizado.");
                                                this.props.history.go(0);
                                            } else {
                                                alert(passJson.result.message);
                                            }
                                        })

                                } else {
                                    alert("La nueva contraseña no puede ser igual a la anterior.");
                                }
                            } else {
                                alert("La confirmación de contraseña y contraseña no coinciden.");
                            }
                        } else {
                            alert("La contraseña debe tener mínimo 4 caracteres y máximo 12.");
                        }
                    } else {
                        alert("Los campos son requeridos.");
                    }

                } else {
                    alert("Perfil Actualizado.");
                    this.props.history.go(0);
                }
            })
    }

    /**
     *Funcion que evalua los cambios del perfil antes de guardarlos
     *
     * @memberof perfil
     */
    _editGuardar = () => {

        if (this.state.editImg !== '' && this.state.editName !== '' && this.state.editApellido !== '' && this.state.editFecha !== '' && this.state.editPais !== '' && this.state.editEstado !== '') {
            if (this.state.editTelefono.length > 0) {
                if (this.state.editTelefono.length === 10) {
                    this._fetchEditSave();
                } else {
                    alert("El teléfono tiene que contener 10 dígitos.");
                }
            } else {
                this._fetchEditSave();
            }
        } else {
            alert("Los campos son requeridos.");
        }
    }

    /**
     *Funcion que abtiene la imagen
     *
     * @memberof perfil
     */
    _handleChange = () => {
        var state = this;
        var imgdata = document.querySelector("#fotoperfil").files[0];
        var reader = new FileReader();
        reader.readAsDataURL(imgdata);
        reader.onload = function (event) {
            state.setState({
                editImg: event.target.result
            })
        };
    }

    /**
     *Funcion para activar o desactivar las notificaciones
     *
     * @memberof perfil
     */
    _wsNotifi = () => {
        fetch(`${localStorage.getItem('urlAPI')}/api/notificacion/activar`, {
            method: 'POST',
            body: JSON.stringify({
                'tokenUsuario': localStorage.getItem('token'),
                'pushAutoexploracion': this.state.toggle1,
                'pushBreastExam': this.state.toggle2,
                'pushMedicamento': this.state.toggle3,
                'pushChat': this.state.toggle4,
                'pushGenerales': this.state.toggle5
            })
        })
            .then(response => response.json())
            .then(responseJson => {
                // console.log(responseJson)
            })
            .catch(error => console.log(error))
    }

    /**
     *Funcion que cambia el estado de los botones de las notificaciones
     *
     * @memberof perfil
     */
    _togglecheck = (selector) => {
        switch (selector) {
            case 1:
                let validator = document.querySelector("#toggle1").className.split(" ").indexOf("active");
                if (Number(validator) >= 0) {
                    this.setState({
                        toggle1: false
                    }, () =>
                        this._wsNotifi()
                    );
                } else {
                    this.setState({
                        toggle1: true
                    }, () =>
                        this._wsNotifi()
                    );
                }
                break;
            case 2:
                let validator2 = document.querySelector("#toggle2").className.split(" ").indexOf("active");
                if (Number(validator2) >= 0) {
                    this.setState({
                        toggle2: false
                    }, () =>
                        this._wsNotifi()
                    );
                } else {
                    this.setState({
                        toggle2: true
                    }, () =>
                        this._wsNotifi()
                    );
                }
                break;
            case 3:
                let validator3 = document.querySelector("#toggle3").className.split(" ").indexOf("active");
                if (Number(validator3) >= 0) {
                    this.setState({
                        toggle3: false
                    }, () =>
                        this._wsNotifi()
                    );
                } else {
                    this.setState({
                        toggle3: true
                    }, () =>
                        this._wsNotifi()
                    );
                }
                break;
            case 4:
                let validator4 = document.querySelector("#toggle4").className.split(" ").indexOf("active");
                if (Number(validator4) >= 0) {
                    this.setState({
                        toggle4: false
                    }, () =>
                        this._wsNotifi()
                    );
                } else {
                    this.setState({
                        toggle4: true
                    }, () =>
                        this._wsNotifi()
                    );
                }
                break;
            case 5:
                let validator5 = document.querySelector("#toggle5").className.split(" ").indexOf("active");
                if (Number(validator5) >= 0) {
                    this.setState({
                        toggle1: false,
                        toggle2: false,
                        toggle3: false,
                        toggle4: false,
                        toggle5: false
                    }, () =>
                        this._wsNotifi()
                    );
                } else {
                    this.setState({
                        toggle1: true,
                        toggle2: true,
                        toggle3: true,
                        toggle4: true,
                        toggle5: true
                    }, () =>
                        this._wsNotifi()
                    );
                }
                break;

            default:
                break;
        }
    }

    /**
     *Cambia el formato del texto de los meses
     *
     * @memberof perfil
     */
    _changeTextMonth = () => {
        var valueTxt = $(".react-date-picker__inputGroup__month");
        for (let index = 0; index < $(valueTxt).children().length; index++) {
            valueTxt.children()[index].innerHTML = valueTxt.children()[index].innerHTML.split(".")[0];
            valueTxt.children()[index].innerHTML = valueTxt.children()[index].innerHTML.slice(0, 1).toUpperCase() + valueTxt.children()[index].innerHTML.slice(1);
        }
    }

    render() {
        validateSession();
        return (
            <div>
                {this.state.inicioModal ? (
                    <Modal history={this.props.history} val={false} close={() => this.setState({ inicioModal: false })}></Modal>
                ) : null}
                <NavBar />
                <div className="row mx-0">
                    <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 p-2 p-lg-5 bg-light shadow-x">
                        <div className="row mb-5 mx-0">
                            <div className="col-12">
                                {this.state.edit ? (

                                    <div className="position-absolute cursor_pointer" id="backPS">
                                        <img onClick={() => this.setState({ edit: false,editImg:this.state.fotoPerfil })} src={back} alt="Image PDE" />
                                    </div>
                                ) : (null)}
                                <div className="h2 titulo">Mi Perfil</div>
                            </div>
                        </div>
                        <div className="row px-3 mb-3 mx-0">
                            <div className="col-12 px-0">
                                <div className="w-100 shadow-x" style={{ minHeight: '37rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ backgroundImage: `url('${cabecera}')`, backgroundPosition: 'bottom', position: 'relative', backgroundSize: 'cover' }} className='w-100 header_MiPerfil'>
                                        <span className='h4 text-light mt-5 font-weight-bold'>{this.state.nombreCompleto}</span>
                                        <div className='imgPerfil_edit_cont mx-auto'>
                                            {this.state.edit ? (
                                                <div className='imgPerfil_edit' style={{ backgroundImage: `url("${this.state.editImg}") ,url("${imgdefault}")` }}>

                                                    <div className='edit_photo center_Text'>
                                                        <input onChange={() => this._handleChange()} id="fotoperfil" type="file" accept="image/*" />
                                                        <label htmlFor="fotoperfil">
                                                            <img className='icon_white w-100' src={IconCamera}></img>
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                    <div className='imgPerfil_edit' style={{ backgroundImage: `url("${this.state.fotoPerfil}") ,url("${imgdefault}")` }}>
                                                    </div>
                                                )}
                                        </div>
                                        {this.state.edit ? (null) : (
                                            <div className='icon_edit'>
                                                <img onClick={() => this.setState({ edit: true })} src={iconEdit}></img>
                                            </div>
                                        )}
                                    </div>

                                    {this.state.edit ? (
                                        <div className='w-100 mt-4'>
                                            <div className='row mx-0'>
                                                <div className='col-md-6 col-12 pl-md-5 pl-2'>
                                                    <div className='row px-4 mb-2'>
                                                        <label className='w-100 pl-2 font-size-08 mb-0 mt-2'>
                                                            Nombre
                                                        </label>
                                                        <input value={this.state.editName} onChange={(event) => this.setState({ editName: event.target.value })} type='text' className='w-100 edit_input px-2 py-1' />
                                                    </div>
                                                    <div className='row px-4 mb-2'>
                                                        <label className='w-100 pl-2 font-size-08 mb-0 mt-2'>
                                                            Apellido
                                                        </label>
                                                        <input value={this.state.editApellido} onChange={(event) => this.setState({ editApellido: event.target.value })} type='text' className='w-100 edit_input px-2 py-1' />
                                                    </div>
                                                    <div className='row px-4 mb-2'>
                                                        <label className='w-100 pl-2 font-size-08 mb-0 mt-2'>
                                                            Correo electrónico
                                                        </label>
                                                        <input disabled value={this.state.correo} type='mail' className='w-100 edit_input px-2 py-1 inp_disabled' />
                                                    </div>
                                                    <div className='row px-4 mb-2'>
                                                        <label className='w-100 pl-2 font-size-08 mb-0 mt-2'>
                                                            Fecha de nacimiento
                                                        </label>
                                                        <DatePicker
                                                            onCalendarOpen={() => this.setState({ calendario: true })}
                                                            onCalendarClose={() => this.setState({ calendario: false })}
                                                            disabled={this.state.calendario}
                                                            locale='es-ES'
                                                            maxDate={new Date(2010,11,17)}
                                                            onChange={(date) => {
                                                                this.setState({
                                                                    editFecha: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
                                                                    editFechaFormat: date,
                                                                    calendario: false

                                                                }); console.log(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
                                                            }}
                                                            value={this.state.editFechaFormat}
                                                            calendarIcon={null}
                                                            format='dd-MMM-y'
                                                            className='edit_input w-100 pl-1 py-1'
                                                        />
                                                    </div>
                                                    <div className='row px-4 mb-2'>
                                                        <label className='w-100 pl-2 font-size-08 mb-0 mt-2'>
                                                            Teléfono
                                                        </label>
                                                        <input value={this.state.editTelefono} onChange={(event) => {
                                                            if (event.target.value.length <= 10) {
                                                                this.setState({ editTelefono: event.target.value })
                                                            }
                                                        }} type='number' className='w-100 edit_input px-2 py-1' />
                                                    </div>
                                                    <div className='row px-4 mb-2'>
                                                        <label className='w-100 pl-2 font-size-08 mb-0 mt-2'>
                                                            País
                                                        </label>
                                                        <select value={this.state.editPais} onChange={(event) => { this.setState({ editPais: event.target.value }); this._getEstado(event.target.value) }} className='edit_input w-100 py-1' style={{ backgroundColor: 'white' }}>
                                                            <option value='0'></option>
                                                            {this.state.paisesData.map(pais =>
                                                                <option key={pais.idpaises} value={pais.idpaises}>{pais.pais}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className='row px-4 mb-2'>
                                                        <label className='w-100 pl-2 font-size-08 mb-0 mt-2'>
                                                            Estado
                                                        </label>
                                                        <select value={this.state.editEstado} onChange={(event) => { this.setState({ editEstado: event.target.value }); }} className='edit_input w-100 py-1' style={{ backgroundColor: 'white' }}>
                                                            <option value='0'></option>
                                                            {this.state.estadosData.length != undefined ? (
                                                                this.state.estadosData.map(estado =>
                                                                    <option key={estado.idestados} value={estado.idestados}>{estado.estado}</option>
                                                                )
                                                            ) : (null)}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-12 pr-md-5 pr-3 pl-2 pt-4'>
                                                    <div className='row px-4 mb-2'>

                                                        <div onClick={() => this._togglePass()} className='edit_checkbox_border mr-2'>
                                                            {this.state.editpass ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" className="svg w-100 replaced-svg" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                                                            ) : (null)}
                                                        </div>
                                                        <label style={{ fontWeight: 'bold' }}>Cambiar contraseña</label>
                                                    </div>
                                                    <div className='row px-4 mb-2'>
                                                        <label className='w-100 pl-2 font-size-08 mb-0 mt-2'>
                                                            Contraseña
                                                        </label>
                                                        <input onChange={(event) => this.setState({ editContrasenia: event.target.value })} disabled={!this.state.editpass} type='password' className={`w-100 edit_input px-2 py-1 ${!this.state.editpass ? ('inp_disabled') : ('')}`} />
                                                    </div>
                                                    <div className='row px-4 mb-2'>
                                                        <label className='w-100 pl-2 font-size-08 mb-0 mt-2'>
                                                            Nueva contraseña
                                                        </label>
                                                        <input onChange={(event) => this.setState({ editContraseniaNva: event.target.value })} disabled={!this.state.editpass} type='password' className={`w-100 edit_input px-2 py-1 ${!this.state.editpass ? ('inp_disabled') : ('')}`} />
                                                    </div>
                                                    <div className='row px-4 mb-2'>
                                                        <label className='w-100 pl-2 font-size-08 mb-0 mt-2'>
                                                            Confirmar contraseña
                                                        </label>
                                                        <input onChange={(event) => this.setState({ editContraseniaNvaConfirm: event.target.value })} disabled={!this.state.editpass} type='password' className={`w-100 edit_input px-2 py-1 ${!this.state.editpass ? ('inp_disabled') : ('')}`} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='edit_guardar mt-4 mb-4'>
                                                <div onClick={() => this._editGuardar()}>Guardar</div>
                                            </div>
                                        </div>
                                    ) : (

                                            <div className='w-100 cont_perfil'>

                                                <div className='col-md-6 col-10 mb-2'>
                                                    <div className='shadow-x py-2 px-3 w-100 centrado-vh-column' style={{ marginTop: '3%' }}>
                                                        <div className='centrado-vh-column mb-2'>
                                                            <div className='font-weight-bold font-size-116 text-center text-break'>
                                                                Correo electrónico
                                                            </div>
                                                            <div className='gray_txt text-center text-break'>
                                                                {this.state.correo}
                                                            </div>
                                                        </div>
                                                        <div className='centrado-vh-column mb-2'>
                                                            <div className='font-weight-bold font-size-116 text-center'>
                                                                Fecha de nacimiento
                                                            </div>
                                                            <div className='gray_txt text-center text-break'>
                                                                {this.state.fechaNacimiento !== '' ? (
                                                                    `${this.state.fechaNacimiento.split("/")[0]}/${meses[Number(this.state.fechaNacimiento.split("/")[1]) - 1].slice(0, 3).toLowerCase()}./${this.state.fechaNacimiento.split("/")[2]}`
                                                                ) : (null)}
                                                            </div>
                                                        </div>
                                                        <div className='centrado-vh-column mb-2'>
                                                            <div className='font-weight-bold font-size-116 text-center '>
                                                                Teléfono
                                                            </div>
                                                            <div className='gray_txt text-center text-break'>
                                                                {this.state.telefono}
                                                            </div>
                                                        </div>
                                                        <div className='centrado-vh-column mb-2'>
                                                            <div className='font-weight-bold font-size-116'>
                                                                País
                                                            </div>
                                                            <div className='gray_txt text-center text-break'>
                                                                {this.state.pais}
                                                            </div>
                                                        </div>
                                                        <div className='centrado-vh-column mb-2'>
                                                            <div className='font-weight-bold font-size-116'>
                                                                Estado
                                                            </div>
                                                            <div className='gray_txt text-center text-break'>
                                                                {this.state.estado}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-6 col-10 mb-5'>
                                                    <div className='shadow-x py-2 px-3 w-100 centrado-vh-column' style={{ marginTop: '3%' }}>
                                                        <div className='centrado-vh-column mb-2'>
                                                            <div className='font-weight-bold font-size-116 text-break'>
                                                                Notificaciones
                                                            </div>
                                                        </div>
                                                        <div className='w-100 mb-4 flex_space_between'>
                                                            <div>
                                                                Autoexploración
                                                            </div>
                                                            <div className='check_toggle_back' style={this.state.toggle1 ? ({ backgroundColor: '#e5097f' }) : ({ backgroundColor: '#9c9c9c' })}>
                                                                <div onClick={() => this._togglecheck(1)} id='toggle1' style={this.state.toggle1 ? ({ right: '0px' }) : ({ right: '55%' })} className={`check_toggle_circle ${this.state.toggle1 ? ('active') : ('')}`}>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='w-100 mb-4 flex_space_between'>
                                                            <div>
                                                                iBreastExam
                                                            </div>
                                                            <div className='check_toggle_back' style={this.state.toggle2 ? ({ backgroundColor: '#e5097f' }) : ({ backgroundColor: '#9c9c9c' })}>
                                                                <div onClick={() => this._togglecheck(2)} id='toggle2' style={this.state.toggle2 ? ({ right: '0px' }) : ({ right: '55%' })} className={`check_toggle_circle ${this.state.toggle2 ? ('active') : ('')}`}>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='w-100 mb-4 flex_space_between'>
                                                            <div>
                                                                Medicamento
                                                            </div>
                                                            <div className='check_toggle_back' style={this.state.toggle3 ? ({ backgroundColor: '#e5097f' }) : ({ backgroundColor: '#9c9c9c' })}>
                                                                <div onClick={() => this._togglecheck(3)} id='toggle3' style={this.state.toggle3 ? ({ right: '0px' }) : ({ right: '55%' })} className={`check_toggle_circle ${this.state.toggle3 ? ('active') : ('')}`}>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='w-100 mb-4 flex_space_between'>
                                                            <div>
                                                                Chat
                                                            </div>
                                                            <div className='check_toggle_back' style={this.state.toggle4 ? ({ backgroundColor: '#e5097f' }) : ({ backgroundColor: '#9c9c9c' })}>
                                                                <div onClick={() => this._togglecheck(4)} id='toggle4' style={this.state.toggle4 ? ({ right: '0px' }) : ({ right: '55%' })} className={`check_toggle_circle ${this.state.toggle4 ? ('active') : ('')}`}>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='w-100 mb-2 flex_space_between'>
                                                            <div>
                                                                General
                                                            </div>
                                                            <div className='check_toggle_back' style={this.state.toggle5 ? ({ backgroundColor: '#e5097f' }) : ({ backgroundColor: '#9c9c9c' })}>
                                                                <div onClick={() => this._togglecheck(5)} id='toggle5' style={this.state.toggle5 ? ({ right: '0px' }) : ({ right: '55%' })} className={`check_toggle_circle ${this.state.toggle5 ? ('active') : ('')}`}>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div onClick={() => this.setState({ cerrarSesion: true })} className='cerrar_sesion mb-5'>
                                                    Cerrar sesión
                                                </div>
                                                {this.state.cerrarSesion ? (
                                                    <div className='cerrar_sesion_modal row mx-0 w-100'>
                                                        <div className='col-md-7 col-10 cont-cerrar-sesion'>
                                                            <div className='cont-cs w-100 h-100 center_Text position-relative flex_column px-md-4 x-3'>
                                                                <div onClick={() => this.setState({ cerrarSesion: false })} className='color-pde cerrar_modal'>x</div>
                                                                <img className='w-50 mb-3' alt='PDE' src={logoColores} />
                                                                <div className='color-pde'>
                                                                    <h5 className='text-center'>
                                                                        ¿Estás segura que desesas cerrar la sesión?
                                                                    </h5>
                                                                </div>
                                                                <div className='w-100 d-flex btn_cerrar_s_cont'>
                                                                    <div className='px-md-5 px-4 py-2' onClick={() => this._closeSession()}>Sí</div>
                                                                    <div className='px-md-5 px-4 py-2' onClick={() => this.setState({ cerrarSesion: false })}>No</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (null)}
                                            </div>
                                        )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default perfil;