import React, { Component } from "react";
import NavBar from "../navBar/NavBar";
import $ from "jquery";
import validateSession from "../checksession";
import like0 from "../resources/like0.png";
import like1 from "../resources/like1.png";
import share from "../resources/share.png";
import iconoplay from '../resources/play.png'

import { messaging } from '../init-fcm';
// import { async } from 'q';
import Modal from "../Modal";

var firebasecount = 0;

/**
 *Funcion que trae los datos de las notas
 *
 * @param {*} id
 * @param {*} history
 */
function notas(id, history, esto) {
  var history = history.history;
  $("#notaGrande").html("");
  $("#contps-izq").html("");
  $("#contps-der").html("");
  var idCat = Number(id);
  var dataJson = '';

  if (localStorage.getItem('token') === '0') {
    dataJson = JSON.stringify({
      seccionNota: 2,
      categoriaId: idCat,
      tipo: 2
    })
  } else {
    dataJson = JSON.stringify({
      tokenUsuario: localStorage.getItem("token"),
      seccionNota: 2,
      categoriaId: idCat,
      tipo: 2
    })
  }

  //  peticion de ajax a fetch

  fetch(`${localStorage.getItem("urlAPI")}/api/notas/listado`, {
    method: 'POST', 
    mode:'cors',
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body:dataJson,
  })
  .then(response => response.json())
  .then(response => {
    if (response.result.data != "none") {
      var cambio = 0;
      $.each(response.result.data, function (i, nota) {
        if (i === 0) {
          $("#notaGrande").append(NotaGdd(nota)).hide().fadeIn("slow");
        } else {
          if (i % 2 === 1) {
            if (cambio === 0) {
              $("#contps-izq").append(NotaMdd(nota)).hide().fadeIn("slow");
              cambio = 1;
            } else {
              $("#contps-izq").append(NotaChd(nota)).hide().fadeIn("slow");
              cambio = 0;
            }
          } else {
            if (cambio === 0) {
              $("#contps-der").append(NotaMdd(nota)).hide().fadeIn("slow");
              cambio = 0;
            } else {
              $("#contps-der").append(NotaChd(nota)).hide().fadeIn("slow");
              cambio = 1;
            }
          }
        }
      });
      //
      $(".notas").click(function () {
        let id = $(this)
          .parent()
          .attr("data-id");
        history.push(`/webapp/app/notaVS?${id}`);
        var html = document.documentElement;
        html.scrollTop = 0;
      });
      $(".btns-actions").click(function () {
        let selector = $(this);
        let tipo = $(selector).attr("data-tipo");
        let id = $(selector).attr("data-id");
        if (tipo === "like") {
          if (localStorage.getItem('token') !== '0') {

            $.ajax({
              type: "POST",
              url: localStorage.getItem("urlAPI") + "/api/notas/megusta",
              data: JSON.stringify({
                tokenUsuario: localStorage.getItem("token"),
                idNota: id
              }),
              dataType: "json",
              success: function (response) {
                if ($(selector).attr("data-active") === "0") {
                  $(selector).attr("src", like1);
                  $(selector).attr("data-active", "1");
                } else {
                  $(selector).attr("src", like0);
                  $(selector).attr("data-active", "0");
                }
              },
              error: function (response) {
                console.log(response);
                alert("Hubo un error");
              }
            });
          } else {
            esto.setState({
              inicioModal: true
            })
          }

        }

        if (tipo === "share") {
          let newHref =
            window.location.href.split("/vidaSana/")[0] + "/notaVS?" + id;
          var aux = document.createElement("input");
          aux.setAttribute("value", newHref);
          document.body.appendChild(aux);
          aux.select();
          if (document.execCommand("copy")) {
            alert("Copiado al portapapeles.");
          } else {
            alert("Error al copiar.");
          }
          document.body.removeChild(aux);
        }
      });
    } else {
      $("#notaGrande").append("No hay datos para mostrar.");
    }
  })
  .catch((error) => {
    console.error('Error:', error);
  });

  // $.ajax({
  //   type: "POST",
  //   url: `${localStorage.getItem("urlAPI")}/api/notas/listado`,
  //   data: dataJson,
  //   dataType: "json",
  //   success: function (response) {
  //     if (response.result.data != "none") {
  //       var cambio = 0;
  //       $.each(response.result.data, function (i, nota) {
  //         if (i === 0) {
  //           $("#notaGrande").append(NotaGdd(nota)).hide().fadeIn("slow");
  //         } else {
  //           if (i % 2 === 1) {
  //             if (cambio === 0) {
  //               $("#contps-izq").append(NotaMdd(nota)).hide().fadeIn("slow");
  //               cambio = 1;
  //             } else {
  //               $("#contps-izq").append(NotaChd(nota)).hide().fadeIn("slow");
  //               cambio = 0;
  //             }
  //           } else {
  //             if (cambio === 0) {
  //               $("#contps-der").append(NotaMdd(nota)).hide().fadeIn("slow");
  //               cambio = 0;
  //             } else {
  //               $("#contps-der").append(NotaChd(nota)).hide().fadeIn("slow");
  //               cambio = 1;
  //             }
  //           }
  //         }
  //       });
  //       //
  //       $(".notas").click(function () {
  //         let id = $(this)
  //           .parent()
  //           .attr("data-id");
  //         history.push(`/webapp/app/notaVS?${id}`);
  //         var html = document.documentElement;
  //         html.scrollTop = 0;
  //       });
  //       $(".btns-actions").click(function () {
  //         let selector = $(this);
  //         let tipo = $(selector).attr("data-tipo");
  //         let id = $(selector).attr("data-id");
  //         if (tipo === "like") {
  //           if (localStorage.getItem('token') !== '0') {

  //             $.ajax({
  //               type: "POST",
  //               url: localStorage.getItem("urlAPI") + "/api/notas/megusta",
  //               data: JSON.stringify({
  //                 tokenUsuario: localStorage.getItem("token"),
  //                 idNota: id
  //               }),
  //               dataType: "json",
  //               success: function (response) {
  //                 if ($(selector).attr("data-active") === "0") {
  //                   $(selector).attr("src", like1);
  //                   $(selector).attr("data-active", "1");
  //                 } else {
  //                   $(selector).attr("src", like0);
  //                   $(selector).attr("data-active", "0");
  //                 }
  //               },
  //               error: function (response) {
  //                 console.log(response);
  //                 alert("Hubo un error");
  //               }
  //             });
  //           } else {
  //             esto.setState({
  //               inicioModal: true
  //             })
  //           }

  //         }

  //         if (tipo === "share") {
  //           let newHref =
  //             window.location.href.split("/vidaSana/")[0] + "/notaVS?" + id;
  //           var aux = document.createElement("input");
  //           aux.setAttribute("value", newHref);
  //           document.body.appendChild(aux);
  //           aux.select();
  //           if (document.execCommand("copy")) {
  //             alert("Copiado al portapapeles.");
  //           } else {
  //             alert("Error al copiar.");
  //           }
  //           document.body.removeChild(aux);
  //         }
  //       });
  //     } else {
  //       $("#notaGrande").append("No hay datos para mostrar.");
  //     }
  //   }
  // });
}

/**
 *Funcion que genera las tarjetas de notas tamaño grande
 *
 * @param {*} params
 * @returns tarjeta
 */
function NotaGdd(params) {
  var titulo = params.titulo,
    contenido = params.descripcion,
    descripcion = contenido.replace(/<[^>]*>?/g, ' '),
    colores = JSON.parse(sessionStorage.getItem("categoriascolor")),
    colorFinal = "";
    let regex = /\?/g;
    let prueba = descripcion.replace(regex,' ')
    console.log(prueba);

  colores.forEach(color => {
    if (color.categoria == params.categoria) {
      colorFinal = `#${color.color}`;
    }
  });
  if (titulo.length > 83) {
    titulo = `${titulo.slice(0, 75)}<span class="color-pde">...</span>`;
  }
  const element = `
    <div data-id=${
    params.idnotas
    } class="cursor_pointer h-auto w-100 position-relative">
        <div class="botones-gd d-flex">
        <div class="w-50 h-100 p-2">
          <img data-tipo="like" data-active=${params.like ? "1" : "0"} data-id=${params.idnotas} class="btns-actions p-0 w-100 h-100 object_fit_contain" src=${params.like ? like1 : like0} />
        </div>
        <div class="w-50 h-100 p-2">
          <img data-tipo="share" data-id=${params.idnotas} class="btns-actions p-0 w-100 h-100 object_fit_contain" src=${share} />
        </div>
    </div>
    <div class="col-12 notas notaps px-0 h-auto">
        <div class="imagenota">
            <img class="portada" src=${params.imagenPrincipal} />
            ${
    params.tipoMultimedia == 2
      ? `<span class='position-absolute reproductor'><img class='h-100 w-100' style='object-fit:contain' alt='Nota' src=${iconoplay} /></span>`
      : ""
    }
    ${
    params.tipoMultimedia == 3
      ? `<span class='position-absolute reproductor'><img class='h-100 w-100' style='object-fit:contain' alt='Nota' src=${iconoplay} /></span>`
      : ""
    }
        </div>
    <div class="datosnota pt-2 pb-2 pl-3 pr-3">
        <div class="row">
            <div class="col-12">
                <span class="color-pde font-size-08">${titulo}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12 truncate-text">
                <span class="font-size-08">${prueba}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12 truncate-text">
                <hr class="my-2 hr_pde">
            </div>
        </div>
        <div class="row">
            <div class="col-12 truncate-text">
                <span class="font-size-075" style="color: ${colorFinal}; font-weight:bold">${
    params.categoria
    }</span>
            </div>
        </div>

    </div>
    </div>
    </div>`;
  return element;
}

/**
 *Funcion que genera las tarjetas de notas tamaño mediano
 *
 * @param {*} params
 * @returns tarjeta
 */
function NotaMdd(params) {
  var titulo = params.titulo,
    contenido = params.descripcion,
    descripcion = contenido.replace(/<[^>]*>?/g, ''),
    colores = JSON.parse(sessionStorage.getItem("categoriascolor")),
    colorFinal = "";

  colores.forEach(color => {
    if (color.categoria == params.categoria) {
      colorFinal = `#${color.color}`;
    }
  });
  if (titulo.length > 35) {
    titulo = `${titulo.slice(0, 34)}<span class="color-pde">...</span>`;
  }
  const element = `
    <div data-id=${
    params.idnotas
    } class="cursor_pointer h-auto w-100 position-relative">
    <div class="botones-md">
        <img data-tipo="like" data-active=${params.like ? "1" : "0"} data-id=${params.idnotas} class="btns-actions p-2 impajuste" src=${params.like ? like1 : like0} />
        <img data-tipo="share" data-id=${params.idnotas} class="btns-actions p-2 impajuste" src=${share} />
    </div>
    <div class="w-100 notas notapsmd mb-3">
        <div class="imagenota imagenotaVS">
            <img class="portada" src=${params.mosaico_a} />
            ${
    params.tipoMultimedia == 2
      ? `<span class='position-absolute reproductormd'><img class='h-100 w-100' style='object-fit:contain' alt='Nota' src=${iconoplay} /></span>`
      : ""
    }
    ${
    params.tipoMultimedia == 3
      ? `<span class='position-absolute reproductormd'><img class='h-100 w-100' style='object-fit:contain' alt='Nota' src=${iconoplay} /></span>`
      : ""
    }
        </div>
        
        <div class="datosnota datosnotaVS pt-2 pb-2 pl-3 pr-3">
            <div class="row mb-0 d-flex align-items-center" style="height:40%">
                <div class="col-12 line_height_75 tituloNota d-flex align-items-center h-100">
                    <span class="color-pde font-size-08">${titulo}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 truncate-text">
                    <span class="font-size-08">${descripcion}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-12 truncate-text">
                    <hr class="my-1 hr_pde">
                </div>
            </div>
            <div class="row">
                <div class="col-12 truncate-text">
                    <span class="font-size-075" style="color: ${colorFinal};font-weight:bold">${
    params.categoria
    }</span>
                </div>
            </div>

        </div>

    </div>
    </div>
    `;
  return element;
}

/**
 *Funcion que genera las tarjetas de notas tamaño chico
 *
 * @param {*} params
 * @returns tarjeta
 */
function NotaChd(params) {
  var titulo = params.titulo,
    contenido = params.descripcion,
    descripcion = contenido.replace(/<[^>]*>?/g, ''),
    colores = JSON.parse(sessionStorage.getItem("categoriascolor")),
    colorFinal = "";

  colores.forEach(color => {
    if (color.categoria == params.categoria) {
      colorFinal = `#${color.color}`;
    }
  });
  if (titulo.length > 35) {
    titulo = `${titulo.slice(0, 34)}<span class="color-pde">...</span>`;
  }
  const element = `
    <div data-id=${
    params.idnotas
    } class="cursor_pointer h-auto w-100 position-relative">
    <div class="botones-ch">
        <img data-tipo="like" data-active=${params.like ? "1" : "0"} data-id=${params.idnotas} class="btns-actions p-2 impajuste" src=${params.like ? like1 : like0} />
        <img data-tipo="share" data-id=${params.idnotas} class="btns-actions p-2 impajuste" src=${share} />
    </div>
    <div class="w-100 notas notapssm mb-3">
        <div class="imagenota imagenotaVS">
            <img class="portada" src=${params.mosaico_b} />
            ${
    params.tipoMultimedia == 2
      ? `<span class='position-absolute reproductorch'><img class='h-100 w-100' style='object-fit:contain' alt='Nota' src=${iconoplay} /></span>`
      : ""
    }
    ${
    params.tipoMultimedia == 3
      ? `<span class='position-absolute reproductorch'><img class='h-100 w-100' style='object-fit:contain' alt='Nota' src=${iconoplay} /></span>`
      : ""
    }
        </div>
        
        <div class="datosnota datosnotaVS pt-2 pb-2 pl-3 pr-3">
            <div class="row mb-0 d-flex align-items-center" style="height:40%">
                <div class="col-12 line_height_75 tituloNota d-flex align-items-center h-100">
                    <span class="color-pde font-size-08">${titulo}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 truncate-text">
                    <span class="font-size-08">${descripcion}</span>
                </div>
            </div>

            <div class="row">
            <div class="col-12 truncate-text">
                <hr class="my-1 hr_pde">
            </div>
            </div>
            <div class="row">
                <div class="col-12 truncate-text">
                    <span class="font-size-075" style="color: ${colorFinal};font-weight:bold">${
    params.categoria
    }</span>
                </div>
            </div>

        </div>

    </div>
    </div>
    `;
  return element;
}

/**
 *Componente que tiene los datos de vida sana
 *
 * @class vidaSana
 * @extends {Component}
 */
class vidaSana extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "#000000",
      categoria: 0,
      inicioModal: false
    };
  }

  /**
   *Funcion que extrae el token de firebase para las notificaciones
   *
   * @memberof vidaSana
   */


  _getTokenFirebase() {

    if (firebasecount <= 1) {

      messaging.getToken().then((currentToken) => {
        if (currentToken) {
          fetch(`${localStorage.getItem('urlAPI')}/api/user/updateToken`, {
            method: 'POST',
            body: JSON.stringify({
              "tokenUsuario": localStorage.getItem('token'),
              "tokenFirebase": currentToken
            })
          })
            .then(response => response.json())
            .then(responseJSON => {
              // console.log(responseJSON)
            })
            .catch(err => console.log(err))
        }
      }).catch((err) => {
        this._getTokenFirebase()
      });

      firebasecount += 1;

    }
  }
  _categoriasPOST = () => {
    var vsvar = this;
    $.ajax({
      type: "POST",
      url: `${localStorage.getItem("urlAPI")}/api/catalogo/categorias`,
      data: JSON.stringify({
        tokenUsuario: localStorage.getItem("token"),
        tipo: 2
      }),
      dataType: "json",
      success: function (response) {
        var categorias = response.result.data;
        sessionStorage.setItem("categoriascolor", JSON.stringify(categorias));
        categorias.forEach((categoria, i) => {
          if (i == 0) {
            vsvar.setState(state => ({
              color: `#${categoria.color}`,
              categoria: categoria.idcategorias
            }));
            $("#categorias").append(`
                        <div data_id="0" data_color=${
              categoria.color
              } class="categoria px-0 py-2 mr-2 cursor_pointer text-center font-size-08 overflow-hidden">
                            <span class="CatTxt font-weight-bold">
                                Todas
                            </span>
                        </div>`).hide().fadeIn("100");
          }
          $("#categorias").append(`
                    <div data_id=${categoria.idcategorias} data_color=${categoria.color} class="categoria px-0 py-2 mr-2 cursor_pointer text-center font-size-08 overflow-hidden">
                        <span class="CatTxt font-weight-bold">
                            ${categoria.categoria}
                        </span>
                    </div>`).hide().fadeIn("100");
          $(".categoria").css("color", "gray");
          $(".categoria").eq(0).css("color", "#E5097F");

        });
        notas(0, vsvar.props, vsvar);
        $(".categoria").click(function () {
          $(".categoria").css("color", "gray");
          $(this).css("color", "#E5097F");

          var colorcat = $(this).attr("data_color");
          var id = $(this).attr("data_id");
          vsvar.setState(state => ({
            color: `#${colorcat}`,
            categoria: id
          }));
          notas(vsvar.state.categoria, vsvar.props, vsvar);
        });
      }
    });
  }

  _categoriasGET = () => {
    var vsvar = this;
    $.ajax({
      type: "GET",
      url: `${localStorage.getItem("urlAPI")}/api/catalogo/categorias?tipo=2`,
      dataType: "json",
      success: function (response) {
        var categorias = response.result.data;
        sessionStorage.setItem("categoriascolor", JSON.stringify(categorias));
        categorias.forEach((categoria, i) => {
          if (i == 0) {
            vsvar.setState(state => ({
              color: `#${categoria.color}`,
              categoria: categoria.idcategorias
            }));
            $("#categorias").append(`
                        <div data_id="0" data_color=${
              categoria.color
              } class="categoria px-0 py-2 mr-2 cursor_pointer text-center font-size-08 overflow-hidden">
                            <span class="CatTxt font-weight-bold">
                                Todas
                            </span>
                        </div>`).hide().fadeIn("100");
          }
          $("#categorias").append(`
                    <div data_id=${categoria.idcategorias} data_color=${categoria.color} class="categoria px-0 py-2 mr-2 cursor_pointer text-center font-size-08 overflow-hidden">
                        <span class="CatTxt font-weight-bold">
                            ${categoria.categoria}
                        </span>
                    </div>`).hide().fadeIn("100");
          $(".categoria").css("color", "gray");
          $(".categoria").eq(0).css("color", "#E5097F");

        });
        notas(0, vsvar.props, vsvar);
        $(".categoria").click(function () {
          $(".categoria").css("color", "gray");
          $(this).css("color", "#E5097F");

          var colorcat = $(this).attr("data_color");
          var id = $(this).attr("data_id");
          vsvar.setState(state => ({
            color: `#${colorcat}`,
            categoria: id
          }));
          notas(vsvar.state.categoria, vsvar.props, vsvar);
        });
      }
    });
  }
  componentDidMount() {
    if(messaging) {
      console.log("es soportado el navegador");
      this._getTokenFirebase()
  }
    

    if (localStorage.getItem('token') === '0') {
      this._categoriasGET()
    } else {
      this._categoriasPOST()
    }
  }

  render() {
    validateSession();
    return (
      <div>
        {this.state.inicioModal ? (
          <Modal history={this.props.history} val={true} close={() => this.setState({ inicioModal: false })}></Modal>
        ) : null}
        <NavBar />
        <div className="row mx-0">
          <div className="col-12 col-md-6 offset-md-3 bg-light p-2 p-md-5 shadow-x">
            <div className="row mb-5 mx-0">
              <div className="col-12">
                <div className="h2 titulo">Vida Sana</div>
              </div>
            </div>
            <div className="row px-3 mb-3 mx-0">
              <div className="col-12">
                <div id="categorias" className="w-100">
                </div>
              </div>
            </div>
            <div className="row px-3 mb-3 mx-0" id="notaGrande" />

            <div className="row px-3 mx-0">
              <div className="col-6 pr-3 pl-0" id="contps-izq" />
              <div className="col-6 pl-3 pr-0" id="contps-der" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default vidaSana;
